import { Auth, Logger } from "aws-amplify";
import store from "@/store";
import axios from "axios";

const logger = new Logger("main")
const getToken = async () => (await Auth.currentSession()).getIdToken().getJwtToken()

const getUserByEmail = (email) => {

    return new Promise(((resolve, reject) => {
        let _url = process.env.VUE_APP_CABINET_V2_API_HOST + '/v1/fg-users/list'
        let _headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + store.state.token,
            'X-referrer': 'login',
            'X-Api-Key': process.env.VUE_APP_X_API_KEY
        }
        axios({ method: 'GET', url: _url, headers: _headers }).then((result) => {
            // console.log('--- LIST USERS ---')
            // console.log(result)
            let userResult = result.data.user
            resolve(userResult)

        }).catch((error) => {
            // console.log('--- LIST USERS ---')
            // console.log(error)
            reject(error)
        })
    }))
}

const getPreferenceByEmail = (email) => {

    return new Promise(((resolve, reject) => {
        let _url = process.env.VUE_APP_CABINET_V2_API_HOST + '/v1/fg-users/preference'
        let _headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + store.state.token,
            'X-referrer': 'login',
            'X-Api-Key': process.env.VUE_APP_X_API_KEY
        }
        axios({ method: 'GET', url: _url, headers: _headers })
            .then((result) => {
                // console.log('--- PREFERENCES ---')
                console.log(result)
                let preference = result.data
                resolve(preference)
            }).catch((error) => {
                // console.log('--- PREFERENCES ---')
                // console.log(error)
                reject(error)
            })
    }))
}

const getAccountsByEmail = (email, store) => {
    return new Promise(((resolve, reject) => {
        if (JSON.parse(localStorage.getItem("storedAccounts")) === null) {
            let _url = process.env.VUE_APP_CABINET_V2_API_HOST + '/v1/cabinet/mt4checkaccounts/'
            let _headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + store.state.token,
                'X-Api-Key': process.env.VUE_APP_X_API_KEY
            }

            axios({ method: 'GET', url: _url, headers: _headers }).then((result) => {
                if (result.data.response.status == "Success") {
                    localStorage.setItem('storedAccounts', JSON.stringify(result.data.response.data))
                    resolve(result.data.response.data)
                } else {
                    resolve(null)
                }
            }).catch((error) => {
                logger.error(error)
                reject(error)
            })
        } else {
            resolve(null)
        }
    }))
}

const getAccountsMt5ByEmail = (email, store) => {
    return new Promise(((resolve, reject) => {
            let _url = process.env.VUE_APP_CABINET_V2_API_HOST + '/v1/cabinet/mt5checkaccounts/'
            let _headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + store.state.token,
                'X-Api-Key': process.env.VUE_APP_X_API_KEY
            }

            axios({ method: 'GET', url: _url, headers: _headers }).then((result) => {
                if (result.data.message.status == "Success") {
                    localStorage.setItem('storedAccountsmt5', JSON.stringify(result.data.message.data))
                    resolve(result.data.message.data)
                } else {
                    localStorage.setItem('storedAccountsmt5', null)
                    resolve(null)
                    if(result.data.message.status == 1){
                      alert(result.data.message.msg)
                    }
                }
            }).catch((error) => {
                logger.error(error)
                reject(error)
            })
    }))
}

const initializePreferences = (email) => {
    //INSERT INTO PREFERENCES
    return new Promise(((resolve, reject) => {

        let _url = process.env.VUE_APP_CABINET_V2_API_HOST + '/v1/fg-users/preference'
        let _headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + store.state.token,
            'X-referrer': 'login',
            'X-Api-Key': process.env.VUE_APP_X_API_KEY
        }
        let _data = {
            theme: 'LIGHT',
            dashboardLanguage: 'ENGLISH',
            emailsLanguage: 'ENGLISH',
            subscribedToCompanyNews: false,
            subscribedToTradingNews: false
        }
        console.log('--- LENGUAJE YYY ---')

        axios({ method: 'POST', url: _url, data: _data, headers: _headers }).then((result) => {
            console.log('--- PREFERENCE ---')
            console.log(result)
            let data = result.data
            let preference = data.preference
            resolve(preference)
        }).catch((error) => {
            console.log('--- PREFERENCE ---')
            console.log(error)
            reject(error)
        })
    }))
}
const registerUserBasicStep = (email) => {
    //INSERT INTO DATA BASE
    return new Promise(((resolve, reject) => {

        let _data = {
            refer: localStorage.getItem("refer"),
        }

        let _url = process.env.VUE_APP_CABINET_V2_API_HOST + '/v1/fg-users/basic'
        let _headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + store.state.token,
            'X-referrer': 'register',
            'X-Api-Key': process.env.VUE_APP_X_API_KEY
        }

        axios({ method: 'POST', url: _url, data: _data, headers: _headers }).then((result) => {
            console.log('--- BASIC USER REGISTRY ---')
            let data = result.data
            let user = data.user
            resolve(user)
        }).catch((error) => {
            logger.error(error)
        })
    }))
}
export {
    getAccountsByEmail,
    getUserByEmail,
    getPreferenceByEmail,
    initializePreferences,
    registerUserBasicStep,
    getAccountsMt5ByEmail,
}
