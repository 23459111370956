import {createStore} from 'vuex'

const store = createStore({
    state: {
        user: null,
        authUser: null,
        signedIn: false,
        userRegistration: null,
        preferences: null,
        application: null,
        loginEvent: null,
        activeAccount: null,
        token: null,
        loginFlow: null,
        dialogOKAction: null,
        showM: null,
        showDialogMessage: null,
        showCongratsDialog: null,
        typeAccount: null,
    },
    mutations: {
        clearUser(state) {
            state.user = null;
            state.authUser = null;
            state.signedIn = false;
        },
        setShowM(state, val) {
            state.showM = val
        },
        setShowDialogMessage(state, val) {
            state.showDialogMessage = val
        },
        setShowCongratsDialog(state, val) {
            state.showCongratsDialog = val
        },
        setDialogOKAction(state, flow) {
            state.dialogOKAction = flow
        },
        setLoginFlow(state, flow) {
            state.loginFlow = flow
        },
        setUser(state, user) {
            state.user = user;
            state.signedIn = user != null;
        },
        setActiveAccount(state, account) {
            state.activeAccount = account
        },
        updateUser(state, user) {
            state.user = {...state.user, ...user}
        },
        setRegistration(state, registration) {
            state.userRegistration = registration
        },
        setApplication(state, application) {
            state.application = application
        },
        setAuthUser(state, authUser) {
            state.authUser = authUser;
        },
        setPreferences(state, preferences) {
            state.preferences = preferences;
        },
        setSignedIn(state, signedIn) {
            state.signedIn = signedIn;
        },
        setLoginEvent(state, loginEvent) {
            state.loginEvent = loginEvent;
        },
        setToken(state, token) {
            state.token = token;
        },
        setTypeAccount(state, typeAccount) {
            state.typeAccount = typeAccount;
        }
    },
    getters: {
        user: state => {
            return state.user;
        },
        authUser: state => {
            return state.authUser;
        },
        showM: state => {
            return state.showM
        },
        typeAccount: state => {
            return state.typeAccount
        },
    }
})

export default store;
