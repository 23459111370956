<template>
  <router-view></router-view>
  <Preloader :showModal="showM"></Preloader>
</template>

<script>

/* eslint-disable */

import { Hub, Logger, Auth } from "aws-amplify";
import { computed, getCurrentInstance, onMounted, onUnmounted, ref } from "@vue/runtime-core";
import store from "@/store"
import router from "@/router"
import { useRoute } from "vue-router";

import {
  getUserByEmail,
  registerUserBasicStep,
} from "@/plugins/authUtils";
import axios from "axios";
import Preloader from "@/components/Preloader";
import { i18n } from '@/plugins/i18n';

/* comment  */
const logger = new Logger("main")

export default {
  name: 'app',
  components: {
    Preloader
  },
  data() {
    return {
      authUser: null,
    };
  },
  setup() {
    const route = useRoute()
    let showM = ref(false)
    let userObj = ref(null)

    const internalInstance = getCurrentInstance()
    const emitter = internalInstance.appContext.config.globalProperties.emitter

    console.log('app setup')

    //  DETECTA CAMBIOS DE AUTH
    Hub.listen('auth', (data) => {

      if (data.payload.event === 'tokenRefresh_failure') {
        console.error('critical: ' + data.payload.event)
        return
      }

      checkAuthUser()
      store.commit('setLoginEvent', data.payload.event)

      let user
      let email
      let signedIn = false
      switch (data.payload.event) {
        case 'signIn':
          showM.value = false
          if (data.payload.data.hasOwnProperty("attributes")) {
            emitter.emit("authStateUpdated", {
              email: data.payload.data.attributes.email,
              state: 'LOGGED_IN'
            })
          }
          break
        case 'signUp':
          showM.value = false
          email = data.payload.data.user.username
          emitter.emit("authStateUpdated", {
            email: email,
            state: 'REGISTERED'
          })
          break
        case 'signOut':
          showM.value = false
          store.commit('setLoginEvent', 'LOGGED_OUT')
          localStorage.removeItem('storedAccounts');
          localStorage.removeItem('typeAccount');
          localStorage.removeItem('activeAccount');
          emitter.emit("authStateUpdated", {
            state: 'LOGGED_OUT'
          })
          break
        case 'signIn_failure':
          // console.log('user sign in failed')
          break
        case 'configured':
          // console.log('the Auth module is configured')
          break
        case 'forgotPasswordSubmit':
          // console.log('submitted a forgotten password code')
          break
        case 'tokenRefresh_failure':
          // console.log('token refresh failure!')
          break
        default:
          // console.log('unknown event: ' + data.payload.event)
          break
      }
    })

    const doAuthenticatedUser = async (email) => {
      showM.value = true

      let userInfo = await getUserByEmail(email).catch((error) => {
        logger.error(error)
      })

      console.log(userInfo);

      if (!userInfo) {
        userInfo = await registerUserBasicStep(email)
      }

      if (userInfo) {

        store.commit('setUser', userInfo)
        emitter.emit('userUpdated')
        localStorage.setItem('activeAccount', userInfo.registerStatus)


        // let preferences = await getPreferenceByEmail(email).catch((error) => {
        //   logger.error(error)
        // })

        // if (!preferences) {
        //   preferences = await initializePreferences(email).catch((error) => {
        //     logger.error(error)
        //   })
        // }

        // if (preferences) {
        //   let htmlElement = document.documentElement

        //   switch (preferences.theme) {
        //     case 'DARK':
        //       htmlElement.setAttribute('theme', 'dark')
        //       break
        //     case 'LIGHT':
        //       htmlElement.setAttribute('theme', 'light')
        //       break
        //   }

        //   store.commit('setPreferences', preferences)
        //   emitter.emit('preferencesUpdated')
        // } else {
        //   logger.error('unable to retrieve user preferences')
        // }


        let htmlElement = document.documentElement
        htmlElement.setAttribute('theme', 'light')

        let preferences = ref([])
        preferences.value.theme = 'LIGHT'

        store.commit('setPreferences', preferences)
        console.log(store.state.preferences.theme)

        emitter.emit('preferencesUpdated')

        if (userInfo.applicationID) {
          let application = "Application Done"
          
          // if (userInfo.registerStatus != "ACTIVE") {
          //   application = await getApplicationsByEmail(email, userInfo.applicationID).catch((error) => {
          //     logger.error(error)
          //   })
          // }

          // if (!application) {
          //   application = await createAndAssignAnApplicationProcess(userInfo, 'INDIVIDUAL').catch((error) => {
          //     logger.error(error)
          //   })
          // }
          
          if (application) {
            store.commit('setApplication', application)
            emitter.emit('applicationUpdated')
          }
        }
        emitter.emit("userSet")
        let routerChanger = window.location.pathname.replace(/\/$/, "").split("/").pop();
        let statusUser = store.state.user.registerStatus

        if (statusUser !== undefined && statusUser !== null) {

          switch (statusUser) {
            case 'AT_PASSWORD':
              let accountRegis = localStorage.getItem('typeAccount') === null ? 'live-account' : localStorage.getItem('typeAccount')
              router.replace('/register/2/' + accountRegis).catch(error => {logger.error(error)})
              break
            case 'INACTIVE':
              await Auth.signOut()
              break
            default:
              // if (userObj.preferredMFA == 'NOMFA' && routerChanger != 'applications') {
              //   routerChanger = '/cabinet/console/mfa'
              // }
              switch (routerChanger) {
                case 'login':
                  if(statusUser !== 'ACTIVE')
                    routerChanger = '/cabinet/console/applications'
                  else
                    routerChanger = '/cabinet/console/accounts'
                    break
                case 'live-account':
                  routerChanger = '/cabinet/console/applications'
                  break
                case 'demo-account':
                  routerChanger = '/cabinet/console/create_new_demo_account'
                  break
              }

              document.title = "Client Cabinet | Tradeview Europe";
              router.replace(routerChanger).catch(error => {
                logger.error(error)
              })
              break
          }
        }

        showM.value = false

        //  TODO: only if not subscribed
        // subscribeToUserChanges(userInfo.id)
      } else {
        throw "CRITICAL ERROR: Contact tech team!"
      }
    }

    //  APLICA REGLAS DE NEGOCIO SEGUN AUTH
    const checkAuthUser = () => {
      Auth.currentAuthenticatedUser().then((user) => {
        store.commit('setToken', user.signInUserSession.idToken.getJwtToken())
        doAuthenticatedUser(user.attributes.email)
      }).catch(err => {
        console.log('WARNING: error during checkAuthUser flow or user logged out; details follow...')
        console.log(err)
        store.commit('clearUser')
      })
    }

    const signOut = async () => {
      await Auth.signOut()
      emitter.emit('successfulRequest')
    }

    async function signOutGlobal() {
      try {
        await Auth.signOut({ global: true });
      } catch (error) {
        console.log('error signing out: ', error);
      }
    }

    const federatedSignIn = async (params) => {
      showM.value = true
      Auth.federatedSignIn({ provider: params.provider })
    }

    const signIn = (email, password) => {
      showM.value = true
      let newPassword = password
      let regInfo = store.state.userRegistration

      Auth.signIn(email, password).then(user => {
        userObj = user
        switch (user.challengeName) {
          case 'NEW_PASSWORD_REQUIRED':
            showM.value = false
            Auth.completeNewPassword(user,newPassword).then(user => {
              // at this time the user is logged in if no MFA required
            }).catch(e => {
              emitter.emit('showDialog', e.message)
            })
            break
          case 'SOFTWARE_TOKEN_MFA':
            showM.value = false
            emitter.emit('showTOTPDialog', i18n.global.t('mfa.emiter'))
            break
        case 'SMS_MFA':
            showM.value = false
            emitter.emit('showTOTPDialog', i18n.global.t('mfa.emiter'))
            break
          default:
            showM.value = false
            let jwt = store.state.token
            let userInfo = localStorage.getItem("activeAccount")

            // if (typeof userInfo === 'undefined' && userInfo !== null) {
            //   if (result) {
            //     let regInfo = store.state.userRegistration
            //     if (typeof regInfo !== 'undefined' && regInfo !== null) {
            //       performSignUpFlow(regInfo, jwt).then((result) => {
            //         store.commit('setUser', result.user)
            //         router.replace('/cabinet/console/applications').catch(err => { });

            //       }).catch((error) => {
            //         console.error(error)
            //       })

            //     }
            //   } else {
            //     store.commit('setUser', result)
            //   }
            // }
            break
        }
        emitter.emit('successfulRequest')

      }).catch(e => {
        showM.value = false
        if (e.message === 'User is not confirmed.') {
          //OJO! PREGUNTAR A ERWIN SI EL CODIGO COMENTADO SE DEBE ELIMINAR
          // dialogMessage.value = 'Please enter the activation code'
          // showCodeDialog.value = true
          emitter.emit('requestNewCode', {
            email: email
          })
          // if (store.state.loginFlow === 'LOGIN') {
          //   emitter.emit('showCodeDialogAtLogin', "Please enter the activation code")
          // } else {
          //   emitter.emit('showCodeDialog', "Please enter the activation code")
          // }
          //emitter.emit('failedRequest')
        } else {
          // dialogMessage.value = e.message
          // showDialog.value = true
          emitter.emit('showDialog', e.message)
          emitter.emit('failedRequest')
        }
        // loginBox.value.release()
      })

    }

    const signUp = async (email, password, disclaimer) => {
      let username = email
      showM.value = true
      try {
        const { user } = await Auth.signUp({ username, password, disclaimer, attributes: { email } })
        emitter.emit('successfulRequest')
      } catch (error) {
        showM.value = false
        emitter.emit('showDialog', error.message)
        if (error.code === 'UsernameExistsException') {
          setTimeout(() => {
            router.replace('/login')
          }, 5000)
        }
        emitter.emit('failedRequest')
      }
    }

    const requestNewCode = async (email) => {
      try {
        Auth.resendSignUp(email).then((_) => {
          if (store.state.loginFlow === 'LOGIN') {

            emitter.emit('showCodeDialogAtLogin', i18n.global.t('register.codeActivation'))
          } else {
            emitter.emit('showCodeDialog', i18n.global.t('register.codeActivation'))
          }
          //emitter.emit('successfulRequest')

        }).catch((error) => {
          if (error.code === 'LimitExceededException') {
            if (store.state.loginFlow === 'LOGIN') {
              emitter.emit('showDialog', i18n.global.t('register.tryAgain'))
            } else {
              emitter.emit('showDialog', i18n.global.t('register.tryAgain'))
            }
          } else {
            emitter.emit('showCodeDialogErrorMessage', error.message)
          }
          emitter.emit('failedRequest')
        })

      } catch (error) {
        emitter.emit('showDialog', error.message)
        emitter.emit('failedRequest')
      }
    }

    const requestConfirmSignUp = async (email, newCode) => {
      try {
        Auth.confirmSignUp(email, newCode).then(() => {
          emitter.emit('showDialog', i18n.global.t('register.continue'))
          emitter.emit('closeAllLoginDialogs')
          emitter.emit('closeAllRegisterDialogs')
          emitter.emit('successfulRequest')

          router.replace('/register/2/' + localStorage.getItem('typeAccount')).catch(error => {
            console.error(error)
          })

        }).catch((error) => {
          emitter.emit('showCodeDialogErrorMessageAtLogin', error.message)
          emitter.emit('failedRequest')
        })

      } catch (error) {
        emitter.emit('showDialog', error.message)
        emitter.emit('failedRequest')
      }
    }

    const requestConfirmSignUpAndLogin = async (email, newCode, pass) => {
      showM.value = true
      try {
        Auth.confirmSignUp(email, newCode).then(() => {
            emitter.emit('closeAllLoginDialogs')
            emitter.emit('closeAllRegisterDialogs')
            showM.value = false
            return Auth.signIn(email, pass)
          })
          .then(() => {
            showM.value = false
            emitter.emit('successfulRequest')
          })
          .catch((error) => {
            showM.value = false
            emitter.emit('showCodeDialogErrorMessage', error.message)
            emitter.emit('failedRequest')
          })

      } catch (error) {
        emitter.emit('showDialog', error.message)
        emitter.emit('failedRequest')
      }
    }

    const requestForgotPassword = async (email) => {

      Auth.forgotPassword(email).then(data => {
          showM.value = false
          emitter.emit('showForgotDialog', i18n.global.t('loginPage.checkEmail'))
          emitter.emit('successfulRequest')
        })
        .catch(error => {
          emitter.emit('showDialog', error.message)
          emitter.emit('failedRequest')
        })
    }

    const requestTOTPLogin = async(totpCode) => {
      Auth.confirmSignIn(userObj, totpCode.value.toString(), userObj.challengeName).then(user => {
        showM.value = false
        emitter.emit('successfulRequest')
      }).catch(e => {
        emitter.emit('showDialog', e.message)
        emitter.emit('failedRequest')
      })
    }

    // const createAndAssignAnApplicationProcess = (user, accountType) => new Promise((resolve, reject) => {
    //   let applicationProcess
    //   showM.value = true
    //   let _url = process.env.VUE_APP_CABINET_V2_API_HOST + '/v1/fg-users/CREATE'
    //   let _headers = {
    //     'Content-Type': 'application/json',
    //     'Accept': 'application/json',
    //     'Authorization': 'Bearer ' + store.state.token,
    //     'X-referrer': 'login',
    //     'X-Api-Key': process.env.VUE_APP_X_API_KEY
    //   }
    //   let _data = {
    //     applicationType: accountType,
    //     disclaimer: true,
    //   }

    //   axios({ method: 'POST', url: _url, data: _data, headers: _headers }).then((result) => {
    //     let item = result.data.updateUser
    //     if (item != null) {
    //       resolve(applicationProcess)
    //       showM.false

    //     } else {
    //       showM.false
    //       logger.error('ERROR: unable to update the user record')
    //       reject('ERROR: unable to update the user record')
    //     }
    //   })
    //     .catch((error) => {
    //       logger.error(error)
    //       reject(error)
    //     })
    // })

    onMounted(() => {
      //  FORZAR REFRESH
      emitter.on('requestUserRefresh', () => {
        checkAuthUser()
      })

      //  VALIDAR USUARIO (FLUJO PARA REGISTRO)
      emitter.on('doAuthenticatedUser', (user) => {
        doAuthenticatedUser(user)
      })

      //  reload the application
      // emitter.on('requestApplicationRefresh', () => {
      //   let application = store.state.application
      //   showM.value = true
      //   if (application !== null) {
      //     getApplicationsByEmail(application.email, application.id).then((application) => {
      //         // console.log('--- GET APPLICATION ---')
      //         // console.log(application)

      //         store.commit('setApplication', application)
      //         emitter.emit('applicationUpdated')
      //         showM.value = false
      //       })
      //       .catch((error) => {
      //         logger.error(error)
      //       })
      //   }
      // })

      emitter.on('requestSignIn', (params) => {
        signIn(params.email, params.password)
      })

      emitter.on('requestSignUp', (params) => {
        signUp(params.email, params.password, params.disclaimer)
      })

      emitter.on('requestSignOut', () => {
        signOut()
      })

      emitter.on('requestRegister', (params) => {
        signUp(params.email, params.password)
      })

      emitter.on('requestNewCode', (params) => {
        requestNewCode(params.email)
      })

      emitter.on('requestTOTPLogin', (params) => {
        requestTOTPLogin(params.totpCode)
      })

      emitter.on('requestConfirmSignUp', (params) => {
        requestConfirmSignUp(params.email, params.newCode)
      })

      emitter.on('requestConfirmSignUpAndLogin', (params) => {
        requestConfirmSignUpAndLogin(params.email, params.newCode, params.password)
      })

      emitter.on('requestForgotPassword', (params) => {
        requestForgotPassword(params.email)
      })

      emitter.on('federatedSignIn', (params) => {
        federatedSignIn(params)
      })

      checkAuthUser()
    })

    onUnmounted(() => {
      emitter.off('requestApplicationRefresh')
      emitter.off('requestUserRefresh')
      emitter.off('requestSignIn')
      emitter.off('requestSignUp')
      emitter.off('requestSignOut')
      emitter.off('requestRegister')
      emitter.off('requestNewCode')
      emitter.off('requestConfirmSignUp')
      emitter.off('requestConfirmSignUpAndLogin')
      emitter.off('requestForgotPassword')
      emitter.off('requestTOTPLogin')
    })



    return {
      checkAuthUser,
      getUserByEmail,
      registerUserBasicStep,
      showM
    }
  }
}
</script>

<style lang="css">
@import "assets/css/style";
</style>