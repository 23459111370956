export default {
  //login home page
  loginHeader: {
    contactUs: 'Kontakt'
  },
  loginPage: {
      instructionstitle: 'Strefa Klienta',
      instructionsLine1: 'Zaloguj się używając swojego adresu e-mail i hasła utworzonego dla konta Tradeview.',
      instructionsLine2: 'W Strefie Klienta będziesz mógł:',
      featureList1: 'Sprawdzić podsumowanie swojego konta',
      featureList2: 'Zobaczyć historię rachunku',
      featureList3: 'Zasilić swoje konto',
      featureList4: 'Dokonać przelewu środków pomiędzy kontami',
      featureList5: 'Zażądać wypłaty z konta',
      pFooter: 'Usługi i produkty oferowane przez Tradeview Europe Ltd. nie są oferowane na terenie Stanów Zjednoczonych (USA) i nie są skierowane do osób z USA, zgodnie z definicją prawa amerykańskiego. Informacje zawarte na tej stronie nie są skierowane do mieszkańców krajów, w których handel walutami i / lub kontraktami CFD jest ograniczony lub zabroniony przez lokalne prawo lub przepisy. Kontrakty CFD są złożonymi instrumentami i wiążą się z wysokim ryzykiem szybkiej utraty środków pieniężnych z powodu dźwigni finansowej. 68,3% rachunków inwestorów detalicznych odnotowuje straty pieniężne w wyniku handlu kontraktami CFD z Tradeview Europe Ltd. Zastanów się, czy rozumiesz, jak działają kontrakty CFD, i czy możesz pozwolić sobie na wysokie ryzyko utraty pieniędzy. Więcej informacji na temat naszych opłat można znaleźć pod tym ',
      linkFooter: 'linkiem. ',
      adressOficce: 'Tradeview Europe Office:',
      termsAndConditions: 'Terms and Conditions',
      privacyPolicy: 'Privacy Policy',
      amlPolicy: 'AML Policy',
      refundPolicy: 'Refund Policy',
      riskDisclosure: 'Risk Disclosure',
      complaintsProcedure: 'Complaints Procedure',
      cookiePolicy: 'Cookie Policy',
      changeSucces: 'Zmiana się powiodła!',
      checkEmail: 'Sprawdź pocztę e-mail, aby uzyskać dalsze informacje',
      code: 'kod',
      newPassword: 'nowe hasło',
      repeatPassword: 'powtórz hasło',
      changePassword: 'zmień hasło'
  },
  loginBox: {
      logInGoogle: 'Zaloguj się przez Google',
      logInFacebook: 'Zaloguj się przez Facebook',
      or: 'albo',
      email: 'ADRES E-MAIL',
      password: 'HASŁO ',
      submit: 'Zaloguj',
      forgot: 'Zapomniałeś hasłą?',
      doNotAccount: "Nie masz konta?",
      signUp: 'Zarejestruj się',
      tryFreeDemo: 'wypróbuj darmowe konto demo',
      showDialog1: 'Wprowadź prawidłowy adres e-mail/hasło',
      changePass: 'Zmiana się powiodła!'
  },
  consoleHeader: {
      account: 'Account #',
      balance: 'Balance',
      equity: 'Equity',
      free: 'Free Margin',
      margin: 'Margin Levelator',
      status: 'Status',
      hello: 'Hello',
      logout: 'Log out',
      confirm: 'Potwierdź',
      outModal: 'Czy na pewno chcesz się wylogować?',
      yes: 'TAK',
      not: 'NIE '
  },

  // Platforms page
  platforms:{
      title: 'Pobierz Platformy',
      accountLive: 'Server Konto Live:',
      accountDemo: 'Server Konto Demo:'

  },

  //History page
  history:{
    title: 'Historia',
    accountActivity: 'Aktywność na rachunku',
    tradingHistory: 'Historia handlu'
  },

  // Withdraw page
  withdraw:{
      title: 'Wypłata',
      platform: 'Platforma',
      sourceAccount: 'Konto źródłowe*',
      method: 'Metoda*',
      currency: 'Waluta*',
      start: 'Wypłać teraz',
      beneficiary: '*Nazwa beneficjenta',
      details: 'Dane banku beneficjenta',
      number: '*Numer konta (IBAN, jeśli dotyczy)',
      code:'*Swift / Kod banku',
      reference: 'Numer referencyjny',
      subTitle: 'Dane banku pośredniczącego (jeśli dotyczy)',
      name_benef:'*Nazwa banku',
      bank: 'Adres bankuBank Address',
      swift: 'Swift banku',
      branch: 'Nazwa oddziału',
      province: 'Region',
      city:'Miasto',
      dialogShow:  'Błąd! Ta platforma nie ma aktywnych rachunków, nie możesz kontynuować procesu, wypróbuj inną platformę.',
      errorOne: 'Błąd! Nie może być pustych pól',
      errorTwo: 'Błąd! Kwota przekracza saldo konta',
      errorThree: 'Błąd! Wiadomość e-mail jest nieprawidłowa',
      errorFour: 'Błąd! Należy podać wyłącznie cyfry dla karty kredytowej',
      dialogShowTwo: "Zgłoszenie wysłane! Sprawdź swoją skrzynkę e-mail",
      dialogShowThree:  "Prosimy o kontakt z pomocą techniczną Tradeview",
      emailAddress: "Adres e-mail konta karty kredytowej",
      creditcard:"Karta kredytowa (ostatnie 4 cyfry)",
      netellerEmail: "Konto e-mail Neteller",
      netellerNumber: "Numer konta Neteller",
      skrillEmail: "Konto e-mail Skrill",
      skrillNumber: "Numer konta Skrill",
      upholdEmail: "Konto e-mail Uphold",
      upholdNumber: "Numer konta Uphold",
  },

  //Account Activity page
  accountActivity:{
      title: 'Aktywność na rachunku',
      from: '*Od',
      to:'*Do',
      type:'Typ',
      platform:'Platforma',
      placeholderAccount: '*Konto',
      account: '*Konto',
      get: 'Pobierz',
      row:'Wiersze dla strony',
      download:'Pobierz raport',
      back:'Powrót',
      confirm: 'Potwierdź',
      close:'Zamknij',
      error: 'Błąd! Nie może być pustych pól',
      errorTwo: 'Błąd! musisz wybrać typ pola',
      activityid: 'Identyfikacja',
      platfomr: 'Platforma',
      date: 'Data / Czas',
      activity: 'Aktywność',
      profit: 'Zysk/Strata',
  },


  //tradingHistory
  tradingHistory:{
      title: 'Historia handlu',
      from: '*Od',
      to:'*Do',
      type:'*Typ',
      platform:'*Platforma',
      placeholderAccount: '*Konto',
      account: '*Konto',
      get: 'Pobierz',
      row:'Wiersze dla strony',
      download:'Pobierz raport',
      back:'Powrót',
      confirm: 'Potwierdź',
      close:'Zamknij',
      error: 'Błąd! Nie może być pustych póls',
      errorTwo: 'Błąd! musisz wybrać typ pola',
      deal:'Pozycja',
      entryTime: 'Czas Wejścia',
      typr: 'Typ',
      simbol: 'Symbol',
      volume:'Wolumen',
      entryPrice: 'Cena Wejścia',
      sl: 'S/L',
      tp: 'T/P',
      exitTime: 'Czas Wyjścia',
      exitPrice: 'Cena Wyjścia',
      commision:'Prowizja',
      swap: 'Swap',
      profit: 'Zysk / Strata'

  },

  newLiveAccount:{
      title:'Utwórz nowe konto live',
      platform:'* Wybierz platformę',
      currency:'* Waluta',
      account: 'Utwórz konto',
      back:'Powrót',
      congratulation:'Gratulacje',
      text:'Twój wniosek o konto live jest sprawdzany i wkrótce zostanie zweryfikowany. Nasz zespół operacyjny skontaktuje się z Tobą, jeśli będziemy potrzebować dodatkowych dokumentów.',
      error:'Błąd! Nie może być pustych pól',
      close:'Zamknij',
  },

  //Transfer page
  transfer:{
      title: 'Przelew pomiędzy rachunkami handlowymi',
      subTitle: 'W celu dokonania przelewu środków z jednego rachunku Tradeview na drugi, należy wypełnić poniższy formularz.',
      platform: 'Platforma',
      sourceAcount: 'Konto źródłowe',
      targetAccount: 'Konto docelowe',
      sendingAmount: 'Kwota przelewu',
      condition: 'Zaznaczając to pole rozumiem, że wnioskuję o przelanie środków pieniężnych z jednego rachunku Tradeview Europe Ltd. na inny rachunek Tradeview Europe Ltd.',
      start: 'Przelew',
      showDialogOne: 'Prosimy o kontakt z pomocą techniczną Tradeview',
      showDialogTwo:'Prosimy o kontakt z pomocą techniczną Tradeview',
      submited: 'Zgłoszenie wysłane! Sprawdź swoją skrzynkę e-mail',
      error:'Błąd! Nie może być pustych pól',
      errorTwo:'Błąd! Numery kont muszą być różne',
      errorThree:'Błąd! Kwota jest nieprawidłowa',
      errorFour:'Błąd! Kwota przekracza saldo konta' 
  },

  //Accounts
  accounts:{
      warning:'Your Demo Account Request is being processed. Final status will be notiﬁed at your email.',
      live:'Rachunek Live',
      demo:'Rachunek Demo',
      createLive:'+ Otwórz nowy Rachunek Live',
      createDemo:'+ Otwórz nowy Rachunek Demo',
      noAccountLive: 'Brak konta live pod adresem e-mail',
      noAccountDemo: 'Brak konta demo pod adresem e-mail',
      dialogShowOne: 'Obecnie nie posiadasz żadnego konta demo Metatrader 4',
      dialogShowTwo: 'Obecnie nie posiadasz żadnego konta demo Metatrader 5',
      dialogShowThree: 'Obecnie nie posiadasz żadnego konta live Metatrader 4',
      dialogShowFour: 'Obecnie nie posiadasz żadnego konta live Metatrader 5',
      account: 'KONTO #',
      balance: 'SALDO',
      equity: 'SALDO OPERACYJNE',
      margin:'ZABEZPIECZENIE',
      free: 'WOLNE ŚRODKI',
      status: 'STATUS',
      leverage:'DŹWIGNIA FINANSOWA',
      currency: 'WALUTA'
  },

  //CreateDemoAccounts
  demoAccounts:{
      title:'Utwórz nowe konto demo',
      platform:'* Wybierz platformę',
      type:'* Typ konta',
      amount:'* Kwota depozytu',
      currency: '* Waluta',
      account: 'Utwórz konto',
      back:'Powrót',
      created:'Twoje konto demo zostało utworzone',
      text:'Przed kliknięciem jakiejkolwiek opcji lub zamknięciem tego okna należy zapisać swoje dane uwierzytelniające, które NIE zostaną wysłane na adres e-mail.',
      login: 'Login',
      pass:'Hasło',
      currencyTwo:'Waluta',
      server:'Serwer',
      data:'TradeviewEurope-Demo Europe',
      download:"Platforma handlowa jest już gotowa do pobrania:",
      close:'Zamknij',
      error:'Błąd! Nie może być pustych pól',
      professional: 'Profesjonalne',
      retail: 'Indywidualne',
      stocks: 'Stocks'
  },

  //credit card
  credit:{
      title: 'Karty Kredytowe',
      subTitle: 'Ten wybór nie jest jeszcze dostępny',
      subTitleAdd: 'Dodaj wpłatę',
      platform: 'Platforma',
      trading: 'Rachunek',
      amount:'* Kwota (Min. 100 USD - max. 10,000 USD)',
      currency: '* Waluta',
      details: 'Dane karty kredytowej',
      cardName: '* Imię i nazwisko posiadacza karty',
      cardNumber: '* Numer karty',
      expiration: 'Data ważności',
      fund: 'Wpłać',
      disclaimer: 'Płatności są akceptowane wyłącznie z konta / karty zarejestrowanej na Twoje nazwisko. Należy pamiętać, że możemy poprosić o dodatkowe dokumenty oraz informacje w celu weryfikacji.',
      back: 'Powrót',
      textOne: 'czy na pewno chcesz dokonać wpłaty za pomocą',
      textTwo: 'karty kredytowej?',
      cancel: 'Anuluj',
      confirm: 'Potwierdź',
      deposit: 'Wpłać',
      ok: 'Ok',
      depositUrl: 'Przekierowanie do Zotapay',
      errorOne:'Błąd! Kwota (min. 100 USD - maks. 10 000 USD)',
      errorTwo:'Błąd! Nie może być pustych pól',
      errorThree:'Błąd! Kod bezpieczeństwa nie jest prawidłowy',
      errorFour:'Błąd! Data wygaśnięcia nie jest prawidłowa',
      errorFive:'Błąd! Numer karty nie jest prawidłowy',
  },


  //Banck Transfer add found Skrill
  addFounds:{
    title:'Dodaj wpłatę ',
    platform: 'Platforma',
    account:'Rachunek',
    currency:'Waluta',
    amount:'Kwota (Min. 100 USD - max. 10,000 USD)',
    email: 'E-mail',
    disclaimer:'Płatności są akceptowane wyłącznie z konta / karty zarejestrowanej na Twoje nazwisko. Należy pamiętać, że możemy poprosić o dodatkowe dokumenty oraz informacje w celu weryfikacji.',
    fund:'Wpłać',
    back:'Powrót',
    redi:'Przekierowanie',
    redirection:'Przekierowanie do',
    checkmark:'Zaznaczając to pole zgadzasz się na wszystkie zasady Tradeview Europe Ltd.',
    submit:'Zatwierdź',
    contactSupport: 'Prosimy o kontakt z pomocą techniczną Tradeview'
},

 //Add Bank Funds
 addBanckFounds:{
    title:'Dodaj wpłatę ',
    description:'Prosimy użyć poniższych informacji do dokonania przelewu:',
    back:'Powrót',
    headers1: 'Dla wpłat w USD',
    rows1Headers1: 'Bank Odbiorcy:',
    subrows1Headers1:'BMO Harris Bank, N.A',
    rows2Headers1:  'Swift Odbiorcy:',
    subrows2Headers1: 'HATRUS44',
    rows3Headers1:  'Numer ABA:',
    subrows3Headers1: '071000288',
    rows4Headers1:  'Tytuł:',
    subrows4Headers1: '(Your Name + Tradeview Account No.)',
    rows5Headers1:  'Nazwa odbiorcy:',
    subrows5Headers1: 'Tradeview Europe Limited',
    rows6Headers1:  'Numer konta:',
    subrows6Headers1: '4243275',
    rows7Headers1:  'Adres banku:',
    subrows7Headers1: '111 West Monroe St, Chicago, IL 60690',
    rows8Headers1:  'Adres odbiorcy:',
    subrows8Headers1: '114/3 The Strand, Gzira, GZR 1027, Malta',
    headers2: 'Dla wpłat w EUR',
    rows1Header2: 'Bank Odbiorcy:',
    subrows1Header2: 'BMO Harris Bank, N.A',
    rows2Header2: 'Swift Odbiorcy:',
    subrows2Header2: 'HATRUS44GTM',
    rows3Header2: 'Bank Pośredniczący:',
    subrows3Header2: 'Deutsche Bank - Frankfurt',
    rows4Header2: 'Swift Pośredniczący:',
    subrows4Header2: 'DEUTDEFF',
    rows5Header2: 'Tytuł:',
    subrows5Header2: '(Your Name + Tradeview Account No.)',
    rows6Header2: 'Nazwa odbiorcy:',
    subrows6Header2: 'Tradeview Europe Limited',
    rows7Header2: 'Numer konta:',
    subrows7Header2: '300894250262',
    rows8Header2: 'Adres Banku:',
    subrows8Header2: '111 West Monroe St, Chicago, IL 60603',
    rows9Header2: 'Adres odbiorcy:',
    subrows9Header2: '114/3 The Strand, Gzira, GZR 1027, Malta',
    headersSecond:'For GBP, EUR, USD, CHF and JPY Deposits',
    rows1HeadersSecond: 'Bank Odbiorcy:',
    subrows1HeadersSecond: 'The Currency Cloud Limited',
    rows2HeadersSecond: 'Swift Odbiorcy:',
    subrows2HeadersSecond: 'TCCLGB3L',
    rows3HeadersSecond: 'IBAN No:',
    subrows3HeadersSecond: 'GB62TCCL04140476597833',
    rows4HeadersSecond: 'Tytuł:',
    subrows4HeadersSecond: '210408-56275 + Your Name and Tradeview Account No.',
    rows5HeadersSecond: 'Nazwa odbiorcy:',
    subrows5HeadersSecond: 'Tradeview Europe Limited',
    rows6HeadersSecond: 'Numer konta:',
    subrows6HeadersSecond: '4243275',
    rows7HeadersSecond: 'Adres Banku:',
    subrows7HeadersSecond: '12 Steward Street, The Steward Building, London, E1 6FQ',
    rows8HeadersSecond: 'Adres odbiorcy:',
    subrows8HeadersSecond: '114/3 The Strand, Gzira, GZR 1027, Malta',
    headersSecond2: 'Dla wpłat w EUR, CHF, DKK, GBP, HUF, NOK, PLN, USD',
    rows1HeadersSecond2: 'Nazwa odbiorcy:',
    subrows1HeadersSecond2: 'Tradeview Europe Limited',
    rows2HeadersSecond2: 'Waluta:',
    subrows2HeadersSecond2: 'EUR, CHF, DKK, GBP, HUF, NOK, PLN, USD',
    rows3HeadersSecond2: 'IBAN:',
    subrows3HeadersSecond2: 'DK5789000051025190',
    rows4HeadersSecond2: 'BIC:',
    subrows4HeadersSecond2: 'SXPYDKKK',
    rows5HeadersSecond2: 'Tytuł:',
    subrows5HeadersSecond2: '(Your Name + Tradeview Account No.)',
    rows6HeadersSecond2: 'Bank Name:',
    subrows6HeadersSecond2: 'The Currency Cloud Limited',
    rows7HeadersSecond2: 'Bank Address:',
    subrows7HeadersSecond2: 'AMERIKA PLADS, 38 COPENHAGEN 2100 Denmark',
    rows8HeadersSecond2: 'Adres odbiorcy:',
    subrows8HeadersSecond2: '114/3 The Strand, Gzira, GZR 1027, Malta',
    headers3: 'Dla wpłat w GBP',
    rows1Headers3: 'Bank odbiorcy:',
    subrows1Headers3:'BMO Harris Bank, N.A.',
    rows2Headers3:  'Kod SWIFT odbiorcy:',
    subrows2Headers3: 'HATRUS44GTM',
    rows3Headers3:  'Nazwa odbiorcy:',
    subrows3Headers3: 'Tradeview Europe Limited',
    rows4Headers3:  'Numer konta:',
    subrows4Headers3: '777554250262',
    rows5Headers3:  'Bank pośredniczący:',
    subrows5Headers3: 'Royal Bank of Scotland- London',
    rows6Headers3:  'Kod SWIFT banku pośredniczącego:',
    subrows6Headers3: 'NWBKGB2L'
},

//Choose bank
chooseBank:{
    title:'Wybierz bank',
    method:'METODA WPŁATY',
    processing:'CZAS WPŁATY',
    fee:'PROWIZJA',
    back:'Powrót',
    instruction:"Straty mogą przekroczyć początkową zainwestowaną kwotę. Płatności stron trzecich NIE będą akceptowane. Wszystkie depozyty MUSZĄ pochodzić od pierwotnego właściciela konta. Nominał platformy handlowej jest w USD. Tradeview Europe Ltd. zastrzega sobie prawo do odesłania/zwrotu wszelkich depozytów dokonanych za pomocą karty kredytowej w miejsce wypłaty."
},

//Choose Credit Card
ChooseCreditCard:{
    title:'Karty Kredytowe',
    method:'METODA WPŁATY',
    processing:'CZAS WPŁATY',
    fee:'PROWIZJA',
    back:'Powrót',
    messageZotapay: 'Zotapay is temporarily unavailable',
    instructionOne:"Tradeview Europe Ltd. zawsze dąży do poprawy doświadczeń naszych klientów. Z tego powodu zapewniamy klientom szybkie i skuteczne metody płatności. W razie potrzeby prosimy o",
    contact:'kontakt',
    instructionTwo:'z naszym zespołem wsparcia, który chętnie pomoże przyspieszyć realizację żądania.',
    instructionThree:"Płatności osób trzecich NIE będą akceptowane. Wszystkie depozyty MUSZĄ pochodzić od pierwotnego właściciela konta. Waluta platformy transakcyjnej jest denominowana w EUR lub USD. Tradeview Europe Ltd. zastrzega sobie prawo do wypłaty / zwrotu za pomocą karty kredytowej."
},

//Choose alternative methods
ChooseAlternativeMethods:{
    title:'Wybierz inne metody',
    method:'METODA WPŁATY',
    processing:'CZAS WPŁATY',
    optionProcessing: 'Natychmiastowy 24/7',
    fee:'PROWIZJA',
    back:'Powrót',
    instructionOne:"Straty mogą przekroczyć początkową zainwestowaną kwotę. Płatności stron trzecich NIE będą akceptowane. Wszystkie depozyty MUSZĄ pochodzić od pierwotnego właściciela konta. Nominał platformy handlowej jest w USD. Tradeview Europe Ltd. zastrzega sobie prawo do odesłania/zwrotu wszelkich depozytów dokonanych za pomocą karty kredytowej w miejsce wypłaty."
},


  //deposit
  deposit:{
      title: 'Wybierz metodę wpłaty',
      vendor: 'METODA WPŁATY',
      processingCards: 'CZAS WPŁATY',
      fee: 'PROWIZJA',
      methodOne: 'Karty Kredytowe',
      methodTwo: 'Inne Metody',
      methodThree: 'Przelew Bankowy',
      textOne:"Tradeview Europe Ltd. zawsze dąży do poprawy doświadczeń naszych klientów. Z tego powodu zapewniamy klientom szybkie i skuteczne metody płatności. W razie potrzeby prosimy o",
      textTwo:' kontakt ',
      textThree:'z naszym zespołem wsparcia, który chętnie pomoże przyspieszyć realizację żądania.',
      textFour: "Płatności osób trzecich NIE będą akceptowane. Wszystkie depozyty MUSZĄ pochodzić od pierwotnego właściciela konta. Waluta platformy transakcyjnej jest denominowana w EUR lub USD. Tradeview Europe Ltd. zastrzega sobie prawo do wypłaty / zwrotu za pomocą karty kredytowej.",
  },

  //Email
  email:{
      title: 'Ustawienia adresu e-mail',
      subTitle: 'Tutaj możesz zarządzać swoimi subskrypcjami. Wybierz tematy, odnośnie których chcesz otrzymywać newsletter:',
      company: 'Wiadomości firmowe o nowości i ofertach',
      selectOne: 'Wiadomości tradingowe (',
      selectTwo: "Surf's Up!",
      selectThree: 'newsletter)',
      update:'Aktualizuj',
      instructions: 'Jeśli nie chcesz już otrzymywać e-maili na żaden z powyższych tematów, po prostu odznacz je. Nie zapomnij kliknąć Aktualizuj.',
      confirm: 'Potwierdź',
      close: 'Zamknij',
      resqSubmited: 'Zgłoszenie wysłane!'
  },

  //password
  password:{
      title:'Zmień hasło',
      subTitle: 'Aby zmienić hasło, poproś o jednorazowy kod na swoje konto e-mail.',
      request:'Wyślij',
      submit:'Zatwierdź',
      code:'kod',
      current:'Aktualne hasło',
      newPassword: 'Nowe hasło',
      repeat:'Potwierdź nowe hasło',
      save: 'Zapisz zmiany',
      confirm: 'Potwierdź',
      close:'Zamknij',
      dialogMessage: 'Jednorazowy kod został wysłany na podany adres e-mail.',
      dialogMessageOne:'Wpisz aktualne hasło',
      dialogMessageTwo:'Podaj nowe hasło',
      dialogMessageThree:'Potwierdź nowe hasło',
      dialogMessageFour:"Nowe/powtórzone hasło nie pasują do siebie",
      success: "Zmiana się powiodła!"
  },

  //Contact
  contact:{
      title: 'Prośba o kontakt telefoniczny',
      firstname: 'IMIĘ*',
      lastname: 'NAZWISKO*',
      phone: 'NUMER TELEFONU*',
      email: 'ADRES E-MAIL*',
      lenguage: 'JĘZYK*',
      call: 'PREFEROWANY CZAS ROZMOWY',
      check: 'Zadzwoń do mnie tak szybko jak to możliwe',
      contactme:'Skontaktuj się',
      error: 'Błąd! Nie może być pustych pól',
      close: 'Zamknij',
      resqSubmited: 'Zgłoszenie wysłane!, Sprawdź swoją skrzynkę e-mail'
  },

  menu:{
    applications: 'Aplikacje',
    account: 'Rachunki',
    platforms: 'Platformy',
    history: 'Historia',
    deposit: 'Wpłata',
    transfer: 'Przelewy',
    withdraw: 'Wypłata',
    settings: 'Ustawienia',
    passwordsettings: 'Ustawienia hasła',
    emailsettings: 'Ustawienia e-mail',
    authenticationsettings: 'Ustawienia identyfikacji',
    contactUs: 'Kontakt'

  },

  //register
  register:{
    title: 'Rejestracja Konta',
    create: 'Utwórz login',
    createdes: 'Wpisz swój e-mail i utwórz hasło, aby rozpocząć rejestrację ',
    complete: 'Wypełnij wniosek',
    completedes: 'Wypełnij nasz szybki i bezpieczny formularz zgłoszeniowy',
    confirm: 'Potwierdź swoją tożsamość',
    confirmdes: 'Zweryfikuj swoją tożsamość online dowodem osobistym lub w razie potrzeby prześlij nam dokumenty',
    fund: 'Wpłata środków i handel',
    funddes: 'Po zatwierdzeniu, zasil konto korzystając z naszych elastycznych i tanich metod zasilenia rachunku i zacznij handlować',
    goBack: 'Powrót',
    getYourLive: 'Załóż swoje konto live już teraz!',
    getYourDemo: 'Załóż swoje konto Demo już teraz!',
    instantly: 'Natychmiastowa rejestracja:',
    registerGoogle: 'Zaloguj się przez Google',
    registerFacebook: 'Zaloguj się przez Facebook',
    or: 'ALBO',
    email: '*ADRES E-MAIL',
    secure: '*BEZPIECZNE HASŁO',
    length: '(Hasło musi zawierać co najmniej 6 znaków, w tym cyfry i znaki specjalne.)',
    confirmPassword: '*POWTÓRZ HASŁO',
    disclaimer: 'ZASTRZEŻENIE:',
    disclaimerdes1: ' Przed otworzeniem rachunku, naszym obowiązkiem jako ',
    disclaimerdes2: " jest upewnienie się, że Klient lub osoby wyznaczone do handlu w jego imieniu posiadają niezbędną wiedzę na temat CFD i powiązanych instrumentów finansowych. Naszym obowiązkiem jest również spełnienie wymogów AML i CFT. W związku z tym prosimy o dostarczenie dokładnych, kompletnych i rzeczowych informacji zgodnie z wymaganiami niniejszej aplikacji w celu zapewnienia przez nas pełnej zgodności z powyższymi zobowiązaniami. Na podstawie tych danych jesteśmy w stanie właściwie określić, czy usługi inwestycyjne związane z oferowanymi instrumentami finansowymi są dla Państwa odpowiednie. Wszystkie sekcje muszą być wypełnione. Więcej informacji dostępne w dokumencie ",
    clientAgree: 'Umowa z Klientem.',
    byTicking: '*KLIKAJĄC TUTAJ ZGADZASZ SIĘ NA POWYŻSZE',
    wish: '*CHCĘ JAKO PIERWSZY/A DOWIADYWAĆ SIĘ O ALERTACH DOTYCZĄCYCH USTAWIEŃ HANDLOWYCH, PRODUKTACH, BIULETYNACH I OFERTACH SPECJALNYCH.',
    policity: '(POLITYKA PRYWATNOŚCI)',
    getstarted: 'Rozpocznij',
    messageFooter1: 'Usługi i produkty oferowane przez ',
    messageFooter2: ' nie są oferowane w Stanach Zjednoczonych (USA) i nie są oferowane osobom z USA, zgodnie z definicją zawartą w prawie amerykańskim. Informacje na tej stronie nie są skierowane do mieszkańców żadnego kraju, w którym handel FX i/lub CFD jest ograniczony lub zabroniony przez lokalne przepisy prawa lub regulacje. Kontrakty CFD są złożonymi instrumentami i wiążą się z wysokim ryzykiem szybkiej utraty środków pieniężnych z powodu dźwigni finansowej. 68,3% rachunków inwestorów detalicznych odnotowuje straty pieniężne w wyniku handlu kontraktami CFD z Tradeview Europe Ltd. Zastanów się, czy rozumiesz, jak działają kontrakty CFD, i czy możesz pozwolić sobie na wysokie ryzyko utraty pieniędzy. Więcej informacji na temat naszych opłat można znaleźć pod tym',
    youcan: 'Możesz korzystać z ',
    freeDemo: 'bezpłatnego konta demo ',
    desYouCan: 'za pomocą naszych platform transakcyjnych na komputery oraz urządzenia mobilne z systemami iOS i Android, a także uzyskać dostęp do ',
    client: 'Gabinetu Klienta',
    desYouCan2: ', będącego internetowym portalem do zarządzania kontem.',
    practice: 'Ćwicz',
    practicedes: 'Handluj wirtualną walutą na symulowanym koncie',
    learn: 'Poznaj nasze narzędzia i usługi',
    learndes: 'Zapoznaj się z oprogramowaniem platform',
    msgCode1: 'Proszę wprowadzić kod aktywacyjny, który został wysłany na Twój adres e-mail.',
    msgCode2: 'Upewnij się, że nie trafił on do folderu spam.',
    sendNewCode: 'Wyślij kod ponownie',
    submit: 'Zatwierdź',
    formmessage1: 'Już prawie! Wybierz rodzaj konta i',
    formmessage2: 'podaj podstawowe informacje',
    formemail: '*ADRES E-MAIL',
    formaccounttype: '** Typ konta:',
    individual: 'Individual',
    formfirtname: '*IMIĘ',
    formlastname: '*NAZWISKO',
    formregister: 'Zarejestruj',
    missingFields: 'Brakujące pola',
    requiredFields: 'Wypełnij wszystkie wymagane pola',
    passwordnotmatch: 'Hasła nie pasują do siebie',
    passwordEspCharacter: 'Hasło musi zawierać co najmniej jeden znak specjalny.',
    passwordOneNumber: 'Hasło musi zawierać co najmniej jedną cyfrę.',
    passwordCharacter: 'Długość co najmniej 6 znaków, w tym cyfry i znaki specjalne.',
    validEmailPass: 'Wprowadź prawidłowy adres e-mail i hasło.',
    codeActivation: 'Na Twój adres e-mail został wysłany nowy kod. Wprowadź kod aktywacyjny.',
    tryAgain: 'Spróbuj ponownie później',
    continue: "Success! Please continue registering",
    pleaseReview: "Sprawdź wprowadzone dane"
  },

  //Calendar
  calendar:{
    title:'Calendar'
  },

  //MFA
  mfa:{
    title:'Ustawienia uwierzytelnienia',
    subTitle:'This section is not yet available',
    enable:'Włącz uwierzytelnianie dwuetapowe',
    body:'Oprócz hasła, skonfiguruj pomocniczą metodę weryfikacji, aby Twoje konto było jeszcze bezpieczniejsze.',
    text:'Po włączeniu uwierzytelniania dwuetapowego konieczne będzie wprowadzenie hasła oraz kodu weryfikacyjnego wysłanego na adres e-mail.',
    turn:'Włącz',
    confirm:'Potwierdź',
    textTwo:'Czy na pewno chcesz wyłączyć 2FA?',
    yes:'TAK',
    no:'NIE',
    qr:'Zeskanuj kod QR',
    type:'Type the generated code: ',
    setup: "Konfiguracja 2-czynnikowa",
    insert: "Wprowadź kod 2FA:",
    submit: "Potwierdź", 
    error: "Błąd podczas włączania 2FA:" 
  },

  //Apllications
  applications:{
    title: 'Aplikacje',
    progress: 'Postęp aplikacji',

    modify: 'Modyfikacja ',
    completed: 'Zakończenie',

    submit: 'Zatwierdź',
    next: 'Dalej',
    saved: 'Zapisany',
    validation: 'Wypełnij wszystkie wymagane pola.',

    personaldetails:
    {
      personal: 'Dane osobowe',
      title:'*TYTUŁ',
      mr: 'Pan',
      mrs: 'Pani', 
      dateofbirth: '*DATA NARODZIN',
      countrybirth: '*KRAJ NARODZIN',
      nationality: '*NARODOWOŚĆ',
      countryresidence: '*KRAJ REZYDENCJI',
      statedis: '*WOJEWÓDZTWO',
      city: '*MIASTO',
      text: '(text)',
      streetname: '*ADRES WE WSKAZANEJ REZYDENCJI – ULICA',
      streetnunmber: '*ADRES WE WSKAZANEJ REZYDENCJI – NUMER DOMU',
      postcode: '*KOD POCZTOWY',
      mobile: '*TELEFON KOMÓRKOWY',
      phone: 'TELEFON',
      passnumber: '*NUMER DOWODU OSOBISTEGO / PASZPORTU',
      passexpiration: '*DATA WAŻNOŚCI DOWODU OSOBISTEGO / PASZPORTU',
      question1: '*Czy jesteś obywatelem USA lub rezydentem podatkowym USA?',
      question2: '*Czy jest Pan/Pani osobą zajmującą eksponowane stanowisko polityczne (PEP), bliskim krewnym lub współpracownikiem PEP?',
      yes: 'TAK',
      no: 'NIE',
      please: '*Podaj szczegóły',
      bytick: '*Zaznaczając następujące informacje, wyrażasz zgodę na poniższe:',
      agree1: 'WYRAŻAM / WYRAŻAMY ZGODĘ NA WARUNKI HANDLOWE TRADEVIEW EUROPE LTD. WRAZ Z ZAŁĄCZNIKAMI:',
      conflicts: 'POLITYKA PRZECIWDZIAŁANIA KONFLIKTOM INTERESÓW',
      and: 'I',
      bets: 'POLITYKA NAJLEPSZEJ REALIZACJI ZLECEŃ.',
      agree2: "AKCEPTUJĘ / AKCEPTUJEMY WARUNKI",
      privacy: 'POLITYKI PRYWATNOŚCI TRADEVIEW EUROPE LTD.',
      agree3: "WYRAŻAM / WYRAŻAMY ZGODĘ NA WYKORZYSTANIE MOICH / NASZYCH DANYCH W CELACH MARKETINGOWYCH PRZEZ TRADEVIEW EUROPE LTD.",
    },

    taxresidency:{
      tax: 'Deklaracja rezydencji podatkowej',
      info: 'Niniejszym potwierdzam, że do celów podatkowych jestem rezydentem następującego kraju/krajów (Wskaż typ i numer podatkowy/PESEL obowiązujący w danym kraju).',
      country: '*KRAJ',
      referencenumber: '*NUMER IDENTYFIKACJI PODATKOWEJ (PESEL / NIP)',
      addcountry: 'Dodaj kolejny kraj',
      removecountry: 'Usuń kraj'
    },

    financialinformation:{
      finalcial: 'Informacja finansowa',
      question1: '*Zatrudnienie',
      option11: 'ZATRUDNIONY ',
      option12: 'SAMO-ZATRUDNIONY',
      option13: 'EMERYT',
      option14: 'BEZROBOTNY',
      option15: 'STUDENT',
      labeloption11: '*NAZWA PRACODAWCY',
      labeloption12: '*BRANŻA',
      labeloption13: '*STANOWISKO',
      accountancy: 'KSIĘGOWOŚĆ',
      adminsecretarial: 'ADMINISTRACJA',
      agriculture: 'ROLNICTWO',
      bankreatil: 'BANKOWOŚĆ DETALICZNA',
      bankInvestment: 'BANCA - INWESTYCJA',
      catering: 'GASTRONOMIA',
      dentist: 'DENTYSTA',
      doctor: 'LEKARZ',
      education: 'EDUKACJA',
      emergencyservice: 'SŁUŻBY RATOWNICZE',
      energyu: 'USŁUGI KOMUNALNE',
      engineering: 'INŻYNIERIA',
      entretaiment: 'ROZRYWKA',
      finalcialadvisor: 'DORADCA FINANSOWY',
      financialservices: 'USŁUGI FINANSOWE',
      armedforces: 'SIŁY ZBROJNE',
      hr: 'KADRY',
      insurance: 'UBEZPIECZENIA',
      it: 'INFORMATYKA',
      legal: 'PRAWO',
      leisure: 'WYPOCZYNEK',
      manufacturing: 'PRODUKCJA',
      marketing: 'MARKETING',
      media: 'MEDIA',
      motortrade: 'MOTORYZACJA',
      property: 'NIERUCHOMOŚCI',
      retail: 'SPRZEDAŻ DETALICZNA',
      sales: 'SPRZEDAŻ',
      socialservices: 'USŁUGI SOCJALNE',
      sports: 'SPORT / REKREACJA',
      stockbroker: 'MAKLER GIEŁDOWY',
      telecomunication: 'TELEKOMUNIKACJA',
      transport: 'TRANSPORT',
      arms: 'HANDEL BRONIĄ',
      carwash: 'MYJNIA SAMOCHODOWA / STACJA BENZYNOWA',
      casino: 'KASYNO',
      construction: 'BUDOWNICTWO',
      heald: 'SŁUŻBA ZDROWIA',
      mining: 'GÓRNICTWO',
      money: 'USŁUGI PIENIĘŻNE / PRZELEWY',
      pharmaceuticals: 'FARMACJA',
      precious: 'METALE SZLACHETNE',
      public: 'ZAMÓWIENIA PUBLICZNE',
      virtual: 'WALUTY WIRTUALNE',
      question2: '*Roczny dochód',
      option21: 'PONIŻEJ 10TYS EUR',
      option22: '11TYS – 30TYS EUR',
      option23: '31TYS – 50TYS EUR',
      option24: '51TYS – 100TYS EUR',
      option25: '101TYS – 200TYS EUR',
      option26: '201TYS – 500TYS EUR',
      option27: 'POWYŻEJ 500TYS EUR',
      question3: '*Łączny majątek (oszczędności, inwestycje, nieruchomość po odliczeniu zobowiązań)',
      option31: 'PONIŻEJ 10TYS EUR',
      option32: '11TYS – 30TYS EUR',
      option33: '31TYS – 50TYS EUR',
      option34: '51TYS – 100TYS EUR',
      option35: '101TYS – 200TYS EUR',
      option36: '201TYS – 500TYS EUR',
      option37: 'POWYŻEJ 500TYS EUR',
      question4: '*Aktywa płynne (gotówka, instrumenty rynku pieniężnego i zbywalne papiery wartościowe)',
      option41: 'PONIŻEJ 10TYS EUR',
      option42: '11TYS – 30TYS EUR',
      option43: '31TYS – 50TYS EUR',
      option44: '51TYS – 100TYS EUR',
      option45: '101TYS – 200TYS EUR',
      option46: '201TYS – 500TYS EUR',
      option47: 'POWYŻEJ 500TYS EUR',
      question5: '*Przewidywany poziom inwestycji / transakcji',
      option51: 'PONIŻEJ 10TYS EUR',
      option52: '11TYS – 30TYS EUR',
      option53: '31TYS – 50TYS EUR',
      option54: '51TYS – 100TYS EUR',
      option55: '101TYS – 200TYS EUR',
      option56: '201TYS – 500TYS EUR',
      option57: 'POWYŻEJ 500TYS EUR',
      questionselect1: '*POCHODZENIE MAJĄTKU',
      soursefounds1: 'Oszczędności z tytułu wynagrodzeń',
      soursefounds2: 'Świadczenia socialne',
      soursefounds3: 'Sprzedaż majątku',
      soursefounds4: 'Inwestycje, dywidendy',
      soursefounds5: 'Dochód z funduszu powierniczego',
      soursefounds6: 'Wynajem',
      soursefounds7: 'Spadek',
      soursefounds8: 'Darowizna',
      soursefounds9: 'Pożyczka',
      soursefounds10: 'Dochód z samozatrudnienia',
      soursefounds11: 'Partner lub inne osoby',
      soursefounds12: 'Wsparcie krewnych',
      soursefounds13: 'Inne',
      questionselect2: '*POCHODZENIE PIENIĘDZY ZDEPONOWANYCH JAKO DEPOZYT?',
      question6: '*PLEASE EXPLAIN YOUR SOURCE OF INCOME',//falta
      question7: '*PLEASE EXPLAIN YOUR SOURCE OF INCOME',//falta
      questionselect3: '*WYBIERZ PLATFORMĘ',
      questionselect4: '*WYBIERZ WALUTĘ:',
      question8: '*Czy jesteś powiązany z bankructwem?',
      yes: 'TAK',
      no: 'NIE',
      question9: '*Z JAKIM RODZAJEM BANKRUCTWA JESTEŚ ZWIĄZANY?',
      question10: '*Przeznaczenie rachunku',
      option101: 'ZABEZPIECZENIE POZYCJI',
      option102: 'SPEKULACJA',
      option103: 'HANDEL WŁASNY',
      option104: 'INWESTYCJE DŁUGOTERMINOWE',
      option105: 'INNE',
    },

    knowledge:{
      know: 'Wiedza finansowa',
      question1: '*Jaki jest poziom wykształcenia, który Pan / Pani uzyskał/a?',
      option11: 'MAM WYKSZTAŁCENIE WYŻSZE (LICENCJAT / INŻYNIER) W ZAKRESIE FINANSÓW / EKONOMII / MATEMATYKI',
      option12: 'MAM WYKSZTAŁCENIE WYŻSZE, ALE NIE JEST ONO ZWIĄZANE Z FINANSAMI / EKONOMIĄ / MATEMATYKĄ',
      option13: 'POSIADAM KWALIFIKACJE ZAWODOWE (TAKIE JAK CISA CFS, CISI) LUB INNE KWALIFIKACJE DOTYCZĄCE INSTRUMENTÓW SPEKULACYJNYCH',
      option14: 'MAM WYKSZTAŁCENIE ŚREDNIE',
      option15: 'NIE MAM FORMALNEGO WYKSZTAŁCENIA',
      question2: '*Czy pracowałeś lub pracujesz w branży usług finansowych?',
      option21: 'PRACUJĘ LUB PRACOWAŁEM JAKO TRADER LUB OSOBA ZWIĄZANY Z HANDLEM INSTRUMENTAMI FINANSOWYMI Z DŹWIGNIĄ FINANSOWĄ W CIĄGU OSTATNICH 5-10 LAT',
      option22: 'PRACUJĘ LUB PRACOWAŁEM JAKO TRADER LUB OSOBA ZWIĄZANY Z HANDLEM OPCJAMI ZWIĄZANYMI Z PAPIERAMI WARTOŚCIOWYMI I / LUB TOWARAMI W CIĄGU OSTATNICH 5-10 LAT',
      option23: 'PRACUJĘ LUB PRACOWAŁEM W USŁUGACH FINANSOWYCH, ALE NIE JAKO TRADER LUB OSOBA ZWIĄZANA Z HANDLEM INSTRUMENTAMI FINANSOWYMI W CIĄGU OSTATNICH 2-10 LAT',
      option24: 'NIE PRACUJĘ, ANI NIE PRACOWAŁEM W USŁUGACH FINANSOWYCH W CIĄGU OSTATNICH 5-10 LAT',
      question3: '*Jak najlepiej wyjaśnić działanie dźwigni finansowej w handlu FX / CFD?',
      option31: 'DŹWIGNIA FINANSOWA POZWALA INWESTOROM NA ZWIELOKROTNIENIE WIELKOŚCI ICH POZYCJI RYNKOWYCH. POWIĘKSZA POTENCJALNĄ STRATĘ LUB ZYSK.',
      option32: 'DŹWIGNIA FINANSOWA POWIĘKSZA POTENCJALNY ZYSK, ALE NIE WPŁYWA NA POTENCJALNĄ STRATĘ',
      option33: 'DŹWIGNIA NIE MA WPŁYWU NA MOJE TRANSAKCJE.',
      question4: 'Które z poniższych elementów są istotne w minimalizowaniu ryzyka podczas handlu kontraktami CFD?',
      option41: 'MONITOROWANIE OTWARTYCH POZYCJI',
      option42: 'WIEDZA NA TEMAT POZIOMU WYKORZYSTANEJ DŹWIGNI FINANSOWEJ',
      option43: 'OBA STWIERDZENIA',
      question5: '*Jaki rodzaj zlecenia zamykającego można zastosować, aby kontrolować wielkość potencjalnej straty?',
      option51: 'STOP LOSS',
      option52: 'TAKE PROFIT',
      option53: 'ŻADNE Z POWYŻSZYCH',
      question6: '*Jakie ryzyko wiąże się z handlem produktami lewarowanymi?',
      option61: 'NIE MA ŻADNEGO RYZYKA',
      option62: 'RYZYKO UTRATY ZAINWESTOWANYCH PIENIĘDZY',
      option63: 'ŻADNE Z POWYŻSZYCH',
    },

    experience:{
      exp: 'Doświadczenie',
      description: 'Proszę wybrać najlepszą opcję, aby opisać swoje doświadczenie w handlu:',
      subtitle1: 'PAPIERY WARTOŚCIOWE NOTOWANE NA GIEŁDZIE:',
      question1: '*Lata doświadczenia:',
      option11: 'BRAK',
      option12: '0 - 1 ROK',
      option13: '2 - 5 LAT',
      option14: '5 - 10 LAT',
      option15: 'PONAD 10 LAT',
      question2a: '*Wolumen obrotu:',
      question2b: ' ',
      option21: 'BRAK',
      option22: 'EUR 0 - EUR 50,000',
      option23: 'EUR 50,001 - EUR 100,000',
      option24: 'EUR 100,001 - EUR 500,000',
      option25: 'EUR 500,001 LUB WIĘCEJ',
      question3: '*Częstotliwość handlu:',
      option31: 'BRAK',
      option32: 'CODZIENNIE',
      option33: 'TYGODNIOWO',
      option34: 'MIESIĘCZNIE',
      option35: 'KWARTALNIE',
      option36: 'KILKA RAZY DO ROKU',
      subtitle2: 'LEWAROWANE INSTRUMENTY FOREX, CFD I METALE SZLACHETNE',
      question4: '*Lata doświadczenia:',
      option41: 'BRAK',
      option42: '0 - 1 ROK',
      option43: '2 - 5 LAT',
      option44: '5 - 10 LAT',
      option45: 'PONAD 10 LAT',
      question5: '*Wolumen obrotu:',
      option51: 'BRAK',
      option52: 'EUR 0 - EUR 50,000',
      option53: 'EUR 50,001 - EUR 100,000',
      option54: 'EUR 100,001 - EUR 500,000',
      option55: 'EUR 500,001 LUB WIĘCEJ',
      question6: '*Częstotliwość handlu:',
      option61: 'BRAK',
      option62: 'CODZIENNIE ',
      option63: 'TYGODNIOWO ',
      option64: 'MIESIĘCZNIE',
      option65: 'KWARTALNIE',
      option66: 'KILKA RAZY DO ROKU'

    },

    verification:{
      idv: 'Weryfikacja tożsamości',
      h1: 'Prawie gotowe!',
      subtitle1: 'Musimy tylko potwierdzić Twoją tożsamość',
      description: 'Aby zatwierdzić Twoje konto inwestycyjne, musimy zweryfikować Twoją tożsamość. Prosimy o załączenie poniższych dokumentów do wniosku o weryfikację. Tradeview może poprosić o dodatkowe informacje lub dokumenty, aby spełnić wymogi przepisów AML i CFT.',
      pleaseprovide: 'Prosimy o dostarczenie tych dokumentów:',
      h2a: 'Urzędowy dokument ze zdjęciem',
      descriptionh2a: 'na przykład paszport (zalecany), dowód tożsamości, pozwolenie na pobyt.',
      h2b: 'Dowód miejsca zamieszkania',
      descriptionh2b: 'na przykład rachunek za media, zaświadczenie o zameldowaniu, wyciąg z konta bankowego.',
      h2c: 'Urzędowy dokument ze zdjęciem',
      descriptionh2c1: 'Prosimy o przesłanie kolorowej kopii paszportu (zalecane), dowodu osobistego lub zezwolenia na pobyt.',
      descriptionh2c2: 'Proszę zwrócić uwagę na to czy:',
      descriptionh2c3: 'Twój dokument tożsamości ze zdjęciem jest wyraźny i widoczny',
      descriptionh2c4: 'Twój dokument tożsamości wydany przez instytucję publiczną',
      descriptionh2c5: 'Twój dokument tożsamości wydany przez instytucję publiczną',
      descriptionh2c6: 'W razie potrzeby dołącz przód i tył swojego dokumentu tożsamości jako osobne pliki',
      select1: '*TYP DOKUMENTU',
      typeofdocs1: 'Paszport (zalecany)',
      typeofdocs2: 'Prawo jazdy',
      typeofdocs3: 'Dowód tożsamości',
      typeofdocs4: 'Pozwolenie na pobyt',
      h2d: 'Prześlij swój dokument:',
      descriptionh2d1: 'Wybierz przednią stronę dokumentu',
      front: 'PRZÓD',
      descriptionh2d2: 'Wybierz TYŁ swojego dokumentu',
      back: 'Powrót',
      description1: 'Każdy plik powinien być w formacie JPG, PNG lub JPEG i mieć rozmiar poniżej 2MB',
      goback: 'Powrót',
      done: 'Zatwierdź',
      h2e: 'dokument poświadczający adres zamieszkania',
      descriptionh2e1: 'na przykład rachunek za media, zaświadczenie o zameldowaniu, wyciąg z konta bankowego.',
      descriptionh2e2: 'Wszystkie dowody zamieszkania muszą być aktualne i wydane maksymalnie 90 dni temu.',
      descriptionh2e3: 'Należy pokazać cały dokument, z wyraźnym imieniem i nazwiskiem i pełnym adresem. ',
      descriptionh2e4: 'Pokaż dokonane świadczenie usług, takie jak należność lub dokonana płatność.',
      select2: '*TYP DOKUMENTU',
      proofType1: "Rachunek za usługi komunalne",
      proofType2: "Zaświadczenie o miejscu zamieszkania",
      proofType3: "Wyciąg bankowy",
      h2f: 'Prześlij swój dokument:',
      descriptionh2f1: 'Wybierz pliki',
      descriptionh2f2: 'Każdy plik powinien być w formacie JPG, PNG lub JPEG i mieć rozmiar poniżej 2MB',
      description2: '*WYRAŻAM ZGODĘ NA ELEKTRONICZNĄ WERYFIKACJĘ TOŻSAMOŚCI PRZEZ TRADEVIEW POPRZEZ DOSTARCZENIE MOICH DANYCH OSOBOWYCH, W TYM DOKUMENTÓW TOŻSAMOŚCI, DO DOSTAWCY USŁUG WERYFIKACJI TOŻSAMOŚCI W CELU UZYSKANIA OCENY, CZY SĄ ONE ZGODNE Z INFORMACJAMI POSIADANYMI PRZEZ NIEGO LUB PRZEZ INNE ŹRÓDŁA WERYFIKACJI TOŻSAMOŚCI.',
      uploadDocument: "Prosimy o przesłanie dokumentu.",
      documentCorresponding: "Prześlij dokument odpowiadający rządowemu dokumentowi tożsamości ze zdjęciem i dokumentowi potwierdzającemu miejsce zamieszkania.",
      cantAttach: "Nie można dołączyć tego obrazu, spróbuj użyć innego"
    },

    acknowledgement:{
      ack: 'Podsumowanie',
      description: '*Kliknij na poniższe odnośniki do dokumentów i potwierdź, że rozumiesz i zgadzasz się z nimi. Po zatwierdzeniu wniosków i umów, używając myszki podpisz się swoim imieniem i nazwiskiem.',
      check1: 'UMOWA Z KLIENTEM',
      check2: 'UMOWA O HANDLU ELEKTRONICZNYM',
      check3: 'OSTRZEŻENIE O RYZYKU',
      check4: 'POLITYKA PRYWATNOŚCI',
      check5: 'ZGODA NA ŚWIADCZENIE USŁUG DROGĄ ELEKTRONICZNĄ',
      check6: 'UMOWA PROWIZYJNA',
      check7: 'REGULAMIN ŚWIADCZENIA USŁUG',
      check8: 'POLITYKA NAJLEPSZEJ REALIZACJI ZLECEŃ',
      check9: 'POLITYKA PRZECIWDZIAŁANIA KONFLIKTOM INTERESÓW',
      check10: 'Dokumenty zawierające kluczowe informacje (KID):',
      forex: 'FOREX',
      crypto: 'KRYPTOWALUTY',
      commodities: 'SUROWCE',
      index: 'INDEKSY',
      description2: 'Podpisując poniżej, potwierdzam i zgadzam się na warunki zawarte w wyżej wymienionej: Umowie Klienta, Umowie o Handlu Elektronicznym, Ostrzeżeniu o Ryzyku, Polityce Prywatności, Zgodzie na Świadczenie Usług Drogą Elektroniczną, Umowie Prowizyjnej, Regulaminie Świadczenia Usług, Polityce Najlepszej Realizacji Zleceń oraz Polityce Przeciwdziałania Konfliktom Interesów. Ponadto potwierdzam i zgadzam się, pod groźbą kary za składanie fałszywych oświadczeń, że wszystkie informacje podane w aplikacji są prawdziwe i dokładne, a wszystkie depozyty na moim koncie, niezależnie od źródła pochodzenia, rodzaju waluty lub metody wpłaty, nie są wpływami z działalności przestępczej lub nielegalnej i pochodzą z legalnych źródeł.',
      authorized: 'PODPIS UPRAWNIONEGO.',
      signature: ' ',
      confirm: 'Potwierdzenie podpisu',
      clean: 'Czytelny podpis',
      hasNot: 'Nie podpisał'
    },

    riskdisclosure:{
      add: 'Dodatkowe informacje o ryzyku',
      description: "Informacje podane we wniosku o założenie konta wskazują, że nie spełniasz wytycznych Tradeview Europe Ltd. dotyczących otwierania rachunku handlowego z jednego z następujących powodów:",
      list1: 'Nie jesteś pomiędzy 21 i 63 rokiem życia',
      list2: 'Twój roczny dochód nie przekracza 10 000 EUR',
      list3: 'Złożyłeś wniosek o upadłość w ciągu ostatniego roku',
      list4: 'Nie jesteś zatrudniony',
      description1: 'Podczas gdy Tradeview Europe Ltd. jest przygotowany do otwarcia Twojego rachunku, należy doradzić Ci ponowne rozważenie tej inwestycji. W oparciu o Twoje dane osobowe i/lub doświadczenie inwestycyjne, handel na rynku forex może być dla Ciebie zbyt ryzykowną formą inwestycji. Strata w handlu na rynku forex może być znaczna. Dlatego powinieneś dokładnie rozważyć, czy taki handel jest dla Ciebie odpowiedni w świetle Twojej sytuacji i zasobów finansowych. Wysoki poziom dźwigni finansowej, który można uzyskać w handlu forex ze względu na małe wymagania dotyczące depozytu zabezpieczającego, może działać zarówno na Twoją korzyść, jak i niekorzyść. Stosowanie dźwigni finansowej może prowadzić zarówno do dużych zysków, jak i strat.',
      description2: '*ROZUMIEM, ŻE NIE SPEŁNIAM MINIMALNYCH WYTYCZNYCH DOTYCZĄCYCH OTWIERANIA KONTA OKREŚLONYCH PRZEZ TRADEVIEW EUROPE LTD. W POWYŻSZYCH USTĘPACH. JEDNAKŻE ROZWAŻYŁEM RYZYKO FINANSOWE ZWIĄZANE Z HANDLEM FOREX W ODNIESIENIU DO MOJEJ SYTUACJI OSOBISTEJ I CHCĘ KONTYNUOWAĆ OTWIERANIE KONTA.',
      description3: '*POTWIERDZAM, ŻE PRZECZYTAŁEM POWYŻSZE I W PEŁNI ROZUMIEM ZAWARTE W NINIEJSZYM INFORMACJI.',
    },

    confirmation:{
      subtitle1: 'Od złożenia wniosku dzieli Cię jedno kliknięcie',
      description: 'Jeśli chcesz przejrzeć ponownie lub edytować wcześniej wprowadzone informacje, możesz przejść do odnośnika „Modyfikacja” w każdej sekcji. Jeśli jesteś gotowy do przesłania wniosku teraz, kliknij przycisk zatwierdzający.',
      modify: '"Zmień"',
      description2: 'w każdej sekcji. Jeśli jesteś gotowy do przesłania wniosku teraz, kliknij przycisk zatwierdzający.',
      subapplication: 'Prześlij aplikację',
      congratulation: 'Gratulacje',
      descriptionmodal: 'Dziękujemy za zgłoszenie. Przesłane przez Ciebie dokumenty są w trakcie sprawdzania i wkrótce zostaną zweryfikowane. Nasz zespół operacyjny skontaktuje się z Tobą, jeśli będziemy potrzebować dodatkowej dokumentacji.',
      close: 'Zamknij',
      your: 'Prośba o Twoje',
      account: 'konto indywidualne',
      description3: 'jest przetwarzana. Finalny status zostanie przesłana na Twój e-mail.'
    }
  }

}
