/* eslint-disable */

import Vue from 'vue';
import { createApp } from 'vue'
import router from './router';
import store from './store/index';
import { fakeBackend } from './_helpers';
import { initFacebookSdk, jwtInterceptor } from './_helpers';

// enable interceptors for http requests
jwtInterceptor();
initFacebookSdk().then(createApp);
fakeBackend();

import awsconfig from './aws-exports';
import { Auth, API, Logger } from "aws-amplify";

const auts = { "Auth": {"cookieStorage": {"domain": window.location.hostname,"path": "/","expires": 365,"sameSite": "strict","secure": true}}}

var output = {};
output = jsonConcat(output, awsconfig);
output = jsonConcat(output, auts);

Auth.configure(output);

Logger.LOG_LEVEL = "ERROR";

import App from './App.vue'
import { i18n } from '@/plugins/i18n'
import mitt from 'mitt'
import VueNextSelect from 'vue-next-select'


const app = createApp(App)
const emitter = mitt()
app.use(router)
app.use(store)
app.use(i18n)
app.component('vue-select', VueNextSelect)
app.config.devtools = false
app.config.errorHandler = () => null;
app.config.warnHandler = () => null;
app.config.globalProperties.emitter = emitter
app.mount('#app')

function jsonConcat(o1, o2) {
    for (var key in o2) {
     o1[key] = o2[key];
    }
    return o1;
   }