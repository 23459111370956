import en from './languages/english'
import es from './languages/spanish'
import it from './languages/italian'
import pl from './languages/polish'
export default {
    en,
    es,
    it,
    pl
}
