export default {
  //login home page
  loginHeader: {
    contactUs: 'Contactenos'
  },
  loginPage: {
      instructionstitle: 'Gabinete del cliente',
      instructionsLine1: 'Inicie sesión con su correo electrónico y la contraseña que creó para su cuenta de Tradeview.',
      instructionsLine2: 'En el Gabinete de Clientes podrá:',
      featureList1: 'Consultar el resumen de su cuenta',
      featureList2: 'Acceder al historial de su cuenta',
      featureList3: 'Financiar su cuenta',
      featureList4: 'Transferir fondos entre cuentas',
      featureList5: 'Solicitar un retiro de su cuenta',
      pFooter: 'Los servicios y productos brindados por Tradeview Europa Ltd. no se ofrecen dentro de  Estados Unidos (EEUU) ni a las personas de este país según lo que define la ley de EEUU. La información de esta página web no está dirigida a los residentes de ningún país donde el trading de FX y/o CFD esté restringido o prohibido por la leyes o regulaciones locales. Los CFDs son instrumentos financieros complejos que conllevan un riesgo de poder perder su dinero rápidamente debido al uso del apalancamiento, el 68.3 % de las cuentas de los inversores minoristas pierden su dinero al operar con CFDs en Tradeview Europe. Debería considerar si comprende cómo funcionan los CFDs y si podria permitirse asumir el alto riesgo de poder perder su dinero.',
      linkFooter: '',
      adressOficce: 'Oficina de Tradeview en Europa:',
      termsAndConditions: 'Términos y condiciones',
      privacyPolicy: 'Política de privacidad',
      amlPolicy: 'Política AML',
      refundPolicy: 'Politica de reembolso',
      riskDisclosure: 'Divulgación de riesgos',
      complaintsProcedure: 'Procedimiento de Quejas',
      cookiePolicy: 'Política de cookies',
      changeSucces: 'Change was successful!',
      checkEmail: 'Check your email for instructions',
      code: 'code',
      newPassword: 'new password',
      repeatPassword: 'repeat password',
      changePassword: 'change password'
  },
  loginBox: {
    logInGoogle: 'Iniciar sesión con Google',
    logInFacebook: 'Iniciar sesión con Facebook',
    or: 'o',
    email: 'Correo electrónico',
    password: 'Contraseña',
    submit: 'Acceder',
    forgot: '¿Olvido su contraseña?',
    doNotAccount: '¿Aún no tiene una cuenta?',
    signUp: 'Inscribirse',
    tryFreeDemo: 'Probar Demo Gratuito',
    showDialog1: 'Introduzca una dirección de correo electrónico/contraseña válida',
    changePass: '¡El cambio fue exitoso!'
  },
  consoleHeader: {
      account: 'Cuenta #',
      balance: 'Balance',
      equity: 'Equidad',
      free: 'Margen libre',
      margin: 'Nivelador de margen',
      status: 'Estado',
      hello: 'Hola',
      logout: 'Cerrar sesión',
      confirm: 'Confirmar',
      outModal: '¿Está seguro de que quiere cerrar sesión?',
      yes: 'Si',
      not: 'No'
  },

  // Platforms page
  platforms:{
      title: 'Descargar Plataformas',
      accountLive: 'Cuenta real del servidor:',
      accountDemo: 'Cuenta de demostración del servidor:'
  },

  history:{
    title: 'Historia',
    accountActivity: 'Actividad de la cuenta',
    tradingHistory: 'Historial comercial'
  },

  // Withdraw page
  withdraw:{
      title: 'Retiros',
      platform: 'Plataforma',
      sourceAccount: 'Cuenta de origen*',
      method: 'Metodo*',
      currency: 'Moneda*',
      start: 'Retirar ahora',
      beneficiary: '*Nombre del Beneficiario',
      details: 'Detalles del Banco Beneficiario',
      name_benef: "*Nombre del Banco Beneficiario",
      number: '*Número de Cuenta (IBAN si aplica)',
      code:'*Swift/Código del Banco',
      reference: 'Referencia',
      subTitle: 'Detalles del banco intermediario (si es necesario)',
      name:'Nombre del Banco',
      bank: 'Dirección del Banco',
      swift: ' SWIFT Bancario',
      branch: 'Nombre de la Sucursal',
      province: 'Provincia',
      city:'Ciudad',
      dialogShow:  '¡Error! esta plataforma no tiene cuentas activas no puedes continuar con el proceso, prueba con otra plataforma.',
      errorOne: '¡Error! Encontramos campos vacíos',
      errorTwo: '¡Error! La cantidad excede el valor de su cuenta',
      errorThree: '¡Error! El correo electrónico no es válido',
      errorFour: '¡Error! Para tarjeta de crédito solo carácter numérico',
      dialogShowTwo: "¡Solicitud enviada! por favor revise su correo electrónico",
      dialogShowThree:  "Póngase en contacto con el Soporte de Tradeview",
      emailAddress: "Correo Electrónico de la Cuenta (Transferencia Bancaria)",
      creditcard:"Tarjeta de Crédito (Últimos 4 Dígitos)",
      netellerEmail: "Neteller  Correo Electrónico",
      netellerNumber: "Neteller Número de Cuenta",
      skrillEmail: "Skrill  Correo Electrónico",
      skrillNumber: "Skrill Número de Cuenta",
      upholdEmail: "Uphold  Correo Electrónico",
      upholdNumber: "Uphold Número de Cuenta",
  },
  accountActivity:{
      title: 'Actividad de la cuenta',
      from: '*De',
      to:'*Para',
      type:'Tipo',
      platform:'Plataforma',
      placeholderAccount: 'Cuenta de operaciones',
      account: 'Cuenta',
      get: 'Obtener',
      row:'Filas por página',
      download:'Descargar Informe',
      back:'Volver',
      confirm: 'Confirmar',
      close:'Cerrar',
      error: '¡Error! No puede haber campos vacíos',
      errorTwo: '¡Error! debe seleccionar el tipo de campo.',
      history:'La cuenta no tiene historial aun',
      activityid: 'Identificación de Actividad',
      platfomr: 'Plataforma',
      date: 'Fecha/Hora',
      activity: 'Actividad',
      profit: 'Ganancia/Pérdida',
  },


  //tradingHistory
  tradingHistory:{
      title: 'Historial comercial',
      from: '*De',
      to:'*Para',
      type:'Tipo',
      platform:'Plataforma',
      placeholderAccount: 'Cuenta de operaciones',
      account: '*Cuenta',
      get: 'Obtener',
      row:'Filas por página',
      download:'Descargar Informe',
      back:'Volver',
      confirm: 'Confirmar',
      close:'Cerrar',
      error: '¡Error! No puede haber campos vacíos',
      errorTwo: '¡Error! debe seleccionar el tipo de campo.',
      deal:'Acuerdo',
      entryTime: 'Tiempo de Entrada ',
      typr: 'Tipo',
      simbol: 'Símbolo',
      volume:'Volumen',
      entryPrice: 'Precio de Entrada',
      sl: 'S/L',
      tp: 'T/P',
      exitTime: 'Tiempo de Salida',
      exitPrice: 'Precio de salida',
      commision:'Comisión',
      swap: 'Intercambio',
      profit: 'Ganancia/Pérdida',
      live: 'En vivo',
      demo: 'Demo'
  },

  newLiveAccount:{
      title:'Crear nueva cuenta real',
      platform:'*Seleccione una plataforma',
      currency:'*Moneda',
      account: 'Crear cuenta',
      back:'Volver',
      congratulation:'Felicitaciones',
      text:'Su solicitud de cuenta real está bajo revisión y será verificada en breve. Nuestro equipo de operaciones se pondrá en contacto con usted en caso de que necesitemos más documentación.',
      error:'¡Error! No puede haber campos vacíos',
      close:'Cerrar',
},

  //Transfer page
  transfer:{
      title: 'Transferencia entre sus cuentas comerciales',
      subTitle: 'Para transferir fondos de una cuenta de Tradeview a otra, complete el siguiente formulario.',
      platform: 'Plataforma',
      sourceAcount: 'Cuenta de origen',
      targetAccount: 'Cuenta destino',
      sendingAmount: 'Cantidad a enviar ',
      condition: 'Al marcar la casilla de verificación, entiendo que he solicitado que se transfieran fondos de una cuenta de Tradeview Europa Ltd. a otra de mis propias cuentas de Tradeview Europa Ltd.',
      start: 'Transferir',
      showDialogOne: 'Póngase en contacto con el soporte de Tradeview',
      showDialogTwo:'Póngase en contacto con el soporte de Tradeview',
      submited: '¡Solicitud enviada!, por favor revise su correo electrónico',
      error:'¡Error! No puede haber campos vacíos',
      errorTwo:'¡Error! Los números de cuenta deben ser diferentes',
      errorThree:'¡Error! El monto no es válido',
      errorFour:'¡Error! El valor excede el saldo de su cuenta'
  },

  //Accounts
  accounts:{
    warning:'Se está procesando su solicitud de cuenta de demostración. El estado final será notificado en su correo electrónico.',
    live:'Cuenta de Operaciones en Vivo',
    demo:'Cuenta Demo',
    createLive:'+ Crear una nueva Cuenta de Operaciones en Vivo',
    createDemo:'+ Crear una nueva Cuenta de Operaciones en Vivo',
    noAccountLive: 'No tiene cuentas reales activas',
    noAccountDemo: 'No tiene cuentas de demostración activas',
    dialogShowOne: 'Actualmente no tiene cuentas demo de Metatrader 4.',
    dialogShowTwo: 'You currently have no Metatrader 5 demo accounts.',
    dialogShowThree: 'Actualmente no tiene cuentas reales de Metatrader 4.',
    dialogShowFour: 'Actualmente no tiene cuentas reales de Metatrader 5.',
    account: 'CUENTA #',
    balance: 'SALDO',
    equity: 'EQUIDAD',
    margin:'NIVEL DE MARGEN',
    free: 'MARGEN LIBRE',
    status: 'ESTADO',
    leverage:'APALANCAMIENTO',
    currency: 'MONEDA'
},

 //CreateDemoAccounts
 demoAccounts:{
    title:'Crear nueva cuenta de demostración',
    platform:'* Seleccione una plataforma',
    type:'* Seleccione un tipo de cuenta',
    amount:'* Cantidad del depósito',
    currency: '* Moneda',
    account: 'Crear una cuenta',
    back:'Volver',
    created:'Su cuenta Demo ha sido creada',
    text:'Guarde sus credenciales antes de hacer clic en cualquier opción o cerrar esta ventana, estas NO serán enviadas a su correo electrónico.',
    login: 'Acceso',
    pass:'Contraseña',
    currencyTwo:'Moneda',
    server:'Servidor',
    data:'TradeviewEurope-Demo Europa',
    download:"Ahora está listo para descargar su plataforma de negociación:",
    close:'Cerrar',
    error:'¡Error! No puede haber campos vacíos',
    professional: 'Profesional',
    retail: 'venta minorista',
    stocks: 'Stocks'
},


//credit card
credit:{
    title: 'Tarjetas de crédito',
    subTitle: 'Esta sección aún no está disponible',
    subTitleAdd: 'Añadir fondos',
    platform: 'Plataforma',
    trading: 'Cuenta de operaciones',
    amount:'* Cantidad (Min. 100 USD - max. 10.000 USD)',
    currency: '* Moneda',
    details: 'Detalles de la tarjeta de crédito',
    cardName: '* Titular de tarjeta',
    cardNumber: '* Número de tarjeta',
    expiration: 'Fecha de caducidad',
    fund: 'Fondo',
    disclaimer: 'Los pagos solo se aceptan desde una cuenta/tarjeta registrada a su nombre. Tenga en cuenta que podemos solicitar documentos y/o información adicional con fines de verificación..',
    back: 'Volver',
    textOne: '¿Estás seguro de que quieres depositar?',
    textTwo: 'con tu tarjeta de credito?',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    deposit: 'Deposito',
    ok: 'Ok',
    depositUrl: 'Redirigir a Zotapay',
    errorOne:'Error! Monto (Min. 100 USD - max. 10,000 USD)',
    errorTwo:'Error! No puede haber campos vacíos',
    errorThree:'Error! No es un código de seguridad válido',
    errorFour:'Error! No es una fecha de caducidad válida',
    errorFive:'Error! No es un número de tarjeta válido',
},

//Banck Transfer add found Skrill
addFounds:{
    title:'Añadir fondos',
    platform:'Plataforma',
    account:'Cuenta Comercial ',
    currency:'Moneda',
    amount:'Monto (Min. 100 USD - max. 10,000 USD)',
    email: 'Correo Electrónico',
    disclaimer:'Solo se aceptan pagos desde una cuenta/tarjeta registrada a su nombre. Tenga en cuenta que podemos solicitar documentos y/o información adicional con fines de verificación.',
    fund:'Financiar',
    back:'Volver',
    redi:'Redirigir',
    redirection:'Redirigir a',
    checkmark:'Al marcar la casilla, acepta todas las políticas de Tradeview Europe Ltd.',
    submit:'Entregar',
    contactSupport: 'Error. Please Contact Tradeview Support'
},


//Add Bank Funds
addBanckFounds:{
  title:'Añadir fondos',
  description:'Por favor use esta información para transferencias: ',
  back:'Volver',
  headers1: 'Para depósitos en USD',
  rows1Headers1: 'Banco Beneficiario:',
  subrows1Headers1:'BMO Harris Bank, N.A',
  rows2Headers1:  'Swift Beneficiario',
  subrows2Headers1: 'HATRUS44',
  rows3Headers1:  'ABA Número:',
  subrows3Headers1: '071000288',
  rows4Headers1:  'Referencia:',
  subrows4Headers1: '(Su Nombre + Número de Cuenta Tradeview)',
  rows5Headers1:  'Nombre de la cuenta:',
  subrows5Headers1: ' Tradeview Europa Limitedo',
  rows6Headers1:  'Número de cuenta:',
  subrows6Headers1: '4243275',
  rows7Headers1:  'Dirección del banco:',
  subrows7Headers1: '111 West Monroe St, Chicago, IL 60690',
  rows8Headers1:  'Dirección del beneficiario:',
  subrows8Headers1: '114/3 The Strand, Gzira, GZR 1027, Malta',
  headers2: 'Para depósitos en EUR',
  rows1Header2: 'Banco Beneficiario:',
  subrows1Header2: 'BMO Harris Bank, N.A',
  rows2Header2: 'Swift Beneficiario',
  subrows2Header2: 'HATRUS44GTM',
  rows3Header2: 'Banco intermediario',
  subrows3Header2: 'Deutsche Bank - Frankfurt',
  rows4Header2: 'Swift intermediario',
  subrows4Header2: 'DEUTDEFF',
  rows5Header2: 'Referencia:',
  subrows5Header2: '(Su Nombre + Número de Cuenta Tradeview)',
  rows6Header2: 'Nombre de la cuenta',
  subrows6Header2: ' Tradeview Europa Limitedo',
  rows7Header2: 'Número de cuenta',
  subrows7Header2: '300894250262',
  rows8Header2: 'Dirección del banco',
  subrows8Header2: '111 West Monroe St, Chicago, IL 60603',
  rows9Header2: 'Dirección del beneficiario',
  subrows9Header2: '114/3 The Strand, Gzira, GZR 1027, Malta',
  headersSecond:'Para depósitos en GBP, EUR, USD, CHF y JPY',
  rows1HeadersSecond: 'Banco beneficiario:',
  subrows1HeadersSecond: 'La nube de moneda limitada',
  rows2HeadersSecond: 'Beneficiario Swift:',
  subrows2HeadersSecond: 'TCCLGB3L',
  rows3HeadersSecond: 'IBAN No:',
  subrows3HeadersSecond: 'GB62TCCL04140476597833',
  rows4HeadersSecond: 'Referencia:',
  subrows4HeadersSecond: '210408-56275 + su nombre y número de cuenta Tradeview',
  rows5HeadersSecond: 'Nombre de la cuenta:',
  subrows5HeadersSecond: 'Tradeview Europe Limited',
  rows6HeadersSecond: 'Número de cuenta:',
  subrows6HeadersSecond: '4243275',
  rows7HeadersSecond: 'Dirección del banco:',
  subrows7HeadersSecond: '12 Steward Street, The Steward Building, London, E1 6FQ',
  rows8HeadersSecond: 'Dirección del beneficiario:',
  subrows8HeadersSecond: '114/3 The Strand, Gzira, GZR 1027, Malta',
  headersSecond2: 'Para transferencias EUR, CHF, DKK, GBP, HUF, NOK, PLN, USD',
  rows1HeadersSecond2: 'Titular de la Cuenta:',
  subrows1HeadersSecond2: 'Tradeview Europe Limited',
  rows2HeadersSecond2: 'Moneda:',
  subrows2HeadersSecond2: 'EUR, CHF, DKK, GBP, HUF, NOK, PLN, USD',
  rows3HeadersSecond2: 'IBAN:',
  subrows3HeadersSecond2: 'DK5789000051025190',
  rows4HeadersSecond2: 'BIC:',
  subrows4HeadersSecond2: 'SXPYDKKK',
  rows5HeadersSecond2: 'Referencia:',
  subrows5HeadersSecond2: '(Su Nombre + Número de Cuenta Tradeview)',
  rows6HeadersSecond2: 'Nombre del banco:',
  subrows6HeadersSecond2: 'El servidor esta limitado',
  rows7HeadersSecond2: 'Dirección bancaria:',
  subrows7HeadersSecond2: 'AMERIKA PLADS, 38 COPENHAGEN 2100 Denmark',
  rows8HeadersSecond2: 'Dirección del beneficiario:',
  subrows8HeadersSecond2: '114/3 The Strand, Gzira, GZR 1027, Malta',
  headers3: 'Para Depósitos en GBP',
  rows1Headers3: 'Banco Beneficiario:',
  subrows1Headers3:'BMO Harris Bank, N.A.',
  rows2Headers3:  'Código Swift del Beneficiario:',
  subrows2Headers3: 'HATRUS44GTM',
  rows3Headers3:  'Nombre de la Cuenta:',
  subrows3Headers3: 'Tradeview Europe Limited',
  rows4Headers3:  'Número de Cuenta:',
  subrows4Headers3: '777554250262',
  rows5Headers3:  'Banco Intermediario:',
  subrows5Headers3: 'Royal Bank of Scotland- London',
  rows6Headers3:  'Código Swift del Banco Intermediario:',
  subrows6Headers3: 'NWBKGB2L'
},
 //Choose bank
 chooseBank:{
  title:'Elija Banco',
  method:'MODALIDAD DE FINANCIACIÓN',
  processing:'TIEMPO DE PROCESAMIENTO',
  fee:'TARIFA',
  back:'Volver',
  instruction:"Las pérdidas pueden exceder la cantidad inicial invertida. NO se aceptarán pagos de terceros. Todos los depósitos DEBEN ser del propietario original de la cuenta. La denominación de la plataforma de negociación está en USD. Tradeview Europe Ltd. se reserva el derecho de reservar/reembolsar cualquier depósito realizado mediante tarjeta de crédito en lugar de un retiro."
},

//Choose Credit Card
ChooseCreditCard:{
  title:'Elija tarjeta de crédito',
  method:'MODALIDAD DE FINANCIACIÓN',
  processing:'TIEMPO DE PROCESAMIENTO',
  fee:'TARIFA',
  back:'Volver',
  messageZotapay: 'Zotapay no está disponible temporalmente',
  instructionOne:"Tradeview Europe Ltd. siempre busca mejorar la experiencia de nuestros clientes. Por este motivo, ponemos a disposición de nuestros clientes métodos de financiación rápidos y eficaces. si es necesario por favor",
  contact:'contacto',
  instructionTwo:'nuestro equipo de soporte que con gusto ayudará a acelerar su solicitud.',
  instructionThree:"NO se aceptarán pagos de terceros. Todos los depósitos DEBEN ser del propietario original de la cuenta. La denominación de la plataforma de negociación es en euros o en USD. Tradeview Europe Ltd. se reserva el derecho de reservar/reembolsar cualquier depósito realizado mediante tarjeta de crédito en lugar de un retiro."
},

//Choose alternative methods
 ChooseAlternativeMethods:{
  title:'Elige métodos alternativos',
  method:'MODALIDAD DE FINANCIACIÓN',
  processing:'TIEMPO DE PROCESAMIENTO',
  optionProcessing: 'Instantánea 24/7',
  fee:'TARIFA',
  back:'Volver',
  instructionOne:"Las pérdidas pueden exceder la cantidad inicial invertida. NO se aceptarán pagos de terceros. Todos los depósitos DEBEN ser del propietario original de la cuenta. La denominación de la plataforma de negociación está en USD. Tradeview Europe Ltd. se reserva el derecho de reservar/reembolsar cualquier depósito realizado mediante tarjeta de crédito en lugar de un retiro."
},


//deposit
deposit:{
    title: 'Elegir Opción de Depósito',
    vendor: 'MODALIDAD DE FINANCIACIÓN',
    processingCards: 'TIEMPO DE PROCESAMIENTO',
    fee: 'TARIFA',
    methodOne: 'Tarjetas de crédito',
    methodTwo: 'Métodos Alternativos',
    methodThree: 'Transferencia Bancaria',
    textOne:"Las pérdidas pueden exceder la cantidad inicial invertida. NO se aceptarán pagos de terceros. Todos los depósitos DEBEN ser del propietario original de la cuenta. La denominación de la plataforma de negociación está en USD. Tradeview Europe Ltd. se reserva el derecho de reservar/reembolsar cualquier depósito realizado mediante tarjeta de crédito en lugar de un retiro.",
    textTwo:'',
    textThree:'',
    textFour: "",
},

//Email
email:{
    title: 'Configuración del correo electrónico',
    subTitle: 'Gestione sus suscripciones aquí. Seleccione los temas de los que le gustaría recibir boletines: ',
    company: 'Noticias Corporativas',
    selectOne: 'Noticias comerciales (',
    selectTwo: "Surf's Up!",
    selectThree: 'Boletín informativo)',
    update:'Actualizar',
    instructions: 'Si ya no desea recibir correos electrónicos sobre ninguno de los temas anteriores, simplemente anule o quite la marca. Recuerde hacer clic en actualizar.',
    confirm: 'confirmar',
    close: 'cerrar',
    resqSubmited: '¡Solicitud enviada!'
},

//password
password:{
    title:'Cambiar Contraseña',
    subTitle: 'Para cambiar su contraseña, solicite un código único en su cuenta de correo electrónico.',
    request:'Enviar Código',
    submit:'Entregar',
    code:'Código',
    current:'Contraseña actual',
    newPassword: 'Nueva contraseña',
    repeat:'Repita la contraseña',
    save: 'Guardar cambios',
    confirm: 'Confirmar',
    close:'Cerrar',
    dialogMessage: 'Se ha enviado un código único a su correo electrónico registrado.',
    dialogMessageOne:'Por favor digite la contraseña actual',
    dialogMessageTwo:'Por favor, crea una nueva contraseña',
    dialogMessageThree:'Por favor, confirme la nueva contraseña.',
    dialogMessageFour:"Las contraseñas nuevas/confirmadas no coinciden",
    success: "Exitoso!"
},

 //Contact
  contact:{
      title: 'Solicitar una llamada',
      firstname: 'NOMBRE',
      lastname: 'APELLIDO',
      phone: 'TELÉFONO',
      email: 'CORREO ELECTRÓNICO ',
      lenguage: 'LENGUAJE PARA SOPORTE',
      call: 'HORARIO DE PREFERENCIA PARA RECIBIR LA LLAMADA',
      check: ' Llamar lo más pronto posible',
      contactme:'Contactenme',
      error: '¡Error! Encontramos campos vacíos',
      close: 'Cerrar',
      resqSubmited: '¡Solicitud enviada! Verifique su correo electrónico'
  },

  menu:{
    applications: 'Aplicaciones',
    account: 'Cuentas',
    platforms: 'Plataformas',
    history: 'Historial',
    deposit: 'Depósito',
    transfer: 'Transferir',
    withdraw: 'Retirar',
    settings: 'Configuración',
    passwordsettings: 'Configuración de contraseña',
    emailsettings: 'Configuración de correo electrónico',
    authenticationsettings: 'Configuración de autenticación',
    contactUs: 'Contáctenos'
  },

  //register
  register:{
    title: 'Registro de cuenta',
    create: 'Cree su Acceso',
    createdes: 'Ingrese su correo electrónico y elija una contraseña para comenzar',
    complete: 'Practique',
    completedes: 'Opere con moneda virtual en una cuenta simulada',
    confirm: 'Conozca nuestras Herramientas y Servicios',
    confirmdes: 'Familiarícese con las plataformas de software.',
    fund: 'Fondo y comercio',
    funddes: 'Una vez aprobado, deposite fondos en su cuenta utilizando nuestros métodos de financiación flexibles y de bajo costo y comience a operar',
    goBack: 'Volver',
    getYourLive: "¡Obtén tu nueva Cuenta en Vivo Ahora!",
    getYourDemo: "¡Obtenga su Cuenta Demo Ahora!",
    getYour: '¡Obtenga su cuenta de demostración gratuita ahora!',
    instantly: 'Registrarse al instante:',
    registerGoogle: 'Registrarse con Google',
    registerFacebook: 'Registrarse con Facebook',
    or: 'O',
    email: '*CORREO ELECTRÓNICO',
    secure: '*CONTRASEÑA SEGURA',
    length: '(Longitud mayor o igual a 6 caracteres, incluidos números y caracteres especiales)',
    confirmPassword: '*CONFIRMAR CONTRASEÑA',
    disclaimer: 'DESCARGO DE RESPONSABILIDAD: ',
    disclaimerdes1: ' Antes de abrir una cuenta, es nuestra obligación en ',
    disclaimerdes2: " asegurarnos que usted o la persona o personas designadas para operar en su nombre tengan el conocimiento y la experiencia necesarios en CFD e instrumentos financieros relacionados. También es nuestro requisito cumplir con las obligaciones ALD y CFT. En este sentido, se le solicita amablemente que proporcione información precisa, completa y fáctica según lo solicitado en línea con esta solicitud y para que podamos cumplir con las obligaciones anteriores. Sobre la base de esta información, podemos determinar correctamente si los servicios de inversión en relación con el/los instrumento/s financiero/s son adecuados para usted. Todas las secciones deben completarse. Para obtener más detalles, consulte nuestro",
    clientAgree: 'Acuerdo del cliente.',
    byTicking: '*AL MARCAR ESTO USTED ACEPTA LO ANTERIOR',
    wish: '*DESEO SER EL PRIMERO EN ENTERARTE SOBRE ALERTAS DE CONFIGURACIÓN DE COMERCIO, PRODUCTOS, BOLETINES Y OFERTAS ESPECIALES',
    policity: '(POLÍTICA DE PRIVACIDAD)',
    getstarted: 'Comenzar',
    messageFooter1: 'Los servicios y productos ofrecidos por ',
    messageFooter2: ' no se ofrecen dentro de los Estados Unidos (EE. UU.) y no se ofrecen a personas de los EE. UU., tal como se define en la legislación de los EE. UU. La información en este sitio web no está dirigida a los residentes de ningún país donde el comercio de FX y/o CFD esté restringido o prohibido por las leyes o regulaciones locales. Los CFD son instrumentos complejos y conllevan un alto riesgo de perder dinero rápidamente debido al apalancamiento. Entre el 74 % y el 89 % de las cuentas de los inversores minoristas pierden dinero al operar con CFD. Debe considerar si comprende cómo funcionan los CFD y si puede permitirse asumir el alto riesgo de perder su dinero. 74-89% de las cuentas minoristas de CFD pierden dinero. Para obtener más información sobre nuestras tarifas, consulte este enlace.',
    youcan: 'Puede utilizar su',
    freeDemo: 'Cuenta de demostración gratuita ',
    desYouCan: 'en todas nuestras plataformas de negociación, incluidas las de escritorio y móviles para iOS y Android, y también puede acceder a ',
    client: 'Gabinet del cliente',
    desYouCan2: ', nuestro portal de administración de cuentas basado en la web.',
    practice: 'Práctica',
    practicedes: 'Opere moneda virtual en una cuenta simulada',
    learn: 'Conozca nuestras Herramientas y Servicios',
    learndes: 'Familiarizase con las plataformas de software',
    msgCode1: 'Introduzca el código de activación que se envió a su correo electrónico.',
    msgCode2: 'Asegúrese de comprobar sus carpetas de spam/basura.',
    sendNewCode: 'Enviar nuevo código',
    submit: 'Enviar',
    formmessage1: '¡Casi terminamos! Selecciona tu tipo de ',
    formmessage2: 'cuenta y proporciona la información básica',
    formemail: '*CORREO ELECTRÓNICO',
    formaccounttype: '*TIPO DE CUENTA',
    individual: 'Individual',
    formfirtname: '*NOMBRE',
    formlastname: '*APELLIDO',
    formregister: 'Registrarse',
    missingFields: 'Faltan campos',
    requiredFields: 'Por favor Rellene todos los campos obligatorios',
    passwordnotmatch: 'Las contraseñas no coinciden.',
    passwordEspCharacter: 'La contraseña debe tener al menos un carácter especial.',
    passwordOneNumber: 'La contraseña debe tener al menos un número.',
    passwordCharacter: 'Length greater than or equal to 6 characters, including numbers and special characters.',
    validEmailPass: 'Longitud mayor o igual a 6 caracteres, incluyendo números y caracteres especiales.',
    codeActivation: 'Se ha enviado un nuevo código a su correo electrónico. Introduzca el código de activación.',
    tryAgain: 'Por favor intente otra vez más tarde',
    continue: "Que bien! Continúe registrándose",
    pleaseReview: "Por favor, revise los datos introducidos"
  },

  //Calendar
  calendar:{
    title:'Calendario'
  },
   //MFA
   mfa:{
    title:'Configuración de autenticación',
    subTitle:'Esta sección aún no está disponible',
    enable:'Habilitar autenticación de 2 pasos',
    body:'Además de su contraseña, configure un método de verificación secundario para mantener su cuenta aún más segura.',
    text:'Cuando habilite la autenticación en dos pasos, deberá ingresar su contraseña y un código de verificación enviado a su dirección de correo electrónico.',
    turn:'Girar',
    confirm:'Confirmar',
    textTwo:'¿Estás seguro de que quieres desactivar el MFA?',
    yes:'Si',
    no:'No',
    qr:'Por favor scanear el codigo QR',
    type:'Escriba el código generado:',
    setup: "Configuración 2factor",
    insert: "Insertar el código MFA:",
    submit: "Enviar",
    error: "Error al habilitar MFA:"
},

//Apllications
  applications:{
    title: 'Aplicaciones',
    progress: 'Progreso de la aplicación',

    modify: 'Modificar',
    completed: 'Completado',

    submit: 'Enviar',
    next: 'Siguiente',
    saved: 'Guardado',
    validation: 'Por favor llene todos los campos requeridos.',

    personaldetails:
    {
      personal: 'Detalles personales',
      title:'*TÍTULO',
      mr: 'Sr.',
      mrs: 'Sra.',
      dateofbirth: '*FECHA DE NACIMIENTO',
      countrybirth: '*PAÍS DE NACIMIENTO',
      nationality: '*NACIONALIDAD',
      countryresidence: '*PAÍS DE RESIDENCIA',
      statedis: '*ESTADO/DISTRITO',
      city: '*CUIDAD',
      text: '(text)',
      streetname: '*DIRECCIÓN RESIDENCIAL / NOMBRE DE LA CALLE',
      streetnunmber: 'DIRECCIÓN RESIDENCIAL / NÚMERO DE LA CALLE',
      postcode: '*CÓDIGO POSTAL',
      mobile: '*CELULAR',
      phone: 'TELÉFONO',
      passnumber: '*NÚMERO DE ID/PASAPORTE',
      passexpiration: '*FECHA DE VENCIMIENTO DEL ID/PASAPORTE',
      question1: '*¿Eres ciudadano estadounidense o residente estadounidense a efectos fiscales?',
      question2: '*¿Eres una persona expuesta políticamente (PEP), pariente cercano o asociado de una PEP?',
      yes: 'SI',
      no: 'NO',
      please: '*Proporcione detalles',
      bytick: '*Al marcar como se indica, estás aceptando lo siguiente:',
      agree1: 'ACEPTO/ACEPTAMOS LOS TÉRMINOS DE NEGOCIO DE TRADEVIEW EUROPE LTD., INCLUYENDO LOS ARCHIVOS ADJUNTOS:',
      conflicts: 'CONFLICTS OF INTEREST POLICY',
      and: 'Y',
      bets: 'BEST EXECUTION POLICY',
      agree2: "ACEPTO/ACEPTAMOS LA POLÍTICA DE PRIVACIDAD DE TRADEVIEW EUROPE LTD ",
      privacy: 'PRIVACY POLICY',
      agree3: "ACEPTO/ACEPTAMOS SER INCLUIDO/INCLUIDOS EN LAS LISTAS DE DISTRIBUCIÓN DE MARKETING DE TRADEVIEW EUROPE LTD.",
    },

    taxresidency:{
      tax: 'Declaración de residencia fiscal',
      info: 'Confirmo por la presente que para efectos fiscales, soy residente en los siguientes países (indicar el tipo y el número de referencia que se aplica en cada país).',
      country: '*PAÍS',
      referencenumber: '*NÚMERO DE REFERENCIA FISCAL',
      addcountry: 'Agregar país',
      removecountry: 'Eliminar país'
    },

    financialinformation:{
      finalcial: 'Información financiera',
      question1: '*Estado laboral',
      option11: 'EMPLEADO',
      option12: 'TRABAJADOR POR CUENTA PROPIA',
      option13: 'JUBILADO',
      option14: 'DESEMPLEADO',
      option15: 'ESTUDIANTE',
      labeloption11: '*NOMBRE DEL EMPLEADOR',
      labeloption12: '*NATURALEZA DEL NEGOCIO',
      labeloption13: '*POSICIÓN OCUPADA',
      accountancy: 'CONTABILIDAD',
      adminsecretarial: 'ADMINISTRADOR/SECRETARÍA',
      agriculture: 'AGRICULTURA',
      bankreatil: 'BANCA - MINORISTA',
      bankInvestment: 'BANCA - INVERSIÓN',
      catering: 'ABASTECIMIENTO',
      dentist: 'DENTISTA',
      doctor: 'DOCTOR',
      education: 'EDUCACIÓN',
      emergencyservice: 'SERVICIOS DE EMERGENCIA',
      energyu: 'ENERGÍA/SERVICIOS',
      engineering: 'INGENIERÍA',
      entretaiment: 'ENTRETENIMIENTO',
      finalcialadvisor: 'ASESOR FINANCIERO',
      financialservices: 'SERVICIOS FINANCIEROS ',
      armedforces: 'FUERZAS ARMADAS',
      hr: 'RECURSOS HUMANOS ',
      insurance: 'SEGUROS',
      it: 'TECNOLOGÍAS DE LA INFORMACIÓN',
      legal: 'LEGAL',
      leisure: 'OCIO',
      manufacturing: 'MANUFACTURA',
      marketing: 'MARKETING',
      media: 'MEDIOS DE COMUNICACIÓN',
      motortrade: 'COMERCIO DE AUTOMÓVILES',
      property: 'PROPIEDAD',
      retail: 'MINORISTA',
      sales: 'VENTAS',
      socialservices: 'SERVICIOS SOCIALES',
      sports: 'DEPORTES Y RECREACIÓN',
      stockbroker: 'CORREDOR DE VALORES',
      telecomunication: 'TELECOMUNICACIONES',
      transport: 'TRANSPORTE',
      arms: 'COMERCIO DE ARMAS/DEFENSA',
      carwash: 'LAVADO DE COCHES / GASOLINERA',
      casino: 'CASINO',
      construction: 'CONSTRUCCIÓN',
      heald: 'SERVICIOS DE SALUD',
      mining: 'MINERÍA',
      money: 'SERVICIO DE DINERO/REMESAS',
      pharmaceuticals: 'FARMACÉUTICOS',
      precious: 'METALES PRECIOSOS',
      public: 'CONTRATACIÓN PÚBLICA',
      virtual: 'MONEDAS VIRTUALES',
      question2: '*Tus ingresos anuales',
      option21: 'MENOS DE EUR 10K',
      option22: 'EUR11K - EUR 30K',
      option23: 'EUR31 - EUR 50K',
      option24: 'EUR 51K - EUR 100K',
      option25: '100K - EUR 200K',
      option26: 'EUR 200K - EUR 500K',
      option27: ' MÁS EUR 500K',
      question3: '*Tus activos totales (ahorros, inversiones, propiedades inmobiliarias después de deducir compromisos)',
      option31: 'MENOS DE EUR 10K',
      option32: 'EUR11K - EUR 30K',
      option33: 'EUR31 - EUR 50K',
      option34: 'EUR 51K - EUR 100K',
      option35: '100K - EUR 200K',
      option36: 'EUR 200K - EUR 500K',
      option37: ' MÁS EUR 500K',
      question4: '*Tus activos líquidos (efectivo, instrumentos del mercado monetario y valores negociables)',
      option41: 'MENOS DE EUR 10K',
      option42: 'EUR11K - EUR 30K',
      option43: 'EUR31 - EUR 50K',
      option44: 'EUR 51K - EUR 100K',
      option45: '100K - EUR 200K',
      option46: 'EUR 200K - EUR 500K',
      option47: ' MÁS EUR 500K',
      question5: '*Nivel anticipado de inversión/trading',
      option51: 'MENOS DE EUR 10K',
      option52: 'EUR11K - EUR 30K',
      option53: 'EUR31 - EUR 39K',
      option54: 'EUR 40K - EUR 99K',
      option55: '101K - EUR 200K',
      option56: 'EUR 201K - EUR 500K',
      option57: ' MÁS EUR 500K',
      questionselect1: '*¿CUÁL ES EL ORIGEN DE TUS ACTIVOS TOTALES?',
      soursefounds1: 'Ahorros de salario',
      soursefounds2: 'Ayuda del gobierno',
      soursefounds3: 'Venta',
      soursefounds4: 'Inversiones, dividendos',
      soursefounds5: 'Ingresos de un fideicomiso',
      soursefounds6: 'Alquiler',
      soursefounds7: 'Herencia',
      soursefounds8: 'Donación',
      soursefounds9: 'Préstamo',
      soursefounds10: 'Ingresos de trabajo por cuenta propia',
      soursefounds11: 'Apoyo de padres, pareja u otros',
      soursefounds12: 'Apoyo de un familiar',
      soursefounds13: 'Otro',
      questionselect2: '*¿CUÁL ES LA FUENTE/ORIGEN DE TUS DEPÓSITOS (FUENTE DE FONDOS)?',
      question6: '*PLEASE EXPLAIN YOUR SOURCE OF INCOME',
      question7: '*PLEASE EXPLAIN YOUR SOURCE OF INCOME',
      questionselect3: '*ELIGE UNA PLATAFORMA',
      questionselect4: '*TIPO DE MONEDA',
      question8: '*¿Estás conectado a bancarrotas?',
      yes: 'SI',
      no: 'NO',
      question9: '*¿A QUÉ TIPO DE BANCARROTAS ESTÁS CONECTADO?',
      question10: '*¿Propósito de la cuenta?',
      option101: 'COBERTURA',
      option102: 'ESPECULACIÓN',
      option103: 'OPERACIONES PROPIETARIAS',
      option104: 'INVERSIÓN A LARGO PLAZO',
      option105: 'OTRO',
    },

    knowledge:{
      know: 'Conocimiento',
      question1: '*¿Cuál es el nivel de educación que has obtenido?',
      option11: 'TENGO UN TÍTULO / DIPLOMA DE POSGRADO EN FINANZAS/ECONOMÍA/MATEMÁTICAS',
      option12: 'TENGO UN TÍTULO DE GRADO Y/O MAESTRÍA Y/O DOCTORADO, PERO NO ESTÁ RELACIONADO CON FINANZAS/ECONOMÍA/MATEMÁTICAS',
      option13: 'TENGO UNA CUALIFICACIÓN PROFESIONAL (COMO CISA CFS, CISI) O UNA LICENCIA Y QUE INCLUYE CONTENIDO SOBRE CFDS U OTROS PRODUCTOS ESPECULATIVOS',
      option14: ' TENGO UN NIVEL DE EDUCACIÓN SECUNDARIA',
      option15: 'NO TENGO EDUCACIÓN FORMAL',
      question2: '*¿Has trabajado o estás trabajando en la industria de servicios financieros?',
      option21: 'ESTOY TRABAJANDO O HE TRABAJADO COMO OPERADOR O EN UN ÁREA RELACIONADA CON EL TRADING DE CFDS, INSTRUMENTOS FINANCIEROS APALANCADOS OTC EN LOS ÚLTIMOS 5-10 AÑOS',
      option22: 'ESTOY TRABAJANDO O HE TRABAJADO COMO OPERADOR O EN UN ÁREA RELACIONADA CON EL TRADING DE VALORES NEGOCIABLES EN BOLSA, OPCIONES Y/O MATERIAS PRIMAS EN LOS ÚLTIMOS 5-10 AÑOS',
      option23: 'TRABAJÉ EN SERVICIOS FINANCIEROS, PERO NO COMO OPERADOR O SIN RELACIÓN CON EL TRADING EN LOS ÚLTIMOS 2-10 AÑOS',
      option24: 'NO TRABAJO O NO HE TRABAJADO EN SERVICIOS FINANCIEROS EN LOS ÚLTIMOS 5-10 AÑOS',
      question3: '¿Cuál es la mejor explicación del apalancamiento en el trading de FX/CFD?',
      option31: 'EL APALANCAMIENTO PERMITE A LOS TRADERS MULTIPLICAR EL TAMAÑO DE SUS POSICIONES DE TRADING. AMPLIFICA LA POTENCIAL PÉRDIDA O GANANCIA.',
      option32: 'EL APALANCAMIENTO AMPLIFICA TU POTENCIAL DE GANANCIA PERO NO AFECTA TU POTENCIAL DE PÉRDIDA.',
      option33: 'EL APALANCAMIENTO NO TIENE EFECTO EN MIS OPERACIONES.',
      question4: '*¿Cuáles de los siguientes son importantes para minimizar el riesgo al operar con CFD?',
      option41: 'MONITOREO DE SUS POSICIONES ABIERTAS',
      option42: 'SABER QUÉ NIVEL DE APALANCAMIENTO SE UTILIZA',
      option43: 'AMBAS DECLARACIONES',
      question5: '*¿Qué tipo de orden de cierre se puede utilizar para controlar la cantidad de pérdida potencial?',
      option51: 'STOP LOSS',
      option52: 'TAKE PROFIT',
      option53: 'NINGUNA DE LAS ANTERIORES',
      question6: '*¿Cuáles son los riesgos asociados con la operación de productos con apalancamiento?',
      option61: 'THERE ARE NO RISKS',
      option62: 'RIESGO DE PERDER EL DINERO INVERTIDO',
      option63: 'NINGUNA DE LAS ANTERIORES',
    },

    experience:{
      exp: 'Experiencia',
      description: 'Por favor, selecciona la mejor opción para describir tu experiencia en trading:',
      subtitle1: 'FUTURES, OPTIONS Y DERIVATES:',
      question1: '*Años de experiencia:',
      option11: 'NINGUNO',
      option12: '0 - 1 AÑO',
      option13: '2-5 AÑOS',
      option14: '5-10 AÑOS',
      option15: 'MÁS DE 10 AÑOS',
      question2a: '*Volumen de operaciones:',
      question2b: '',
      option21: 'NINGUNO',
      option22: 'EUR 0 - EUR 50,000',
      option23: 'EUR 50,001 - EUR 100,000',
      option24: 'EUR 100,001 - EUR 500,000',
      option25: 'EUR 500,001 O MÁS',
      question3: '*Frecuencia',
      option31: 'NINGUNO',
      option32: 'DIARIO',
      option33: 'SEMANAL',
      option34: 'MENSUAL',
      option35: 'TRIMESTRAL',
      option36: 'SEMI-ANUAL',
      subtitle2: 'APALANCAMIENTO EN DIVISAS (FX), CFD Y METALES PRECIOSOS',
      question4: '*Años de experiencia:',
      option41: 'NINGUNO',
      option42: '0 - 1 AÑO',
      option43: '2-5 AÑOS',
      option44: '5-10 AÑOS',
      option45: 'MÁS DE 10 AÑOS',
      question5: '*Volumen de operaciones:',
      option51: 'NINGUNO',
      option52: 'EUR 0 - EUR 50,000',
      option53: 'EUR 50,001 - EUR 100,000',
      option54: 'EUR 100,001 - EUR 500,000',
      option55: 'EUR 500,001 O MÁS',
      question6: 'Frecuencia:',
      option61: 'NINGUNO',
      option62: 'DIARIO',
      option63: 'SEMANAL',
      option64: 'MENSUAL',
      option65: 'TRIMESTRAL',
      option66: 'SEMI-ANUAL',
    },

    verification:{
      idv: 'Verificación de identidad',
      h1: '¡Ya casi terminamos!',
      subtitle1: 'Solo necesitamos verificar tu identidad.',
      description: 'Para aprobar tu cuenta de trading, es necesario verificar tu identidad. Adjunta los siguientes documentos a tu solicitud para su revisión. Tradeview puede solicitar información adicional o documentos para cumplir con las regulaciones de AML y CFT.',
      pleaseprovide: 'Por favor proporciona estos documentos:',
      h2a: 'Identificación con foto emitida por el gobierno.',
      h2b: "Prueba de residencia",
      descriptionh2a: 'Por favor, sube una copia en color de tu pasaporte (recomendado), tarjeta de identidad o permiso de residencia.',
      descriptionh2b: 'Por ejemplo, factura de servicios públicos, certificado de residencia, estado de cuenta bancaria.',
      h2c: 'Identificación con foto emitida por el gobierno.',
      descriptionh2c1: 'Por favor, sube una copia en color de tu pasaporte (recomendado), tarjeta de identidad o permiso de residencia.',
      descriptionh2c2: 'Ten en cuenta lo siguiente:',
      descriptionh2c3: 'Tu documento de identidad debe ser claro y visible.',
      descriptionh2c4: 'Tu documento de identidad debe ser emitido por una agencia gubernamental.',
      descriptionh2c5: 'Tu nombre completo, fecha de nacimiento y fecha de vencimiento/emisión deben mostrarse claramente.',
      descriptionh2c6: 'Adjunta el frente y el reverso de tu documento de identidad como archivos separados.',
      select1: '*TIPO DE DOCUMENTO',
      typeofdocs1: 'Pasaporte (recomendado)',
      typeofdocs2: 'Licencia de conducir',
      typeofdocs3: 'Tarjeta de identidad',
      typeofdocs4: 'Permiso de residencia',
      h2d: 'Carga tu documento:',
      descriptionh2d1: 'Selecciona la parte FRONTAL de tu documento',
      front: 'FRENTE',
      descriptionh2d2: 'Seleccione el REVERSO de su documento',
      back: 'REVERSO',
      description1: 'Cada archivo debe tener un formato JPG, PNG o JPEG, y tener un tamaño inferior a 2MB.',
      goback: 'Regresar',
      done: 'Hecho',
      h2e: 'Prueba de residencia',
      descriptionh2e1: 'Por ejemplo, factura de servicios públicos, certificado de residencia, estado de cuenta bancaria.',
      descriptionh2e2: 'Todas las pruebas de residencia deben ser actuales y no deben tener más de 90 días de antigüedad.',
      descriptionh2e3: 'Muestra el documento completo, donde se muestre claramente el nombre y la dirección completa (sin apartados postales).',
      descriptionh2e4: 'Muestra una provisión de servicios, como una deuda o pago realizado.',
      select2: '*TIPO DE DOCUMENTO',
      proofType1: "Factura de servicios públicos",
      proofType2: "Certificado de residencia",
      proofType3: "Extracto bancario",
      h2f: 'Carga tu documento:',
      descriptionh2f1: 'Seleccionar archivos',
      descriptionh2f2: 'Cada archivo debe tener un formato JPG, PNG o JPEG, y tener un tamaño inferior a 2MB.',
      description2: '*DOY MI CONSENTIMIENTO PARA QUE TRADEVIEW VERIFIQUE ELECTRÓNICAMENTE MI IDENTIDAD PROPORCIONANDO MI INFORMACIÓN PERSONAL, INCLUYENDO DOCUMENTOS DE IDENTIDAD, A UN PROVEEDOR DE SERVICIOS DE VERIFICACIÓN DE IDENTIDAD PARA OBTENER UNA EVALUACIÓN DE SI COINCIDE CON LA INFORMACIÓN QUE ELLOS OTRAS FUENTES DE VERIFICACIÓN DE IDENTIDAD POSEEN.',
      uploadDocument: "Cargue su documento.",
      documentCorresponding: "Por favor, suba su documento correspondiente a la identificación gubernamental con foto y prueba de residencia",
      cantAttach: "No puedes adjuntar esta imagen, inténtalo con otra"
    },

    acknowledgement:{
      ack: 'Aviso de recepción',
      description: '*Haz clic en los enlaces de los documentos a continuación y confirma tu comprensión y acuerdo con el documento. Después de confirmar la solicitud, los contratos y las divulgaciones, lee la afirmación en el bloque de la firma y, utilizando el mouse, firma con tu nombre.',
      check1: 'CLIENT AGREEMENT',
      check2: 'ELECTRONIC TRADING AGREEMENT',
      check3: 'RISK DISCLOSURE',
      check4: 'PRIVACY POLICY',
      check5: 'CONSENT TO DELIVERY OF STATEMENTS BY ELECTRONIC MEDIA',
      check6: 'COMMISSION AGREEMENT',
      check7: 'TERMS AND CONDITIONS',
      check8: 'BEST EXECUTION POLICY',
      check9: 'CONFLICTS OF INTEREST POLICY',
      check10: 'Key Information Documents (KID):',
      forex: 'FOREX',
      crypto: 'CRYPTOCURRENCY',
      commodities: 'COMMODITIES',
      index: 'INDEX',
      description2: 'Al firmar a continuación, reconozco y acepto los términos del Contrato del Cliente, Contrato de Operaciones Electrónicas, Divulgación de Riesgos, Política de Privacidad, Consentimiento para la Entrega de Estados de Cuenta por Medios Electrónicos, Contrato de Comisión, Términos y Condiciones, Política de Mejor Ejecución y Política de Conflictos de Intereses mencionados anteriormente. Además, reconozco y acepto, bajo pena de perjurio, que toda la información proporcionada en la solicitud es verdadera y precisa, y que todos los depósitos en mi cuenta, independientemente de su origen, tipo de moneda o método de depósito, no son producto de actividades criminales o ilegales y provienen de fuentes legítimas.',
      authorized: 'FIRMA AUTORIZADA',
      signature: 'FIRMAR ARRIBA.',
      confirm: 'Confirmar firma',
      clean: 'Borrar firma',
      hasNot: 'No ha firmado'
    },

    riskdisclosure:{
      add: 'Divulgación de riesgos adicional',
      description: "La información proporcionada en su solicitud de cuenta indica que usted no cumple con las pautas de Tradeview Europe Ltd. para abrir una cuenta comercial por una de las siguientes razones:",
      list1: 'No tienes entre 21 y 63 años',
      list2: 'Su ingreso anual es inferior a 10 mil EUR',
      list3: 'Se ha declarado en bancarrota en el último año.',
      list4: 'No estás empleado',
      description1: 'Si bien Tradeview Europe Ltd. está preparado para abrir su cuenta, es necesario aconsejarle que reconsidere esta inversión. Según su información personal y/o experiencia de inversión, forex podría ser una estrategia de inversión demasiado arriesgada. La pérdida en el comercio de divisas puede ser sustancial. Por lo tanto, debe considerar detenidamente si dicha negociación es adecuada para usted a la luz de sus circunstancias y recursos financieros. El alto grado de apalancamiento que se obtiene en el comercio de divisas debido a los requisitos de margen pequeño puede funcionar tanto en su contra como a su favor. El uso del apalancamiento puede conducir tanto a grandes pérdidas como a ganancias.',
      description2: '*ENTIENDO QUE NO CUMPLO CON LAS NORMAS MÍNIMAS PARA ABRIR UNA CUENTA SEGÚN LO ESTABLECIDO POR TRADEVIEW EUROPE LTD. EN LOS PÁRRAFOS ANTERIORES. SIN EMBARGO, HE CONSIDERADO LOS RIESGOS FINANCIEROS INVOLUCRADOS EN EL COMERCIO FOREX CON RESPECTO A MI SITUACIÓN PERSONAL, Y DESEO PROCEDER A ABRIR UNA CUENTA.',
      description3: '*RECONOZCO QUE HE LEÍDO LO ANTERIOR Y ENTIENDO COMPLETAMENTE LA INFORMACIÓN PROPORCIONADA AQUÍ.',
    },

    confirmation:{
      subtitle1: 'Está a un clic de enviar su solicitud',
      description: 'Si necesita revisar o editar su información previamente introducida, puede ir al enlace ',
      modify: '"Modificar"',
      description2: 'de cada sección. Si está listo para enviar su solicitud ahora simplemente haga clic en el botón de enviar.',
      subapplication: 'Enviar solicitud',
      congratulation: 'Felicidades',
      descriptionmodal: 'Gracias por su aplicación. Sus documentos cargados están bajo revisión y serán verificados en breve. Nuestro equipo de operaciones estará en contacto con usted si necesitamos más documentación.',
      close: 'Cerrar',
      your: 'Su solicitud de',
      account: 'cuenta individual',
      description3: ' está siendo procesada. El estado final será notificado en su correo electrónico.'
    }
  }
}
