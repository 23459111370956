export default {
  //login home page
  loginHeader: {
    contactUs: 'Contattaci'
  },
  loginPage: {
      instructionstitle: 'Area Clienti',
      instructionsLine1: 'Effettua l’accesso utilizzando la tua e-mail e la password che hai creato per il tuo Conto Tradeview.',
      instructionsLine2: 'Nell’Area Clienti sarai in grado di:',
      featureList1: 'Controllare il riepilogo del tuo conto',
      featureList2: 'Accedere allo storico del tuo conto',
      featureList3: 'Depositare sul tuo conto',
      featureList4: 'Trasferire fondi tra I tuoi conti',
      featureList5: 'Richiedere un prelievo dal tuo conto',
      pFooter: "I servizi e i prodotti offerti da Tradeview Europe Ltd. non vengono offerti negli Stati Uniti (USA) e non vengono offerti a soggetti statunitensi, come definito dalla legge statunitense. Le informazioni su questo sito Web non sono dirette ai residenti di paesi in cui il trading di FX e/o CFD è limitato o proibito dalle leggi o dai regolamenti locali. I CFD sono strumenti complessi e presentano un rischio significativo di perdere denaro rapidamente a causa della leva finanziaria. Il 68.3% degli investitori al dettaglio perde denaro a causa delle negoziazioni in CFD con Tradeview Europe LTD. Valuta se comprendi il funzionamento dei CFD e se puoi permetterti di correre questo alto rischio di perdere il tuo denaro.",
      linkFooter: 'link.',
      adressOficce: 'UFFICIO TRADEVIEW EUROPE:',
      termsAndConditions: 'Termini e Condizioni',
      privacyPolicy: 'Policy sulla Privacy',
      amlPolicy: 'Policy AML',
      refundPolicy: 'Policy sui Rimborsi',
      riskDisclosure: 'Avvertenza sui Rischi',
      complaintsProcedure: 'Procedura Reclami',
      cookiePolicy: 'Policy sui Cookie'
  },
  loginBox: {
      logInGoogle: 'Accedi con Google',
      logInFacebook: 'Accedi con Facebook',
      or: 'O',
      email: 'INDIRIZZO EMAIL',
      password: 'PASSWORD',
      submit: 'Accedi',
      forgot: ' Hai dimenticato la tua password?',
      doNotAccount: "Non hai un conto?",
      signUp: 'Registrati',
      tryFreeDemo: 'Prova una demo gratuita',
      showDialog1: 'Si prega di intserire una email e password valide',
      changePass: 'Change was successful!'
  },
  consoleHeader: {
      account: 'Account #',
      balance: 'Balance',
      equity: 'Equity',
      free: 'Free Margin',
      margin: 'Margin Levelator',
      status: 'Status',
      hello: 'Ciao',
      logout: 'Esci',
      confirm: 'Confirm',
      outModal: 'Sei sicuro di voler uscire?',
      yes: 'SI',
      not: 'NO'
  },

  // Platforms page
  platforms:{
      title: 'Scarica le Piattaforme',
      accountLive: 'Server Conto Reale:',
      accountDemo: 'Server Conto Demo:'

  },

  //History page
  history:{
    title: 'Storico',
    accountActivity: 'Attività Conto',
    tradingHistory: 'Storico del Conto'
  },

  // Withdraw page
  withdraw:{
      title: 'Prelievo',
      platform: 'Piattaforma',
      sourceAccount: 'Conto*',
      method: 'Metodo*',
      currency: 'Valuta*',
      start: 'Preleva ora',
      beneficiary: '*Nome Beneficiario',
      details: 'Dettagli Banca Beneficiario',
      number: '*Numero Conto (IBAN se applicabile)',
      code:'*Codice SWIFT',
      reference: 'Causale',
      subTitle: 'Intermediary Bank Details (If Needed)',
      name_benef:'*Nome della banca',
      bank: 'Bank Address',
      swift: 'Bank SWIFT',
      branch: 'Branch Name',
      province: 'Province',
      city:'City',
      dialogShow:  "Errore! questa piattaforma non ha conti attivi per continuare con questa procedura, prova con un'altra piattaforma.",
      errorOne: 'Errore! Non possono essere lasciati campi vuoti',
      errorTwo: 'Errore! Il valore eccede il saldo del tuo conto',
      errorThree: "Errore! l'email non è valida",
      errorFour: 'Errore! per le carte di credito solo caratteri numerici',
      dialogShowTwo: "Richiesta inviata. Ti preghiamo di controllare la tua email",
      dialogShowThree:  "Ti preghiamo di contattare il supporto di Tradeview",
      emailAddress: "Indirizzo email associato alla Carta di Credito",
      creditcard:"Carta di Credito (ultime 4 cifre)",
      netellerEmail: "Email Conto Neteller",
      netellerNumber: "Numero Conto Neteller",
      skrillEmail: "Skrill Email Account",
      skrillNumber: "Skrill Account Number",
      upholdEmail: "Uphold Email Account",
      upholdNumber: "Uphold Account Number",
  },

  //Account Activity page
  accountActivity:{
      title: 'Storico del Conto',
      from: '*Da',
      to:'*A',
      type:'*Tipo',
      platform:'*Piattaforma',
      placeholderAccount: 'Conto di Trading',
      account: '*Conto',
      get: 'Ottieni',
      row:'Rows for page',
      download:'Download Report',
      back:'Indietro',
      confirm: 'Confirm',
      close:'Chiudi',
      error: 'Errore! Non possono essere lasciati campi vuoti',
      errorTwo: 'Error! you must select the field type.',
      activityid: 'ID Attività',
      platfomr: 'Piattaforma',
      date: 'Data/Ora',
      activity: 'Attività',
      profit: 'Profitto/Perdita',

  },


  //tradingHistory
  tradingHistory:{
      title: 'Storico del trading',
      from: '*Da',
      to:'*A',
      type:'Tipo',
      platform:'Piattaforma',
      placeholderAccount: 'Conto di Trading',
      account: '*Conto',
      get: 'Ottieni',
      row:'Rows for page',
      download:'Download Report',
      back:'Indietro',
      confirm: 'Confirm',
      close:'Chiudi',
      error: 'Errore! Non possono essere lasciati campi vuoti',
      errorTwo: 'Error! you must select the field type.',
      deal:'Deal',
      entryTime: 'Ora Entrata',
      typr: 'Tipo',
      simbol: 'Simbolo',
      volume:'Volume',
      entryPrice: 'Prezzo Entrata',
      sl: 'S/L',
      tp: 'T/P',
      exitTime: 'Ora Uscita',
      exitPrice: 'Prezzo Uscita',
      commision:'Commissione',
      swap: 'Swap',
      profit: 'Profitto / Perdita',
      live: 'Reale',
      demo: 'Demo'
  },

  newLiveAccount:{
      title:'Crea un nuovo Conto Reale',
      platform:'*Seleziona una Piattaforma',
      currency:'*Valuta',
      account: 'Crea Conto',
      back:'Indietro',
      congratulation:'Congratulazioni',
      text:'La tua richiesta per un Conto Reale è in fase di verifica. Il nostro team di Operations si metterà in contatto con te qualora ci servisse ulteriore documentazione.',
      error:'Errore! Non possono essere lasciati campi vuoti',
      close:'Chiudi',


  },

  //Transfer page
  transfer:{
      title: 'Trasferisci fondi tra I tuoi conti di trading',
      subTitle: 'Per trasferire fondi da un conto Tradeview a un altro, compila il seguente modulo.',
      platform: 'Piattaforma',
      sourceAcount: 'Conto di provenienza',
      targetAccount: 'Conto di destinazione',
      sendingAmount: 'Importo da trasferire',
      condition: 'Selezionando questa casella, capisco di aver richiesto il trasferimento di fondi da un conto Tradeview Europe Ltd. a un altro dei miei conti Tradeview Europe Ltd.',
      start: 'Trasferisci',
      showDialogOne: 'Please Contact Tradeview Support',
      showDialogTwo:'Please Contact Tradeview Support',
      submited: 'Request Submited!, Please check your Email',
      error:'Errore! Non possono essere lasciati campi vuoti',
      errorTwo:'Errore! I numeri di conto devono essere diversi',
      errorThree:"Errore! L'importo non è valido",
      errorFour:'Errore! Il valore eccede il saldo del tuo conto'
  },

  //Accounts
  accounts:{
      warning:'Your Demo Account Request is being processed. Final status will be notiﬁed at your email.',
      live:'Conto reale di Trading',
      demo:'Conti Demo',
      createLive:'+ Crea un nuovo Conto Reale',
      createDemo:'+ Crea un nuovo Conto Demo',
      noAccountLive: 'Nessun conto reale per questo indirizzo email',
      noAccountDemo: 'Nessun conto demo per questo indirizzo email',
      dialogShowOne: 'You currently have no Metatrader 4 demo accounts.',
      dialogShowTwo: 'You currently have no Metatrader 5 demo accounts.',
      dialogShowThree: 'You currently have no Metatrader 4 live accounts.',
      dialogShowFour: 'You currently have no Metatrader 5 live accounts.',
      account: 'CONTO #',
      balance: 'SALDO',
      equity: 'EQUITA’',
      margin:'LIVELLO MARGINE',
      free: 'MARGINE DISPONIBILE',
      status: 'STATO',
      leverage:'LEVA',
      currency: 'VALUTA'
  },

  //CreateDemoAccounts
  demoAccounts:{
      title:'Crea un nuovo Conto Demo',
      platform:'*Seleziona una Piattaforma',
      type:'* Tipo di Conto',
      amount:'* Importo Deposito',
      currency: '* Valuta',
      account: 'Creare un account',
      back:'Indietro',
      created:'Il tuo Conto Demo è stato creato',
      text:'Ti consigliamo di salvare queste credenziali prima di chiudere questa finestra, perchè NON verranno inviate sulla tua email.',
      login: 'Login',
      pass:'Password',
      currencyTwo:'Valuta',
      server:'Server',
      data:'TradeviewEurope-Demo Europe',
      download:"Ora sei pronto per scaricare la tua piattaforma di trading:",
      close:'Chiudi',
      error:'Errore! Non possono essere lasciati campi vuoti',
      professional: 'Professionale',
      retail: 'Retails',
      stocks: 'Stocks'
  },

  //credit card
  credit:{
      title: 'Credit cards',
      subTitle: 'This section is not yet available',
      subTitleAdd: 'Aggiungi Fondi',
      platform: 'Piattaforma',
      trading: 'Conto di Trading',
      amount:'* Importo (Min 100€ - Max. 10000€)',
      currency: '* Valuta',
      details: 'Dettagli Carta di Credito',
      cardName: '* Titolare Carta',
      cardNumber: '* Numero Carta',
      expiration: 'Data di scadenza',
      fund: 'Deposita',
      disclaimer: "I depositi vengono accettati da un conto/carta registrata a tuo nome. Ti preghiamo di notare che potremmo richiederti documentazione aggiuntiva e/o informazioni a scopo di verifica.",
      back: 'Indietro',
      textOne: 'Are you sure you want to Deposit',
      textTwo: 'with your Credit card?',
      cancel: 'Cancel',
      confirm: 'Confirm',
      deposit: 'Deposit',
      ok: 'Ok',
      depositUrl: 'Redirect to Zotapay',
      errorOne:'Error! Amount (Min. 100 USD - max. 10,000 USD)',
      errorTwo:'Errore! Non possono essere lasciati campi vuoti',
      errorThree:'Error! Is not a valid security code',
      errorFour:'Error! Is not a valid expiration date',
      errorFive:'Error! Is not a valid card number',
  },


  //Banck Transfer add found Skrill
  addFounds:{
    title:'Aggiungi Fondi',
    platform: 'Piattaforma',
    account:'Conto di Trading ',
    currency:'Valuta',
    amount:'Importo (Min. 100 EUR - max. 10,000 EUR)',
    email: 'Indirizzo email',
    disclaimer:'I depositi vengono accettati da un conto/carta registrata a tuo nome. Ti preghiamo di notare che potremmo richiederti documentazione aggiuntiva e/o informazioni a scopo di verifica.',
    fund:'Deposita',
    back:'Indietro',
    redi:'Redirecting',
    redirection:'Reindirizzando a',
    checkmark:'Cliccando questo box acconsenti a tutte le policy di Tradeview Europe Ltd.',
    submit:'Submit'
},

 //Add Bank Funds
 addBanckFounds:{
    title:'Aggiungi fondi',
    description:'Si prega di utilizzare queste informazioni per i bonifici in entrata:',
    back:'Indietro',
    headers1: 'Per Bonifici in USD',
    rows1Headers1: 'Beneficiario:',
    subrows1Headers1:'BMO Harris Bank, N.A',
    rows2Headers1:  'Swift',
    subrows2Headers1: 'HATRUS44',
    rows3Headers1:  'ABA No:',
    subrows3Headers1: '071000288',
    rows4Headers1:  'Causale:',
    subrows4Headers1: '(Il tuo Nome + N° Conto Tradeview)',
    rows5Headers1:  'Nome Conto:',
    subrows5Headers1: 'Tradeview Europe Limited',
    rows6Headers1:  'Numero Conto:',
    subrows6Headers1: '4243275',
    rows7Headers1:  'Indirizzo Banca:',
    subrows7Headers1: '111 West Monroe St, Chicago, IL 60690',
    rows8Headers1:  'Indirizzo Beneficiario:',
    subrows8Headers1: '114/3 The Strand, Gzira, GZR 1027, Malta',
    headers2: 'Per Bonifici in EUR',
    rows1Header2: 'Beneficiario',
    subrows1Header2: 'BMO Harris Bank, N.A',
    rows2Header2: 'Swift ',
    subrows2Header2: 'HATRUS44GTM',
    rows3Header2: 'Banca Intermediaria',
    subrows3Header2: 'Deutsche Bank - Frankfurt',
    rows4Header2: 'Swift Intermediario',
    subrows4Header2: 'DEUTDEFF',
    rows5Header2: 'Causale:',
    subrows5Header2: '(Il tuo Nome + N° Conto Tradeview)',
    rows6Header2: 'Nome Conto',
    subrows6Header2: 'Tradeview Europe Limited',
    rows7Header2: 'Numero Conto',
    subrows7Header2: ' 300894250262',
    rows8Header2: 'Indirizzo Banca',
    subrows8Header2: '111 West Monroe St, Chicago, IL 60603',
    rows9Header2: 'Indirizzo Beneficiario',
    subrows9Header2: '114/3 The Strand, Gzira, GZR 1027, Malta',
    headersSecond:'For GBP, EUR, USD, CHF and JPY Deposits',
    rows1HeadersSecond: 'Beneficiary Bank:',
    subrows1HeadersSecond: 'The Currency Cloud Limited',
    rows2HeadersSecond: 'Beneficiary Swift:',
    subrows2HeadersSecond: 'TCCLGB3L',
    rows3HeadersSecond: 'IBAN No:',
    subrows3HeadersSecond: 'GB62TCCL04140476597833',
    rows4HeadersSecond: 'Reference:',
    subrows4HeadersSecond: '210408-56275 + Your Name and Tradeview Account No.',
    rows5HeadersSecond: 'Account name:',
    subrows5HeadersSecond: 'Tradeview Europe Limited',
    rows6HeadersSecond: 'Account Number:',
    subrows6HeadersSecond: '4243275',
    rows7HeadersSecond: 'Bank Address:',
    subrows7HeadersSecond: '12 Steward Street, The Steward Building, London, E1 6FQ',
    rows8HeadersSecond: 'Beneficiary Address:',
    subrows8HeadersSecond: '114/3 The Strand, Gzira, GZR 1027, Malta',
    headersSecond2: 'Per Bonifici in EUR, CHF, DKK, GBP, HUF, NOK, PLN, USD',
    rows1HeadersSecond2: 'Beneficiario',
    subrows1HeadersSecond2: 'Tradeview Europe Limited',
    rows2HeadersSecond2: 'Valuta:',
    subrows2HeadersSecond2: 'EUR, CHF, DKK, GBP, HUF, NOK, PLN, USD',
    rows3HeadersSecond2: 'IBAN:',
    subrows3HeadersSecond2: 'DK5789000051025190',
    rows4HeadersSecond2: 'BIC:',
    subrows4HeadersSecond2: 'SXPYDKKK',
    rows5HeadersSecond2: 'Causale:',
    subrows5HeadersSecond2: '(Il tuo Nome e Cognome + Il Numero di conto Tradeview)',
    rows6HeadersSecond2: 'Bank Name:',
    subrows6HeadersSecond2: 'The Currency Cloud Limited',
    rows7HeadersSecond2: 'Bank Address:',
    subrows7HeadersSecond2: 'AMERIKA PLADS, 38 COPENHAGEN 2100 Denmark',
    rows8HeadersSecond2: 'Beneficiary Address:',
    subrows8HeadersSecond2: '114/3 The Strand, Gzira, GZR 1027, Malta',
    headers3: 'Per Depositi in GBP',
    rows1Headers3: 'Banca Beneficiaria:',
    subrows1Headers3:'BMO Harris Bank, N.A.',
    rows2Headers3:  'Codice Swift del Beneficiario:',
    subrows2Headers3: 'HATRUS44GTM',
    rows3Headers3:  "Nome dell'Account:",
    subrows3Headers3: 'Tradeview Europe Limited',
    rows4Headers3:  'Numero di Conto:',
    subrows4Headers3: '777554250262',
    rows5Headers3:  'Banca Intermediaria:',
    subrows5Headers3: 'Royal Bank of Scotland- London',
    rows6Headers3:  'Codice Swift della Banca Intermediaria:',
    subrows6Headers3: 'NWBKGB2L'
},

//Choose bank
chooseBank:{
    title:'Scegli banca',
    method:'MODALITA’ DI DEPOSITO',
    processing:'PROCESSING TIME',
    fee:'COMMISSIONE',
    back:'Indietro',
    instruction:"Le perdite possono eccedere l'investimento iniziale. Non saranno accettati pagamenti da parte di terzi. Tutti i depositi devono provenire dal proprietario del conto originale. La dimostrazione della piattaforma di trading è in euro o in usd. Tradeview si riserva il diritto di prenotare/rimborsare eventuali depositi effettuati tramite carta di credito al posto di un prelievo"
},

//Choose Credit Card
ChooseCreditCard:{
    title:'Carte di Credito',
    method:'MODALITA’ DI DEPOSITO',
    processing:'PROCESSING TIME',
    fee:'COMMISSIONE',
    back:'Indietro',
    messageZotapay: 'Zotapay al memento non è disponibile',
    instructionOne:"Tradeview Euope Ltd. cerca sempre di migliorare l'esperienza dei nostri clienti. Per questo motivo, forniamo ai nostri clienti metodi di deposito rapidi ed efficaci. Se necessario,",
    contact:'contatta',
    instructionTwo:'il nostro team di supporto che sarà lieto di aiutarti a velocizzare la tua richiesta.',
    instructionThree:"Non saranno accettati pagamenti da parte di terzi. Tutti i depositi devono provenire dal proprietario del conto originale. La dimostrazione della piattaforma di trading è in euro o in usd. Tradeview Euope Ltd. si riserva il diritto di prenotare/rimborsare eventuali depositi effettuati tramite Carta di Credito al posto di un prelievo"
},

//Choose alternative methods
ChooseAlternativeMethods:{
    title:'Scegli metodi alternativi',
    method:'MODALITA’ DI DEPOSITO',
    processing:'PROCESSING TIME',
    optionProcessing: 'Instant 24/7',
    fee:'COMMISSIONE',
    back:'Indietro',
    instructionOne:"Le perdite possono eccedere l'investimento iniziale. Non saranno accettati pagamenti da parte di terzi. Tutti i depositi devono provenire dal proprietario del conto originale. La dimostrazione della piattaforma di trading è in euro o in usd. Tradeview si riserva il diritto di prenotare/rimborsare eventuali depositi effettuati tramite carta di credito al posto di un prelievo"
},


  //deposit
  deposit:{
      title: 'Scegli il metodo di deposito',
      vendor: 'MODALITA’ DI DEPOSITO',
      processingCards: 'PROCESSING TIME',
      fee: 'COMMISSIONE',
      methodOne: 'Carte di Credito',
      methodTwo: 'Metodi alternativi',
      methodThree: 'Bonifico bancario',
      textOne:"Le perdite possono superare l’importo iniziale investito. Pagamenti da terze parti NON saranno accettati. Tutti i depositi DEVONO provenire dal titolare del conto. La denominazione della piattaforma di trading è in USD.",
      textTwo:'',
      textThree:'',
      textFour: "Tradeview Europe Ltd. si riserva il diritto di /rimborsare eventuali depositi effettuati tramite Carta di Credito al posto di un prelievo.",
  },

  //Email
  email:{
      title: 'Impostazioni email',
      subTitle: 'Gestisci qui le tue iscrizioni. Seleziona gli argomenti per cui vorresti ricevere newsletter personalizzate:',
      company: 'Notizie Societarie',
      selectOne: 'Notizie di trading (',
      selectTwo: "Surf's Up!",
      selectThree: 'newsletter)',
      update:'Aggiorna',
      instructions: 'Se non desideri più ricevere email su nessuno degli argomenti di cui sopra, deselezionali. Non dimenticare di fare clic su Aggiorna.',
      confirm: 'confirm',
      close: 'Chiudi',

  },

  //password
  password:{
      title:'Cambia Password',
      subTitle: 'Per cambiare la tua password ti preghiamo di richiedere un codice OTP sulla email del tuo conto.',
      request:'Richiedi Codice',
      submit:'Invia',
      code:'Codice',
      current:'Password attuale',
      newPassword: 'Nuova password',
      repeat:'Ripeti password',
      save: 'Salva le modifiche',
      confirm: 'Conferma',
      close:'Chiudi',
      dialogMessage: 'Un codice OTP è stato inviato alla tua email registrata.',
      dialogMessageOne:'Please type the current password',
      dialogMessageTwo:'Please specify a new password',
      dialogMessageThree:'Please confirm the new password',
      dialogMessageFour:"New/confirm passwords don't match",
      success: "Successo!"
  },

  //Contact
  contact:{
      title: 'Richiedi un contatto',
      firstname: 'IL TUO NOME*',
      lastname: 'IL TUO COGNOME*',
      phone: 'IL TUO CELLULARE*',
      email: 'LA TUA EMAIL*',
      lenguage: 'LINGUAGGI SUPPORTATI*',
      call: 'RARIO PREFERITO PER IL CONTATTO',
      check: 'Chiamami appena possibile',
      contactme:'Contattami',
      error: 'Errore! Non possono essere lasciati campi vuoti',
      close: 'Chiudi'
  },

  menu:{
    applications: 'Richieste',
    account: 'Conti',
    platforms: 'Piattaforme',
    history: 'Storico',
    deposit: 'Deposito',
    transfer: 'Trasferimento',
    withdraw: 'Prelievo',
    settings: 'Impostazioni',
    passwordsettings: 'Impostazioni Password',
    emailsettings: 'Impostazioni Email',
    authenticationsettings: 'Impostazioni Autenticazione',
    contactUs: 'Contattaci'

  },

  //register
  register:{
    title: 'Registrazione Conto',
    create: 'Crea i tuoi dati di accesso',
    createdes: 'Inserisci la tua email e scegli una password per iniziare',
    complete: 'Completa la tua domanda',
    completedes: 'Completa il nostro veloce e sicuro modulo per apertura conto',
    confirm: 'Conferma la tua identità',
    confirmdes: 'Verifica la tua identità online o carica I tuoi documenti di identità se richiesto',
    fund: 'Deposita e fai trading',
    funddes: 'Una volta approvato, finanzia il tuo conto usando I nostri metodi di deposito flessibili e a basso costo e inizia a fare trading',
    goBack: 'Indietro',
    getYourLive: 'Ottieni il tuo conto reale Ora!',
    getYourDemo: 'Get your Free Demo Account now!',
    instantly: 'Registrati subito:',
    registerGoogle: 'Registrati con Google',
    registerFacebook: 'Registrati con Facebook',
    or: 'O',
    email: '*INDIRIZZO EMAIL',
    secure: '*SCEGLI UNA PASSWORD',
    length: '(Lunghezza maggiore o uguale a 6 caratteri, inclusi numeri e caratteri speciali)',
    confirmPassword: '*CONFERMA PASSWORD',
    disclaimer: 'DISCLAIMER:',
    disclaimerdes1: ' Prima di aprire un conto è nostro obbligo a ',
    disclaimerdes2: " di assicurarci che tu o le persone incaricate di fare trading per tuo conto abbiano la conoscenza e l’esperienza necessarie sui CFD e strumenti finanziari correlati. E’ inoltre nostro obbligo rispettare gli obblighi in materia di AML e CFT. A tal proposito siete pregati di fornire informazioni accurate, complete e fattuali come richieste in linea con la presente richiesta e al fine di consentirci il completo adempimento degli obblighi di cui sopra. Sulla base di queste informazioni siamo in grado di determinare correttamente se i servizi di investimento in relazione allo/agli strumento/i finanziario/i sono appropriati per Te. Tutte le Sezioni devono essere completate. Per ulteriori dettagli, fare riferimento al nostro ",
    clientAgree: 'Client Agreement.',
    byTicking: '*CLICCANDO QUI ACCETTI QUANTO SOPRA',
    wish: '*DESIDERO ESSERE IL PRIMO A CONOSCERE GLI AVVISI DI TRADING, I PRODOTTI, LA NEWSLETTER E LE OFFERTE SPECIALI',
    policity: '(Private Policy)',
    getstarted: 'Inizia',
    messageFooter1: 'The services and products offered by ',
    messageFooter2: ' are not being offered within the United States (US) and are not being offered to US Persons, as defined under US law. The information on this website is not directed to residents of any country where FX and/or CFDs trading is restricted or prohibited by local laws or regulations. CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. Between 74% - 89% of retail investors’ accounts lose money when trading CFDs. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. 74-89% of retail CFD accounts lose money. For further information regarding our fees please refer to this link.',
    youcan: 'Puoi usare il tuo ',
    freeDemo: 'Conto Demo Gratuito ',
    desYouCan: 'con tutte le nostre piattaforme di trading incluse quella per desktop e mobile per iOS e Android e anche accedere all’',
    client: 'Area Clienti',
    desYouCan2: ', il nostro portale web per la gestione del conto.',
    practice: 'Fai pratica',
    practicedes: 'Fai trading sulle valute in un conto simulato',
    learn: 'Conosci i nostri Strumenti e Servizi',
    learndes: 'Diventa familiare con le piattaforme di trading',
    msgCode1: 'Inserisci il codice di attivazione che è stato inviato alla tua email.',
    msgCode2: 'Assicurati di controllare le tue cartelle di posta indesiderata/spam..',
    sendNewCode: 'Richiedi Nuovo Codice',
    submit: 'Invia',
    formmessage1: 'Ci siamo quasi! Scegli il tipo di conto e',
    formmessage2: 'fornisci alcune informazioni base',
    formemail: '*INDIRIZZO EMAIL',
    formaccounttype: '*TIPO DI CONTO',
    individual: 'Individuale',
    formfirtname: '*NOME',
    formlastname: '*COGNOME',
    formregister: 'Registrati',
    continue: "Success! Please continue registering",
    pleaseReview: "Si prega di rivedere i dati inseriti"
  },

  //Calendar
  calendar:{
    title:'Calendar'
  },

  //MFA
  mfa:{
    title:'Authentication settings',
    subTitle:'This section is not yet available',
    enable:'Attiva autenticazione 2-step',
    body:'In aggiunta alla tua password, imposta un secondo metodo di verifica per tenere il tuo conto ancora più al sicuro.',
    text:"Quando attivi l'autenticazione 2-step, dovrai inserire la tua password oltre ad un codice di verifica che ti verrà inviato sulla email.",
    turn:'Attiva',
    confirm:'Confirm',
    textTwo:'Are you sure you want to disable the 2FA?',
    yes:'Yes',
    no:'No',
    qr:'Please scan the QR',
    type:'Type the generated code:',
    setup: "Setup 2factor",
    insert: "Insert the 2FA code:",
    submit: "Submit",
    error: "Error while enabling 2FA:"
},

//Apllications
applications:{
  title: 'Richieste',
  progress: 'Stato Richieste',

  modify: 'Modifica',
  completed: 'Completato',

  submit: 'Submit',
  next: 'Avanti',
  saved: 'Saved',
  validation: 'Please fill all required fields.',

  personaldetails:
  {
    personal: 'Dettagli Personali',
    title:'*TITOLO',
    mr: 'Sig.',
    mrs: 'Sig.r.',
    dateofbirth: '*DATA DI NASCITA',
    countrybirth: '*NAZIONE DI NASCITA',
    nationality: '*NAZIONALITA’',
    countryresidence: '*NAZIONE DI RESIDENZA',
    statedis: '*REGIONE',
    city: '*CITTA’',
    text: '(testo)',
    streetname: '*INDIRIZZO DI RESIDENZA / NUMERO CIVICO',
    streetnunmber: '*INDIRIZZO DI RESIDENZA / VIA,PIAZZ',
    postcode: '*CODICE POSTALE',
    mobile: '*CELLULARE',
    phone: 'TELEFONO',
    passnumber: '*NUMERO ID/PASSAPORTO',
    passexpiration: '*DATA DI SCANDEZA ID/PASSAPORTO',
    question1: '*Sei un cittadino degli USA  o residente negli USA ai fini fiscsali?',
    question2: '*Sei una persona esposta politicamente (PEP), un familiare vicino o associato a un PEP?',
    yes: 'SI',
    no: 'NO',
    please: '*Per favore, fornisci i dettagli.',
    bytick: '*Cliccando come indicato accetti quanto segue:',
    agree1: 'SONO/SIAMO D’ACCORDO CON TRADEVIEW EUROPE LTD. - TERMS OF BUSINESS INCLUSO GLI ALLEGATI:',
    conflicts: 'CONFLICTS OF INTEREST POLICY',
    and: 'E',
    bets: 'BEST EXECUTION POLICY.',
    agree2: "SONO/SIAMO D’ACCORDO CON TRADEVIEW EUROPE LTD",
    privacy: 'PRIVACY POLICY',
    agree3: "SONO/SIAMO D’ACCORDO DI ESSERE INSERITI NELLE LISTE MARKETING DI TRADEVIEW EUROPE LTD",
  },

  taxresidency:{
    tax: 'Dichiarazione di Residenza Fiscale',
    info: 'Con la presente confermo di essere, ai fini fiscali, un residente nel/i seguente/i paese/i (indica il tipo e il numero di riferimento applicabile in ciascun paese).',
    country: '*NAZIONE',
    referencenumber: '*CODICE FISCALE',
    addcountry: 'Aggiungi Nazione',
    removecountry: 'Rimuovi paese'
  },

  financialinformation:{
    finalcial: 'Informazioni Finanziarie',
    question1: '*Dettagli lavorativi',
    option11: 'IMPIEGATO',
    option12: 'LIBERO PROFESSIONISTA',
    option13: 'PENSIONATO',
    option14: 'DISOCCUPATO',
    option15: 'ALUNNO',
    labeloption11: '*NOME DATORE DI LAVORO',
    labeloption12: '*NATURA DEL LAVORO',
    labeloption13: '*POSIZIONE LAVORATIVA',
    accountancy: 'Contabilità',
    adminsecretarial: 'Amministrazione/Segretariato',
    agriculture: 'Agricoltura',
    bankreatil: 'Banche - Vendita al dettaglio',
    bankInvestment: 'BANCA - INVESTIMENTI',
    catering: 'Catering',
    dentist: 'Dentista',
    doctor: 'Medico',
    education: 'Educazione',
    emergencyservice: 'Servizi di emergenza',
    energyu: 'Energia / Servizi pubblici',
    engineering: 'Ingegneria',
    entretaiment: 'Intrattenimento',
    finalcialadvisor: 'Consulente finanziario',
    financialservices: 'Servizi finanziari',
    armedforces: 'Forze armate',
    hr: 'Risorse umane',
    insurance: 'Assicurazioni',
    it: "Tecnologia dell'informazione",
    legal: 'Legale',
    leisure: 'Tempo libero:',
    manufacturing: 'Manifatturiero',
    marketing: 'Marketing',
    media: 'Media',
    motortrade: 'Commercio automobilistico',
    property: 'Proprietà immobiliari',
    retail: 'Vendita al dettaglio',
    sales: 'Vendite',
    socialservices: 'Servizi sociali:',
    sports: 'Sport/Ricreazione',
    stockbroker: 'Agente di borsa',
    telecomunication: 'Telecomunicazioni',
    transport: 'Trasporti',
    arms: 'Commercio di armi/Difesa',
    carwash: 'Lavaggio auto / Stazione di servizio',
    casino: 'Casinò',
    construction: 'Costruzioni',
    heald: 'Altro settore sanitario',
    mining: 'Estrazione mineraria',
    money: 'Servizio di denaro/trasferimento',
    pharmaceuticals: 'Prodotti farmaceutici',
    precious: 'Metalli preziosi',
    public: 'Appalti pubblici',
    virtual: 'Valute virtuali',
    question2: '*Il tuo Reddito annuale',
    option21: 'SOTTO EUR 10K',
    option22: 'EUR 11K - EUR 30K',
    option23: 'EUR31 - EUR 50K',
    option24: 'EUR 51K - EUR 100K',
    option25: '100K - EUR 200K',
    option26: 'EUR 200K - EUR 500K',
    option27: 'SOPRA EUR 500K',
    question3: '*Il tuo patrimonio totale (risparmi, investimenti, proprietà immobiliari)',
    option31: 'SOTTO EUR 10K',
    option32: 'EUR11K - EUR 30K',
    option33: 'EUR31 - EUR 50K',
    option34: 'EUR 51K - EUR 100K',
    option35: '100K - EUR 200K',
    option36: 'EUR 200K - EUR 500K',
    option37: 'SOPRA EUR 500K',
    question4: '*I tuoi asset liquidi (contanti, strumenti del mercato monetario e titoli negoziabili)',
    option41: 'SOTTO EUR 10K',
    option42: 'EUR11K - EUR 30K',
    option43: 'EUR31 - EUR 50K',
    option44: 'EUR 51K - EUR 100K',
    option45: '100K - EUR 200K',
    option46: 'EUR 200K - EUR 500K',
    option47: 'SOPRA EUR 500K',
    question5: '*Livello anticipato di investimento/trading',
    option51: 'SOTTO EUR 10K',
    option52: 'EUR11K - EUR 30K',
    option53: 'EUR31 - EUR 39K',
    option54: 'EUR 40K - EUR 99K',
    option55: '101K - EUR 200K',
    option56: 'EUR 201K - EUR 500K',
    option57: 'SOPRA EUR 500K',
    questionselect1: '*QUAL’E’ LA PROVENIENZA DEL TUO PATRIMONIO?',
    soursefounds1: 'Risparmi da stipendio',
    soursefounds2: 'Aiuti del governo',
    soursefounds3: 'Vendita',
    soursefounds4: 'Investimenti, dividendi',
    soursefounds5: 'Reddito da un trust',
    soursefounds6: 'Lascito',
    soursefounds7: 'Eredità',
    soursefounds8: 'Donazione',
    soursefounds9: 'Prestito',
    soursefounds10: 'Reddito da lavoro autonomo',
    soursefounds11: 'Genitori, partner o altro',
    soursefounds12: 'Supporto familiare',
    soursefounds13: 'Altro',
    questionselect2: '*QUAL’E’ LA PROVENIENZA DEI TUOI DEPOSITI (ORIGINE DEI FONDI)?',
    question6: '*PLEASE EXPLAIN YOUR SOURCE OF INCOME',
    question7: '*PLEASE EXPLAIN YOUR SOURCE OF INCOME',
    questionselect3: '*CHOOSE A PLATFORM:',
    questionselect4: '*CURRENCY TYPE:',
    question8: '*Sei connesso a bancarotte?',
    yes: 'SI',
    no: 'NO',
    question9: '*CON CHE TIPO DI BANCAROTTE SEI CONNESSO?',
    question10: '*Scopo del conto?',
    option101: 'COPERTURA',
    option102: 'SPECULAZIONE',
    option103: 'TRADING PROPRIETARIO',
    option104: 'INVESTIMENTI LUNGO TERMINE',
    option105: 'ALTRO',
  },

  knowledge:{
    know: 'Conoscenza',
    question1: '*Qual’è il livello di istruzione che hai ottenuto?',
    option11: 'HO UNA LAUREA E/O MASTER E/O PHD MA NON IN FINANZA/ECONOMIA/MATEMATICA',
    option12: 'HO UNA LAUREA/DIPLOMA POST LAUREA IN FINANZA/ECONOMIA/MATEMATICA',
    option13: 'SONO IN POSSESSO DI QUALIFICA PROFESSIONALE',
    option14: 'HO UN LIVELLO SECONDARIO DI ISTRUZIONE',
    option15: 'NON HO UN’ISTRUZIONE FORMALE ',
    question2: '*Hai mai lavorato o lavori nel settore dei servizi finanziari?',
    option21: ' LAVORO O HO LAVORATO COME TRADER O IN AMBITO DI TRADING DI CFD, OTC, STRUMENTI A LEVA NEGLI ULTIMI 5-10 ANNI',
    option22: 'LAVORO O HO LAVORATO COME TRADER O IN AMBITO DI TRADING DI TITOLI; OPZIONI E/O MATERIE PRIME NEGLI ULTIMI 5-10 ANNI',
    option23: 'LAVORO O HO LAVORATO NEI SERVIZI FINANZIARI NON COME TRADER O IN AMBITO DI TRADING NEGLI ULTIMI 2-10 ANNI',
    option24: 'NON LAVORO O NON HO MAI LAVORATO NEI SERVIZI FINANZIARI NEGLI ULTIMI 5-10 ANNI',
    question3: 'Quando i mercati sperimentano "Alta Volatilità"?',
    option31: 'QUANDO CI SONO COMUNICATI STAMPA DI NOTIZIE ECONOMICHE IMPREVISTE O IMPORTANTI',
    option32: 'QUANDO VENGONO PUBBLICATE LE NOTIZIE ECONOMICHE PREVISTE',
    option33: 'QUANDO SI VERIFICA UNA DIMINUZIONE DEL PREZZO DI UN PRODOTTO FINANZIARIO',
    question4: 'Quali delle seguenti sono importanti per ridurre al minimo il rischio nel trading di CFD?',
    option41: 'MONITORAGGIO DELLE POSIZIONI APERTE',
    option42: 'CONOSCERE IL LIVELLO DI LEVA UTILIZZATO',
    option43: 'ENTRAMBE LE DICHIARAZIONI',
    question5: 'Quale tipo di ordine di chiusura può essere usato per controllare l’importo di una potenziale perdita?',
    option51: 'STOP LOSS',
    option52: 'TAKE PROFIT',
    option53: 'NESSUNA DEI DUE',
    question6: '*Quali sono i rischi associati al trading di prodotti a leva?',
    option61: 'NON CI SONO RISCHI',
    option62: 'RISCHIO DI PERDERE I SOLDI INVESTITI',
    option63: 'NESSUNO DEI DUE',
  },

  experience:{
    exp: 'Esperienza',
    description: 'Seleziona una delle opzioni fornite in linea con il tuo background accademico e le tue qualifiche:',
    subtitle1: 'TITOLI IN BORSA/TITOLI TRASFERIBILI',
    question1: "*Anni di esperienza:",
    option11: 'NESSUNOE',
    option12: '0 - 1 ANNI',
    option13: '2-5 ANNI',
    option14: '5-10 ANNI',
    option15: "PIU’ DI 10 ANNI",
    question2a: '*Volume di trading:',
    question2b: '',
    option21: 'NESSUNO',
    option22: 'EUR 0 - EUR 50,000',
    option23: 'EUR 50,001 - EUR 100,000',
    option24: 'EUR 100,001 - EUR 500,000',
    option25: "EUR 500,001 O PIU’",
    question3: '*Frequenza',
    option31: 'NESSUNA',
    option32: 'GIORNALIERA',
    option33: 'SETTIMANALE',
    option34: 'MENSILE',
    option35: 'TRIMESTRALE',
    option36: 'SEMESTRALE',
    subtitle2: 'STRUMENTI A LEVA, CFD & METALLI PREZIOSI',
    question4: '*Anni di esperienza:',
    option41: 'NESSUNO',
    option42: '0 - 1 ANNI',
    option43: '2 - 5 ANNI',
    option44: '5 - 10 ANNI',
    option45: "PIU’ DI 10 ANNI",
    question5: '*Volume di trading:',
    option51: 'NESSUNO',
    option52: 'EUR 0 - EUR 50,000',
    option53: 'EUR 50,001 - EUR 100,000',
    option54: 'EUR 100,001 - EUR 500,000',
    option55: "EUR 500,001 O PIU’",
    question6: 'Frequenza',
    option61: 'NESSUNA',
    option62: 'GIORNALIERA',
    option63: 'SETTIMANALE',
    option64: 'MENSILE',
    option65: 'TRIMESTRALE',
    option66: 'SEMESTRALE'

  },

  verification:{
    idv: 'Verifica ID',
    h1: 'Ci siamo quasi!',
    subtitle1: 'Dobbiamo solo verificare la tua identità',
    description: 'Per approvare il tuo conto siamo obbligati a verificare la tua identità. Ti preghiamo di allegare i seguenti documenti all atua richiesta per la verifica. Tradeview potrebbe richiedere ulteriori informazioni o documenti per conformarsi alle normative AML e CFT.',
    pleaseprovide: 'Si prega di fornire questi documenti:',
    h2a: 'Documento d’Identità con foto rilasciato dal Governo',
    descriptionh2a: 'es. passaporto (consigliato), carta di identità o permesso di sogiorno.',
    h2b: 'Prova di residenza',
    descriptionh2b: 'es. bolletta di utenza, certificato di residenza, estratto conto bancario.',
    h2c: 'Documento d’Identità con foto rilasciato dal Governo',
    descriptionh2c1: 'Si prega di caricare una copia a colori del tuo passaporto (consigliato), carta di identità o permesso di soggiorno.',
    descriptionh2c2: 'Si prega di notare che:',
    descriptionh2c3: 'la foto del documento sia chiara e visibile',
    descriptionh2c4: 'il tuo documento sia rilasciato da un’agenzia governativa',
    descriptionh2c5: 'il tuo nome e cognome, data di nascita e data di scadenza/emissione siano chiaramente indicati',
    descriptionh2c6: 'vengano allegati sia il fronte che il retro del tuo documento come file separati',
    select1: '*TIPO DI DOCUMENTO',
    typeofdocs1: 'Passaporto (consigliato)',
    typeofdocs2: 'Patente di Guida',
    typeofdocs3: 'Carta di Identità',
    typeofdocs4: 'Permesso di Soggiorno',
    h2d: 'Carica il tuo documento:',
    descriptionh2d1: 'Seleziona il FRONTE del tuo documento',
    front: 'FRONTE',
    descriptionh2d2: 'SSeleziona il INDIETRO del tuo documento',
    back: 'INDIETRO',
    description1: 'Ogni file dovrebbe essere in formato JPG, PNG o JPEG, e con dimensioni inferiori a 2MB',
    goback: 'Indietro',
    done: 'Fatto',
    h2e: 'Prova di residenza',
    descriptionh2e1: 'es. bolletta di utenza, certificate di residenza, estratto conto bancario.',
    descriptionh2e2: 'Tutte le prove di residenza devono essere attuali e non più vecchie di 90 giorni',
    descriptionh2e3: 'Usa il documento completo, mostra chiaramente il tuo nome e cognome e l’indirizzo dove risiedi (no Caselle postali)',
    descriptionh2e4: 'Mostra una fornitura di servizi come denaro dovuto o pagato.',
    select2: '*TIPO DI DOCUMENTO',
    proofType1: "Bolletta di utenza",
    proofType2: "Certificato di Residenza",
    proofType3: "Estratto conto bancario",
    h2f: 'Carica il tuo documento:',
    descriptionh2f1: 'Seleziona documento',
    descriptionh2f2: 'Ogni file dovrebbe essere in formato JPG, PNG o JPEG, e con dimensioni inferiori a 2MB',
    description2: "*ACCONSENTO A TRADEVIEW DI VERIFICARE ELETTRONICAMENTE LA MIA IDENTITÀ FORNENDO LE MIE INFORMAZIONI PERSONALI, COMPRESI I DOCUMENTI DI IDENTITÀ, A UN FORNITORE DI SERVIZI DI VERIFICA DELL'IDENTITÀ PER OTTENERE UNA VALUTAZIONE DELLA CORRISPONDENZA ALLE INFORMAZIONI DETENUTE DA LORO O AD ALTRE FONTI DI VERIFICA DELL'IDENTITÀ.",
    uploadDocument: "Caricate il vostro documento.",
    documentCorresponding: "Si prega di caricare il documento corrispondente al documento d'identità con foto del governo e alla prova di residenza.",
    cantAttach: "Non è possibile allegare questa immagine, provare con un'altra."
  },

  acknowledgement:{
    ack: 'Presa Visione',
    description: '*Cliccando sui link ai documenti qui di seguito confermi che hai compreso e sei in accordo con il documento. Dopo aver confermato la domanda, gli accordi e le informative, leggi il paragrafo della firma e utilizza il mouse per firmare il tuo nome.',
    check1: 'CLIENT AGREEMENT',
    check2: 'ELECTRONIC TRADING AGREEMENT',
    check3: 'RISK DISCLOSURE',
    check4: 'PRIVACY POLICY',
    check5: 'CONSENT TO DELIVERY OF STATEMENTS BY ELECTRONIC MEDIA',
    check6: 'COMMISSION AGREEMENT',
    check7: 'TERMS AND CONDITIONS',
    check8: 'BEST EXECUTION POLICY',
    check9: 'CONFLICTS OF INTEREST POLICY',
    check10: 'Key Information Documents (KID):',
    forex: 'FOREX',
    crypto: 'CRYPTOCURRENCY',
    commodities: 'COMMODITIES',
    index: 'INDEX',
    description2: 'Firmando qui di seguito, prendo atto e accetto the terms in the above-listed Client Agreement, Electronic Trading Agreement, Risk Disclosure, Privacy Policy, Consent to Delivery of Statements by Electronic Media, Commission Agreement, Terms and Conditions, Best Execution Policy and Conflicts of Interest Policy. Riconosco e accetto inoltre, sotto pena di falsa testimonianza, che tutte le informazioni fornite nella domanda sono veritiere e accurate e tutti i depositi sul mio conto, indipendentemente dalla fonte di origine, dal tipo di valuta o dal metodo di deposito, non sono proventi di attività criminali o attività illecite e derivano da fonti legittime.',
    authorized: 'FIRMA',
    signature: 'AUTORIZZATA, FIRMA QUI SOPR',
    confirm: 'Conferma Firma',
    clean: 'Cancella Firma',
    hasNot: 'Non ha firmato'
  },

  riskdisclosure:{
    add: 'Divulgazione di Rischi Aggiuntiva',
    description: "Le informazioni fornite nella tua domanda di apertura dell'account indicano che non soddisfi le linee guida di Tradeview Europe Ltd. per aprire un conto di trading per una delle seguenti ragioni:",
    list1: "Non hai un'età compresa tra i 21 e i 63 anni.",
    list2: 'Il tuo reddito annuo è inferiore a 10.000 EUR.',
    list3: "Hai presentato una domanda di fallimento nell'ultimo anno.",
    list4: 'Non sei impiegato.',
    description1: "Sebbene Tradeview Europe Ltd. sia disposta ad aprire il tuo conto, è necessario avvisarti di riconsiderare questo investimento. Sulla base delle tue informazioni personali e/o esperienza di investimento, il forex potrebbe essere una strategia di investimento troppo rischiosa. Le perdite nel trading forex possono essere consistenti. Pertanto, dovresti valutare attentamente se tale tipo di trading sia adatto a te alla luce della tua situazione e delle tue risorse finanziarie. L'alto grado di leva finanziaria ottenibile nel trading forex a causa dei piccoli requisiti di margine può essere sia a tuo favore che contro di te. L'uso della leva finanziaria può portare a perdite così come a guadagni.",
    description2: "*CAPISCO CHE NON RISPONDO AI REQUISITI MINIMI PER APRIRE UN CONTO, COME INDICATO DA TRADEVIEW EUROPE LTD. NEI PARAGRAFI SOPRA. TUTTAVIA, HO CONSIDERATO I RISCHI FINANZIARI CONNESSI AL TRADING FOREX IN RELAZIONE ALLA MIA SITUAZIONE PERSONALE E DESIDERO PROCEDERE CON L'APERTURA DI UN CONTO.",
    description3: '*CONFERMO DI AVER LETTO QUANTO SOPRA E DI AVERE COMPRENSO APPIENO LE INFORMAZIONI QUI FORNITE.',
  },

  confirmation:{
    subtitle1: 'Sei a un solo clic di distanza dal presentare la tua candidatura',
    description: 'Se desideri rivedere o modificare le informazioni inserite in precedenza, puoi selezionare il link ',
    modify: '"Modifica"',
    description2: ' in ogni sezione. Se sei pronto per inviare la tua candidatura ora, clicca semplicemente sul pulsante di invio.',
    subapplication: 'Invia Candidatura',
    congratulation: 'Congratulazioni',
    descriptionmodal: 'Grazie per la tua candidatura. I documenti caricati sono attualmente in fase di revisione e verranno verificati a breve. Il nostro team operativo ti contatterà qualora avessimo bisogno di ulteriori documenti.',
    close: 'Chiudi',
    your: 'Your', // falta
    account: 'account', //falta
    description3: 'Request is being processed. Final status will be notified at your email.' //falta
  }
}

}
