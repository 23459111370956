import { createI18n } from 'vue-i18n'

import messages from './messages'

//Variable para capturar el idioma del navegador || Variable to capture the language of the browser
let navigatorLanguage = navigator.language.substring(0, 2) || navigator.userLanguage.substring(0, 2);
//Variable para obtener idioma guardado en el localestorage, en el caso que esta no exista toma el idioma del navegador || Variable to obtain language saved in the localstorage, in the event that this does not exist, it takes the language of the browser
let defaultLang = localStorage.getItem("language") || navigatorLanguage || "en";

export const i18n = createI18n({
    locale: defaultLang,
    fallbackLocale: 'en',
    messages: messages,
    fallbackWarn: false,
    missingWarn: false,
    warnHtmlMessage: false,
})
