<template>
    <transition v-if="showModal" name="fade">
        <div class="preloader">
            <div class="preLogo">
                <!-- <img src="/assets/images/logo_tradeview_full_dark.svg" alt=""> -->
                <!-- <p>Loading. . .</p> -->
                <img class="svgAnimated" src="/assets/images/Logo_preloader_300x300.gif" alt="">
            </div>
            <!-- <p>Mensaje</p> -->
        </div>
    </transition>
</template>

<script>
export default {
    name: 'preloader',
    props: [
        'showModal'
    ],
    emits: ['showToggle'],
    data() {
        return {
            show: true
        }
    },
    mounted() {
        // this.showToggle()
        // console.log('preloader...........')
    },
    methods: { 
        showToggle(){
            emit('showToggle', props.name)
            // setTimeout(() => {
            //     this.show = false
            // },2000)
        }
    },
    
}
</script>

<style scoped>
.preloader{
    display: flex;
    flex-direction: colum;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #000000d4;
    z-index: 9999;
    top: 0px;
    left: 0px;
    /* backdrop-filter: blur(2px); */
}

.preloader .preLogo{
    /* background:url(/assets/images/preloader2.svg); */
    display: flex;
    width: 100px;
    max-width: 100px;
    min-width: 100px;
    height: auto;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.preloader .preLogo .svgAnimated{
    width: 100%;
    margin-top: -100px;
}

.preloader p{
    font-size: 1.5rem;
    color: white;
    margin-top: 20px;
    margin-bottom: 10px;
}

.fade-enter-active , .fade-leave-active{
    transition: opacity 1s;
}

.fade-enter-active .preLogo, .fade-leave-active .preLogo{
    animation-name: scaleOut;
    animation-duration: 1s;
}

.fade-enter-active p, .fade-leave-active p{
    animation-name: scaleOut;
    animation-duration: 1s;
}

.fade-enter, .fade-leave-to{
    opacity: 0;
}

</style>