/* eslint-disable */

import { createRouter, createWebHistory } from 'vue-router'
import {Auth} from "@aws-amplify/auth";
import store from "@/store";

const CabinetLogin = () => import('../pages/login/Login')
const CabinetRegister = () => import('../pages/login/Register')

/*import auth from '../app/auth';
import LogoutSuccess from '@/components/LogoutSuccess';
import UserInfoStore from '../app/user-info-store';
import UserInfoApi from '../app/user-info-api';
import ErrorComponent from '@/components/Error';*/

const Default = () => import("../layouts/cabinet/Default");

const NotFound = () => import('../pages/404');

const CabinetConsole = () => import('../pages/cabinet/Console');
const CabinetApplicationsV3 = () => import('../pages/cabinet/ApplicationsV3');

const CabinetAccounts = () => import('../pages/cabinet/Accounts');
const CabinetPlatforms = () => import('../pages/cabinet/Platforms');
const CabinetHistory = () => import('../pages/cabinet/History');
const CabinetDeposit = () => import('../pages/cabinet/Deposit');
const CabinetTransfer = () => import('../pages/cabinet/Transfer');
const CabinetWithdraw = () => import('../pages/cabinet/Withdraw');
const CabinetCalendar = () => import('../pages/cabinet/Calendar');
const CabinetPassword = () => import('../pages/cabinet/Password');
const CabinetLanguage = () => import('../pages/cabinet/Language');
const CabinetEmail = () => import('../pages/cabinet/Email');
const CabinetMFA = () => import('../pages/cabinet/MFA');
const CabinetCalculator = () => import('../pages/cabinet/Calculator');
const CabinetContactUs = () => import('../pages/cabinet/ContactUs');

const ChooseBanks = () => import('../pages/cabinet/ChooseBanks');
const ChooseAlternativeMethods = () => import('../pages/cabinet/ChooseAlternativeMethods');
const AddFunds = () => import('../pages/cabinet/AddFunds');
const AddBankFunds = () => import('../pages/cabinet/AddBankFunds');
const AddCreditCardFunds = () => import('../pages/cabinet/AddCreditCardFunds');
const ChooseCreditCard = () => import('../pages/cabinet/ChooseCreditCard');
const NewLiveAccount = () => import('../pages/cabinet/NewLiveAccount');
const NewDemoAccount = () => import('../pages/cabinet/NewDemoAccount');
const AccountActivity = () => import('../pages/cabinet/AccountActivity');
const TradingHistory = () => import('../pages/cabinet/TradingHistory');
const WebTerminalMT4 = () => import('../pages/cabinet/WebTerminalMT4');
const WebTerminalMT5 = () => import('../pages/cabinet/WebTerminalMT5');
const Platforms = () => import('../pages/cabinet/Platforms');

async function requireAuth(to, from, next) {
    // console.log('---> requires auth')
    // console.log(store.state.loginEvent)
    await Auth.currentAuthenticatedUser().then(data => {
        // console.log(data)
        next();
    }).catch((e) => {
        // console.log(e)
        // console.log(from)
        // console.log(to)
        next({
            path: '/login',
            // query: {
            //     redirect: to.fullPath,
            // }
        });
    });
}

const router = createRouter({
    mode: 'history',
    base: '/',
    history: createWebHistory(),
    linkActiveClass: 'open active',
    scrollBehavior: () => ({y: 0}),
    routes: [
        {
            path: '/',
            redirect: '/login'
        },
        {
            path: '/register',
            redirect: '/register/1/live-account'
        },
        {
            path: '/register/:id/:account',
            name: 'Register',
            component: CabinetRegister
        },
        {
            path: '/login',
            name: 'Login',
            component: CabinetLogin
        },
        {
            path: '/cabinet',
            name: 'Cabinet',
            // component: {
            //     name: 'Cabinet',
            //     render(c) {
            //         return c('router-view')
            //     }
            // },
            component: Default,
            children: [
                {
                    path: 'console',
                    name: 'Console',
                    component: CabinetConsole,
                    children: [
                        {
                            path: 'applications',
                            name: 'Applications',
                            component: CabinetApplicationsV3,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'accounts',
                            name: 'Accounts',
                            component: CabinetAccounts,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'accounts/:type',
                            name: 'Accountst',
                            component: CabinetAccounts,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'platforms',
                            name: 'Platforms',
                            component: CabinetPlatforms,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'history',
                            name: 'History',
                            component: CabinetHistory,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'deposit',
                            name: 'Deposit',
                            component: CabinetDeposit,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'transfer',
                            name: 'Transfer',
                            component: CabinetTransfer,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'withdraw',
                            name: 'Withdraw',
                            component: CabinetWithdraw,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'calendar',
                            name: 'Calendar',
                            component: CabinetCalendar,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'calculator',
                            name: 'Calculator',
                            component: CabinetCalculator,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'password',
                            name: 'Password',
                            component: CabinetPassword,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'language',
                            name: 'Language',
                            component: CabinetLanguage,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'email',
                            name: 'Email',
                            component: CabinetEmail,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'mfa',
                            name: 'MFA',
                            component: CabinetMFA,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'contact_us',
                            name: 'Contact Us',
                            component: CabinetContactUs,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'choose_banks',
                            name: 'Choose Banks',
                            component: ChooseBanks,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'choose_alternative_methods',
                            name: 'Choose Alternative Methods',
                            component: ChooseAlternativeMethods,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'add_funds/:target',
                            name: 'Add Funds',
                            component: AddFunds,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'add_bank_funds/:target',
                            name: 'Add Bank Funds',
                            component: AddBankFunds,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'add_credit_card_funds/:target',
                            name: 'Add Credit Card Funds',
                            component: AddCreditCardFunds,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'choose_credit_card',
                            name: 'Choose Credit Card',
                            component: ChooseCreditCard,
                            beforeEnter: requireAuth
                        },
                        {
                            path: 'create_new_live_account',
                            name: 'New Live Account',
                            component: NewLiveAccount,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'create_new_demo_account',
                            name: 'New Demo Account',
                            component: NewDemoAccount,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'account_activity',
                            name: 'Account Activity',
                            component: AccountActivity,
                            beforeEnter: requireAuth,
                        },
                        {
                            path: 'trading_history',
                            name: 'Trading History',
                            component: TradingHistory,
                            beforeEnter: requireAuth,
                        },
                    ]
                },
                {
                    path: 'web_terminal_mt4',
                    name: 'Web Terminal MT4',
                    component: WebTerminalMT4,
                    beforeEnter: requireAuth,
                },
                {
                    path: 'web_terminal_mt5',
                    name: 'Web Terminal MT5',
                    component: WebTerminalMT5,
                    beforeEnter: requireAuth,
                },
            ],
        },
        {
            path: '/:pathMatch(.*)',
            name: '404',
            // path: '/404',
            component: NotFound,
        }
    ]
});

export default router;
