export default {
  //login home page
  loginHeader: {
    contactUs: 'Contact us'
  },
  loginPage: {
      instructionstitle: 'Client Cabinet',
      instructionsLine1: 'Please log in using your e-mail and the password you created for your Tradeview Account.',
      instructionsLine2: 'In the Client Cabinet you will be able to:',
      featureList1: 'Check your account summary',
      featureList2: 'Access your account history',
      featureList3: 'Fund your account',
      featureList4: 'Transfer funds between accounts',
      featureList5: 'Request a withdrawal from your account',
      pFooter: "The services and products offered by Tradeview Europe Ltd. are not being offered within the United States (US) and are not being offered to US Persons, as defined under US law. The information on this website is not directed to residents of any country where FX and/or CFDs trading is restricted or prohibited by local laws or regulations. CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. 68.3% of retail investors' accounts lose money when trading CFDs with Tradeview Europe Ltd. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. For further information regarding our fees please refer to this ",
      linkFooter: 'link.',
      adressOficce: 'Tradeview Europe Office:',
      termsAndConditions: 'Terms and Conditions',
      privacyPolicy: 'Privacy Policy',
      amlPolicy: 'AML Policy',
      refundPolicy: 'Refund Policy',
      riskDisclosure: 'Risk Disclosure',
      complaintsProcedure: 'Complaints Procedure',
      cookiePolicy: 'Cookie Policy',
      changeSucces: 'Change was successful!',
      checkEmail: 'Check your email for instructions',
      code: 'code',
      newPassword: 'new password',
      repeatPassword: 'repeat password',
      changePassword: 'change password'
  },
  loginBox: {
      logInGoogle: 'Log in with Google',
      logInFacebook: 'Log in with Facebook',
      or: 'Or',
      email: 'Email',
      password: 'Password',
      submit: 'Access',
      forgot: 'Forgot password?',
      doNotAccount: "Don't have an account?",
      signUp: 'Sign Up',
      tryFreeDemo: 'Try a Free Demo',
      showDialog1: 'Please enter a valid email/password',
      changePass: 'Change was successful!'
  },
  consoleHeader: {
      account: 'Account #',
      balance: 'Balance',
      equity: 'Equity',
      free: 'Free Margin',
      margin: 'Margin Levelator',
      status: 'Status',
      hello: 'Hello',
      logout: 'Log out',
      confirm: 'Confirm',
      outModal: 'Are you sure you want to logout?',
      yes: 'Yes',
      not: 'Not'
  },

  // Platforms page
  platforms:{
      title: 'Download Platforms',
      accountLive: 'Server Live Account:',
      accountDemo: 'Server Demo Account:'
  },

  history:{
    title: 'History',
    accountActivity: 'Account Activity',
    tradingHistory: 'Trading History'
  },

  // Withdraw page
  withdraw:{
      title: 'Withdraw',
      platform: 'Platform',
      sourceAccount: 'Source account*',
      method: 'Method*',
      currency: 'Currency*',
      start: 'Withdraw now',
      beneficiary: '*Beneficiary Name',
      details: 'Beneficiary Bank Details',
      number: '*Account Number (IBAN if applicable)',
      code:'*Swift/Code Bank',
      reference: 'Reference',
      subTitle: 'Intermediary Bank Details (If Needed)',
      name_benef:'*Bank Name',
      bank: 'Bank Address',
      swift: 'Bank SWIFT',
      branch: 'Branch Name',
      province: 'Province',
      city:'City',
      dialogShow:  'Error! this platform has no active accounts you cannot continue with the process, try another platform.',
      errorOne: 'Error! There cannot be empty fields',
      errorTwo: 'Error! The value exceeds your account balance',
      errorThree: 'Error! the email is invalid',
      errorFour: 'Error! for credit card only numeric character',
      dialogShowTwo: "Request Submited!, Please check your Email",
      dialogShowThree:  "Please Contact Tradeview Support",
      emailAddress: "Email Address of Credit Card Account",
      creditcard:"Credit Card (Last 4 digits)",
      netellerEmail: "Neteller Email Account",
      netellerNumber: "Neteller Account Number",
      skrillEmail: "Skrill Email Account",
      skrillNumber: "Skrill Account Number",
      upholdEmail: "Uphold Email Account",
      upholdNumber: "Uphold Account Number",
  },

  //Account Activity page
  accountActivity:{
      title: 'Account Activity',
      from: '*From',
      to:'*To',
      type:'*Type',
      platform:'*Platform',
      placeholderAccount: 'Trading account',
      account: '*Account',
      get: 'Get',
      row:'Rows for page',
      download:'Download Report',
      back:'Back',
      confirm: 'Confirm',
      close:'Close',
      error: 'Error! There cannot be empty fields',
      errorTwo: 'Error! you must select the field type.',
      activityid: 'Activity ID ',
      platfomr: 'Platform',
      date: 'Date / Time',
      activity: 'Activity',
      profit: 'Profit/Loss',
  },


  //tradingHistory
  tradingHistory:{
      title: 'Trading History',
      from: '*From',
      to:'*To',
      type:'Type',
      platform:'Platform',
      placeholderAccount: 'Trading account',
      account: '*Account',
      get: 'Get',
      row:'Rows for page',
      download:'Download Report',
      back:'Back',
      confirm: 'Confirm',
      close:'Close',
      error: 'Error! There cannot be empty fields',
      errorTwo: 'Error! you must select the field type.',
      deal:'Deal',
      entryTime: 'Entry Time',
      typr: 'Type',
      simbol: 'Symbol',
      volume:'Volume',
      entryPrice: 'Entry Price',
      sl: 'S/L',
      tp: 'T/P',
      exitTime: 'Exit Time',
      exitPrice: 'Exit Price',
      commision:'Commision',
      swap: 'Swap',
      profit: 'Profit/Loss',
      live: 'Live',
      demo: 'Demo'
  },

  newLiveAccount:{
      title:'Create New Live Account',
      platform:'*Select a Platform',
      currency:'*Currency',
      account: 'Create Account',
      back:'Back',
      congratulation:'Congratulations',
      text:'Your Live Account Application is under review and will be verified shortly. Our operations team will be in contact with you in case we require any further documentation.',
      error:'Error! There cannot be empty fields',
      close:'Close',
  },

  //Transfer page
  transfer:{
      title: 'Transfer between your trading accounts',
      subTitle: 'In order to transfer funds from one Tradeview account to another, fill out the following form.',
      platform: 'Platform',
      sourceAcount: 'Source account',
      targetAccount: 'Target Account',
      sendingAmount: 'Sending Amount',
      condition: 'By checking the checkbox I understand that I have requested funds to be transferred from one Tradeview Europe Ltd. account to another of my own Tradeview Europe Ltd. accounts.',
      start: 'Transfer',
      showDialogOne: 'Please Contact Tradeview Support',
      showDialogTwo:'Please Contact Tradeview Support',
      submited: 'Request Submited!, Please check your Email',
      error:'Error! There cannot be empty fields',
      errorTwo:'Error! Account numbers must be different',
      errorThree:'Error! The amount is not valid',
      errorFour:'Error! The value exceeds your account balance'
  },

  //Accounts
  accounts:{
      warning:'Your Demo Account Request is being processed. Final status will be notiﬁed at your email.',
      live:'Live Trading Accounts',
      demo:'Demo Accounts',
      createLive:'+ Create a New Live Account',
      createDemo:'+ Create a New Demo Account',
      noAccountLive: 'No Live Account under email',
      noAccountDemo: 'No Demo Account under email',
      dialogShowOne: 'You currently have no Metatrader 4 demo accounts.',
      dialogShowTwo: 'You currently have no Metatrader 5 demo accounts.',
      dialogShowThree: 'You currently have no Metatrader 4 live accounts.',
      dialogShowFour: 'You currently have no Metatrader 5 live accounts.',
      account: 'ACCOUNT #',
      balance: 'BALANCE',
      equity: 'EQUITY',
      margin:'MARGIN LEVEL',
      free: 'FREE MARGIN',
      status: 'STATUS',
      leverage:'LEVERAGE',
      currency: 'CURRENCY'
  },

  //CreateDemoAccounts
  demoAccounts:{
      title:'Create New Demo Account',
      platform:'* Select a Platform',
      type:'* Account Type',
      amount:'* Deposit Amount',
      currency: '* Currency',
      account: 'Create Account',
      back:'Back',
      created:'Your Demo Account has been created',
      text:'Please save your credentials before clicking any option or closing this window, these will NOT be sent to your email.',
      login: 'Login',
      pass:'Password',
      currencyTwo:'Currency',
      server:'Server',
      data:'TradeviewEurope-Demo Europe',
      download:"Now you're ready to download your trading platform:",
      close:'Close',
      error:'Error! There cannot be empty fields',
      professional: 'Professional',
      retail: 'Retail',
      stocks: 'Stocks'
  },

  //credit card
  credit:{
      title: 'Credit cards',
      subTitle: 'This section is not yet available',
      subTitleAdd: 'Deposit with credit card',
      platform: 'Platform',
      trading: 'Trading account',
      amount:'* Amount (Min. 100 USD - max. 10.000 USD)',
      currency: '* Currency',
      details: 'Credit Card Details',
      cardName: '* Card Name Holder',
      cardNumber: '* Card Number',
      expiration: 'Expiration date',
      fund: 'Continue',
      disclaimer: 'Payments are only accepted from an account/card registered in your name. Please note that we may request additional documents and/or information for verification purposes.',
      back: 'back',
      textOne: 'Confirm your intention to deposit',
      textTwo: ' using your Credit Card',
      cancel: 'Cancel',
      confirm: 'Confirm',
      deposit: 'Deposit',
      ok: 'Ok',
      depositUrl: 'Redirect to Zotapay',
      errorOne:'Error! Amount (Min. 100 USD - max. 10,000 USD)',
      errorTwo:'Error! There cannot be empty fields',
      errorThree:'Error! Is not a valid security code',
      errorFour:'Error! Is not a valid expiration date',
      errorFive:'Error! Is not a valid card number',
  },


  //Banck Transfer add found Skrill
  addFounds:{
    title:'Deposit with Digital wallets',
    platform: 'Platform',
    account:'Trading account',
    currency:'Currency',
    amount:'Amount (Min. 100 USD - max. 10,000 USD)',
    email: 'Correo Electrónico',
    disclaimer:'Payments are only accepted from an account/card registered in your name. Please note that we may request additional documents and/or information for verification purposes.',
    fund:'Continue',
    back:'Back',
    redi:'Redirecting',
    redirection:'Redirecting to',
    checkmark:'By checking the box you agree on all Tradeview Europe Ltd. policies.',
    submit:'Submit',
    contactSupport: 'Error. Please Contact Tradeview Support'
},

 //Add Bank Funds
 addBanckFounds:{
    title:'Add funds',
    description:'Please use this information for Incoming Wires:',
    back:'Back',
    headers1: 'For USD Deposits',
    rows1Headers1: 'Beneficiary Bank:',
    subrows1Headers1:'BMO Harris Bank, N.A',
    rows2Headers1:  'Beneficiary Swift:',
    subrows2Headers1: 'HATRUS44',
    rows3Headers1:  'ABA No:',
    subrows3Headers1: '071000288',
    rows4Headers1:  'Reference:',
    subrows4Headers1: '(Your Name + Tradeview Account No.)',
    rows5Headers1:  'Account name:',
    subrows5Headers1: 'Tradeview Europe Limited',
    rows6Headers1:  'Account number:',
    subrows6Headers1: '4243275',
    rows7Headers1:  'Bank Address:',
    subrows7Headers1: '111 West Monroe St, Chicago, IL 60690',
    rows8Headers1:  'Beneficiary Address:',
    subrows8Headers1: '114/3 The Strand, Gzira, GZR 1027, Malta',
    headers2: 'For EUR Deposits',
    rows1Header2: 'Beneficiary Bank',
    subrows1Header2: 'BMO Harris Bank, N.A',
    rows2Header2: 'Beneficiary Swift',
    subrows2Header2: 'HATRUS44GTM',
    rows3Header2: 'Intermediary Bank',
    subrows3Header2: 'Deutsche Bank - Frankfurt',
    rows4Header2: 'Intermediary Swift',
    subrows4Header2: 'DEUTDEFF',
    rows5Header2: 'Reference:',
    subrows5Header2: '(Your Name + Tradeview Account No.)',
    rows6Header2: 'Account name',
    subrows6Header2: 'Tradeview Europe Limited',
    rows7Header2: 'Account number',
    subrows7Header2: '300894250262',
    rows8Header2: 'Bank Address',
    subrows8Header2: '111 West Monroe St, Chicago, IL 60603',
    rows9Header2: 'Beneficiary Address',
    subrows9Header2: '114/3 The Strand, Gzira, GZR 1027, Malta',
    headersSecond:'For GBP, EUR, USD, CHF and JPY Deposits',
    rows1HeadersSecond: 'Beneficiary Bank:',
    subrows1HeadersSecond: 'The Currency Cloud Limited',
    rows2HeadersSecond: 'Beneficiary Swift:',
    subrows2HeadersSecond: 'TCCLGB3L',
    rows3HeadersSecond: 'IBAN No:',
    subrows3HeadersSecond: 'GB62TCCL04140476597833',
    rows4HeadersSecond: 'Reference:',
    subrows4HeadersSecond: '210408-56275 + Your Name and Tradeview Account No.',
    rows5HeadersSecond: 'Account name:',
    subrows5HeadersSecond: 'Tradeview Europe Limited',
    rows6HeadersSecond: 'Account Number:',
    subrows6HeadersSecond: '4243275',
    rows7HeadersSecond: 'Bank Address:',
    subrows7HeadersSecond: '12 Steward Street, The Steward Building, London, E1 6FQ',
    rows8HeadersSecond: 'Beneficiary Address:',
    subrows8HeadersSecond: '114/3 The Strand, Gzira, GZR 1027, Malta',
    headersSecond2: 'For EUR, CHF, DKK, GBP, HUF, NOK, PLN, USD Deposits',
    rows1HeadersSecond2: 'Account Name:',
    subrows1HeadersSecond2: 'Tradeview Europe Limited',
    rows2HeadersSecond2: 'Currency:',
    subrows2HeadersSecond2: 'EUR, CHF, DKK, GBP, HUF, NOK, PLN, USD',
    rows3HeadersSecond2: 'IBAN:',
    subrows3HeadersSecond2: 'DK5789000051025190',
    rows4HeadersSecond2: 'BIC:',
    subrows4HeadersSecond2: 'SXPYDKKK',
    rows5HeadersSecond2: 'Reference:',
    subrows5HeadersSecond2: '(Your Name + Tradeview Account No.)',
    rows6HeadersSecond2: 'Bank Name:',
    subrows6HeadersSecond2: 'The Currency Cloud Limited',
    rows7HeadersSecond2: 'Bank Address:',
    subrows7HeadersSecond2: 'AMERIKA PLADS, 38 COPENHAGEN 2100 Denmark',
    rows8HeadersSecond2: 'Beneficiary Address:',
    subrows8HeadersSecond2: '114/3 The Strand, Gzira, GZR 1027, Malta',
    headers3: 'For GBP Deposits',
    rows1Headers3: 'Beneficiary Bank:',
    subrows1Headers3:'BMO Harris Bank, N.A.',
    rows2Headers3:  'Beneficiary Swift:',
    subrows2Headers3: 'HATRUS44GTM',
    rows3Headers3:  'Account Name:',
    subrows3Headers3: 'Tradeview Europe Limited',
    rows4Headers3:  'Account Number:',
    subrows4Headers3: '777554250262',
    rows5Headers3:  'Intermediary Bank:',
    subrows5Headers3: 'Royal Bank of Scotland- London',
    rows6Headers3:  'Intermediary Swift:',
    subrows6Headers3: 'NWBKGB2L'
},

//Choose bank
chooseBank:{
    title:'Choose bank',
    method:'FUNDING METHOD',
    processing:'PROCESSING TIME',
    fee:'FEE',
    back:'Back',
    instruction:"Losses can exceed initial amount invested. Third party payments will NOT be accepted. All deposits MUST be from the original account owner. The trading platform's denomination is in USD. Tradeview Europe Ltd. reserves the right to bookback/refund any deposits made via Credit Card in lieu of a withdrawal."
},

//Choose Credit Card
ChooseCreditCard:{
    title:'Choose Credit Card',
    method:'FUNDING METHOD',
    processing:'PROCESSING TIME',
    fee:'FEE',
    back:'Back',
    messageZotapay: 'Zotapay is temporarily unavailable',
    instructionOne:"Tradeview Europe Ltd. is always looking to improve our customer's experience. For this reason, we provide our clients with fast and effective funding methods. If needed please",
    contact:'contact',
    instructionTwo:'our support team who will gladly help expedite your request.',
    instructionThree:"Third-party payments will NOT be accepted. All deposits MUST be from the original account owner. The trading platform's denomination is in euros or in USD. Tradeview Europe Ltd. reserves the right to book back/refund any deposits made via Credit Card in lieu of a withdrawal."
},

//Choose alternative methods
ChooseAlternativeMethods:{
    title:'Choose alternative methods',
    method:'FUNDING METHOD',
    processing:'PROCESSING TIME',
    optionProcessing: 'Instant 24/7',
    fee:'FEE',
    back:'Back',
    instructionOne:"Losses can exceed initial amount invested. Third party payments will NOT be accepted. All deposits MUST be from the original account owner. The trading platform's denomination is in USD. Tradeview Europe Ltd. reserves the right to bookback/refund any deposits made via Credit Card in lieu of a withdrawal."
},


  //deposit
  deposit:{
      title: 'Choose deposit method',
      vendor: 'FUNDING METHOD',
      processingCards: 'PROCESSING TIME',
      fee: 'FEE',
      methodOne: 'Credit cards',
      methodTwo: 'Alternative methods',
      methodThree: 'Bank transfer',
      textOne:"Tradeview Europe Ltd. is always looking to improve our customer's experience. For this reason, we provide our clients with fast and effective funding methods. If needed please",
      textTwo:' contact ',
      textThree:'our support team who will gladly help expedite your request.',
      textFour: "Third-party payments will NOT be accepted. All deposits MUST be from the original account owner. The trading platform's denomination is in euros or in USD. Tradeview Europe Ltd. reserves the right to book back/refund any deposits made via Credit Card in lieu of a withdrawal.",
  },

  //Email
  email:{
      title: 'Email settings',
      subTitle: 'Manage your subscriptions here. Select the topics you would like to receive branded newsletters from:',
      company: 'Company news',
      selectOne: 'Trading news (',
      selectTwo: "Surf's Up!",
      selectThree: 'newsletter)',
      update:'Update',
      instructions: 'If you no longer want to receive emails on any of the above topics, just uncheck them. Do not forget to click update.',
      confirm: 'confirm',
      close: 'close',
      resqSubmited: 'Request Submited!'
  },

  //password
  password:{
      title:'Change Password',
      subTitle: 'To change your password please request a one-time code to your email account.',
      request:'Request Code',
      submit:'Submit',
      code:'Code',
      current:'Current password',
      newPassword: 'New password',
      repeat:'Repeat password',
      save: 'Save changes',
      confirm: 'Confirm',
      close:'Close',
      dialogMessage: 'A one-time code was sent to your registered email.',
      dialogMessageOne:'Please type the current password',
      dialogMessageTwo:'Please specify a new password',
      dialogMessageThree:'Please confirm the new password',
      dialogMessageFour:"New/confirm passwords don't match",
      success: "Success!"
  },

  //Contact
  contact:{
      title: 'Request a call',
      firstname: 'YOUR FIRST NAME*',
      lastname: 'YOUR LAST NAME*',
      phone: 'YOUR TELEPHONE NUMBER*',
      email: 'YOUR EMAIL*',
      lenguage: 'SUPPORT LANGUAGE*',
      call: 'PREFERRED TIME TO CALL',
      check: 'Call me as soon as possible',
      contactme:'Contact me',
      error: 'Error! There cannot be empty fields',
      close: 'Close',
      resqSubmited: 'Request Submited! Please check you email'
  },

  menu:{
    applications: 'Applications',
    account: 'Accounts',
    platforms: 'Platforms',
    history: 'History',
    deposit: 'Deposit',
    transfer: 'Transfer',
    withdraw: 'Withdraw',
    settings: 'Settings',
    passwordsettings: 'Password settings',
    emailsettings: 'Email settings',
    authenticationsettings: 'Authentication settings',
    contactUs: 'Contact us'
  },

  //register
  register:{
    title: 'Account Registration',
    create: 'Create your login',
    createdes: 'Enter your email and choose a password to get started',
    complete: 'Complete your application',
    completedes: 'Complete our fast and secure application form',
    confirm: 'Confirm your ID',
    confirmdes: 'Verify your identity online or upload your ID documents if required',
    fund: 'Fund and trade',
    funddes: 'Once approved, fund your account using our flexible, low-cost funding methods and start trading',
    goBack: 'Go back',
    getYourLive: 'Get your Live Account now!',
    getYourDemo: 'Get your Free Demo Account now!',
    instantly: 'Instantly register:',
    registerGoogle: 'Register with Google',
    registerFacebook: 'Register with Facebook',
    or: 'OR',
    email: '*EMAIL ADDRESS',
    secure: '*SECURE CLIENT PASSWORD',
    length: '(Length greater than or equal to 6 characters, including numbers and special characters)',
    confirmPassword: '*CONFIRM PASSWORD',
    disclaimer: 'DISCLAIMER:',
    disclaimerdes1: ' Before opening an account it is our obligation at ',
    disclaimerdes2: " to ensure that You or the person/s appointed to trade on your behalf have the necessary knowledge and experience in CFD and related financial instruments. It is also our requirement to comply with AML and CFT obligations. In this regard you are kindly requested to provide accurate, complete and factual information as requested in line with this application and in order for us to complete compliance with the above obligations. On the basis of this information we are able to properly determine whether the investment services in connection with the financial instrument/s is/are appropriate to You. All Sections need to be completed. For further details please refer to our ",
    clientAgree: 'Client Agreement.',
    byTicking: '*BY TICKING THIS YOU AGREE TO THE ABOVE',
    wish: '*I WISH TO BE THE FIRST TO KNOW ABOUT TRADING SETUP ALERTS, PRODUCTS, NEWSLETTERS, AND SPECIAL OFFERS',
    policity: '(PRIVACY POLICY)',
    getstarted: 'Get started',
    messageFooter1: 'The services and products offered by ',
    messageFooter2: " are not being offered within the United States (US) and are not being offered to US Persons, as defined under US law. The information on this website is not directed to residents of any country where FX and/or CFDs trading is restricted or prohibited by local laws or regulations. CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. 68.3% of retail investors' accounts lose money when trading CFDs with Tradeview Europe Ltd. You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money. For further information regarding our fees please refer to this link.",
    youcan: 'You can use your ',
    freeDemo: 'Free Demo Account ',
    desYouCan: 'with all of our trading platforms including desktop and mobile for iOS and Android and also access to',
    client: 'Client Cabinet',
    desYouCan2: ', our web-based account management portal.',
    practice: 'Practice',
    practicedes: 'Trade virtual currency in a simulated account',
    learn: 'Learn our Tools and Services',
    learndes: 'Become familiar with the software platforms',
    msgCode1: 'Please enter the activation code that was sent to your email.',
    msgCode2: 'Make sure to check your spam/junk folders.',
    sendNewCode: 'Send new code',
    submit: 'Submit',
    formmessage1: 'Almost there! Select your account type and',
    formmessage2: 'provide some basic information',
    formemail: '*EMAIL ADDRESS',
    formaccounttype: '*ACCOUNT TYPE:',
    individual: 'Individual',
    formfirtname: '*FIRST NAME',
    formlastname: '*LAST NAME',
    formregister: 'Register',
    missingFields: 'Missing fields',
    requiredFields: 'Please fill all required fields',
    passwordnotmatch: 'Passwords do not match.',
    passwordEspCharacter: 'Password must have at least one special character.',
    passwordOneNumber: 'Password must have at least one number.',
    passwordCharacter: 'Length greater than or equal to 6 characters, including numbers and special characters.',
    validEmailPass: 'Please enter a valid email and password.',
    codeActivation: 'A new code was sent to your email. Please enter the activation code.',
    tryAgain: 'Please try again later',
    continue: "Success! Please continue registering",
    pleaseReview: "please review the data entered"
  },

  //Calendar
  calendar:{
    title:'Calendar'
  },

  //MFA
  mfa:{
    title:'Authentication settings',
    subTitle:'This section is not yet available',
    enable:'Enable 2-step authentication',
    body:'In addition to your password, set up a secondary verification method to keep your account even safer.',
    text:'When you enable 2-step authentication, you will need to enter your password as well as a verification code sent to your email address.',
    turn:'Turn',
    confirm:'Confirm',
    textTwo:'Are you sure you want to disable the 2FA?',
    yes:'Yes',
    no:'No',
    qr:'Please scan the QR',
    type:'Type the generated code:',
    setup: "Setup 2factor",
    insert: "Insert the 2FA code:",
    submit: "Submit",
    error: "Error while enabling 2FA:"
},

//Apllications
applications:{
  title: 'Applications',
  progress: 'Application progress',

  modify: 'Modify',
  completed: 'Completed',

  submit: 'Submit',
  next: 'Next',
  saved: 'Saved',
  validation: 'Please fill in all required fields.',

  personaldetails:
  {
    personal: 'Personal Details',
    title:'*TITLE',
    mr: 'Mr.',
    mrs: 'Mrs.',
    dateofbirth: '*DATE OF BIRTH',
    countrybirth: '*COUNTRY OF BIRTH',
    nationality: '*NATIONALITY',
    countryresidence: '*COUNTRY OF RESIDENCE',
    statedis: '*STATE / DISTRICT',
    city: '*CITY',
    text: '(text)',
    streetname: '*RESIDENTIAL ADDRESS - STREET NAME',
    streetnunmber: '*RESIDENTIAL ADDRESS - STREET NUMBER',
    postcode: '*POST CODE',
    mobile: '*MOBILE',
    phone: 'PHONE',
    passnumber: '*ID/PASSPORT NUMBER',
    passexpiration: '*ID/PASSPORT EXPIRATION DATE',
    question1: '*Are you a US Citizen or a US Resident for tax purposes?',
    question2: '*Are you a politically exposed person (PEP), close relative or associate of a PEP?',
    yes: 'YES',
    no: 'NO',
    please: '*Please provide details',
    bytick: '*By ticking as indicated you are agreeing to the following:',
    agree1: 'I/WE AGREE WITH TRADEVIEW EUROPE LTD. - TERMS OF BUSINESS INCLUDING ATTACHMENTS:',
    conflicts: 'CONFLICTS OF INTEREST POLICY',
    and: 'AND',
    bets: 'BEST EXECUTION POLICY',
    agree2: "I/WE AGREE WITH THE TRADEVIEW EUROPE LTD'S",
    privacy: 'PRIVACY POLICY',
    agree3: "I/WE AGREE TO BE INCLUDED IN TRADEVIEW EUROPE LTD'S MARKETING DISTRIBUTION LISTS.",
  },

  taxresidency:{
    tax: 'Declaration of Tax Residency',
    info: 'I hereby confirm that I am, for tax purposes, a resident in the following country/countries (indicate the type and reference number that applies in each country).',
    country: '*COUNTRY',
    referencenumber: '*TAX REFERENCE NUMBER',
    addcountry: 'Add Country',
    removecountry: 'Remove Country'
  },

  financialinformation:{
    finalcial: 'Financial Information',
    question1: '*Employment status',
    option11: 'EMPLOYED',
    option12: 'SELF-EMPLOYED',
    option13: 'RETIRED',
    option14: 'UNEMPLOYED',
    option15: 'STUDENT',
    labeloption11: '*EMPLOYER NAME',
    labeloption12: '*NATURE OF BUSINESS',
    labeloption13: '*POSITION HELD',
    accountancy: 'ACCOUNTANCY',
    adminsecretarial: 'ADMIN/SECRETARIAL',
    agriculture: 'AGRICULTURE',
    bankreatil: 'BANKING - RETAIL',
    bankInvestment: 'BANKING - INVESTMENT',
    catering: 'CATERING',
    dentist: 'DENTIST',
    doctor: 'DOCTOR',
    education: 'EDUCATION',
    emergencyservice: 'EMERGENCY SERVICES',
    energyu: 'ENERGY/UTILITIES',
    engineering: 'ENGINEERING',
    entretaiment: 'ENTERTAINMENT',
    finalcialadvisor: 'FINANCIAL ADVISOR',
    financialservices: 'FINANCIAL SERVICES OTHER',
    armedforces: 'RMED FORCES',
    hr: 'HR',
    insurance: 'INSURANCE',
    it: 'IT',
    legal: 'LEGAL',
    leisure: 'LEISURE',
    manufacturing: 'MANUFACTURING',
    marketing: 'MARKETING',
    media: 'MEDIA',
    motortrade: 'MOTOR TRADE',
    property: 'PROPERTY',
    retail: 'RETAIL',
    sales: 'SALES',
    socialservices: 'SOCIAL SERVICES',
    sports: 'SPORTS / RECREATION',
    stockbroker: 'STOCKBROKER',
    telecomunication: 'TELECOMMUNICATIONS',
    transport: 'TRANSPORT',
    arms: 'ARMS TRADE/DEFENCE',
    carwash: 'CAR WASH / GAS STATION',
    casino: 'CASINO',
    construction: 'CONSTRUCTION',
    heald: 'HEALTHCARE OTHER',
    mining: 'MINING',
    money: 'MONEY SERVICE/REMITTANCE',
    pharmaceuticals: 'PHARMACEUTICALS',
    precious: 'PRECIOUS METALS',
    public: 'PUBLIC PROCUREMENT',
    virtual: 'VIRTUAL CURRENCIES',
    question2: '*Your annual income',
    option21: 'BELOW EUR 10K',
    option22: 'EUR11K - EUR 30K',
    option23: 'EUR31 - EUR 50K',
    option24: 'EUR 51K - EUR 100K',
    option25: '100K - EUR 200K',
    option26: 'EUR 200K - EUR 500K',
    option27: 'ABOVE EUR 500K',
    question3: '*Your total assets (savings, investments, real estate property after deducting commitments)',
    option31: 'BELOW EUR 10K',
    option32: 'EUR11K - EUR 30K',
    option33: 'EUR31 - EUR 50K',
    option34: 'EUR 51K - EUR 100K',
    option35: '100K - EUR 200K',
    option36: 'EUR 200K - EUR 500K',
    option37: 'ABOVE EUR 500K',
    question4: '*Your liquid assets (cash, money market instruments, and marketable securities)',
    option41: 'BELOW EUR 10K',
    option42: 'EUR11K - EUR 30K',
    option43: 'EUR31 - EUR 50K',
    option44: 'EUR 51K - EUR 100K',
    option45: '100K - EUR 200K',
    option46: 'EUR 200K - EUR 500K',
    option47: 'ABOVE EUR 500K',
    question5: '*Anticipated level of investment/trading',
    option51: 'BELOW EUR 10K',
    option52: 'EUR11K - EUR 30K',
    option53: 'EUR31 - EUR 39K',
    option54: 'EUR 40K - EUR 99K',
    option55: '101K - EUR 200K',
    option56: 'EUR 201K - EUR 500K',
    option57: 'ABOVE EUR 500K',
    questionselect1: '*WHAT IS THE ORIGIN OF YOUR TOTAL ASSETS?',
    soursefounds1: 'Savings from salary',
    soursefounds2: 'Government aid',
    soursefounds3: 'Sale',
    soursefounds4: 'Investments, dividends',
    soursefounds5: 'Income from a trust',
    soursefounds6: 'Letting',
    soursefounds7: 'Inheritance',
    soursefounds8: 'Donation',
    soursefounds9: 'Loan',
    soursefounds10: 'Income from self-employment',
    soursefounds11: 'Parents, partner or other',
    soursefounds12: 'Relative support',
    soursefounds13: 'Other',
    questionselect2: '*WHAT IS THE SOURCE/ORIGIN OF YOUR DEPOSITS (SOURCE OF FUNDS)?',
    question6: '*PLEASE EXPLAIN YOUR SOURCE OF INCOME',
    question7: '*PLEASE EXPLAIN YOUR SOURCE OF INCOME',
    questionselect3: '*CHOOSE A PLATFORM:',
    questionselect4: '*CURRENCY TYPE:',
    question8: '*Are you connected to bankruptcies',
    yes: 'YES',
    no: 'NO',
    question9: '*WHAT KIND OF BANKRUPTCIES ARE YOU CONNECTED WITH?',
    question10: '*Purpose for account?',
    option101: 'HEDGING',
    option102: 'SPECULATION',
    option103: 'PROPRIETARY TRADING',
    option104: 'LONG-TERM INVESTMENT',
    option105: 'OTHER',
  },

  knowledge:{
    know: 'Knowledge',
    question1: '*What is your education level?',
    option11: 'I HAVE A GRADUATE DIPLOMA (BACHELOR’S OR MASTER OR PH.D.) IN FINANCE /ECONOMICS/MATHEMATICS',
    option12: 'I HAVE A BACHELOR’S OR POSTGRADUATE DEGREE NOT RELATED TO FINANCE',
    option13: 'I HAVE OTHER PROFESSIONAL QUALIFICATIONS RELATED TO CFDS',
    option14: 'I HAVE A SECONDARY LEVEL OF EDUCATION',
    option15: 'I HAVE NO FORMAL EDUCATION',
    question2: '*Have you worked in the Financial Services Industry for over a year?',
    option21: 'YES, IN A JOB THAT REQUIRES A GOOD UNDERSTANDING OF CFDS AND LEVERAGED PRODUCTS OVER THE LAST 5 YEARS',
    option22: 'YES, IN A JOB THAT REQUIRES A GOOD UNDERSTANDING OF CFDS AND LEVERAGED PRODUCTS BUT NOT OVER THE LAST 5 YEARS',
    option23: 'YES, IN FINANCIAL SERVICES NOT AS A TRADER OVER THE LAST 2 YEARS',
    option24: 'I DO NOT WORK OR HAVE NOT WORKED IN FINANCIAL SERVICES',
    question3: 'When do markets experience “High Volatility”?',
    option31: 'WHEN THERE ARE UNEXPECTED OR IMPORTANT ECONOMICAL NEWS RELEASE',
    option32: 'WHEN EXPECTED ECONOMICAL NEWS IS RELEASED',
    option33: 'WHEN THERE IS A DECREASE IN THE PRICE OF A FINANCIAL PRODUCT',
    question4: 'Which of the following are important in minimizing risk when trading CFD?',
    option41: 'MONITORING YOUR OPEN POSITIONS',
    option42: 'KNOWING WHAT LEVERAGE LEVEL IS USED',
    option43: 'BOTH STATEMENTS',
    question5: '*Which type of closing order can be used to control the amount of potential loss?',
    option51: 'STOP LOSS',
    option52: 'TAKE PROFIT',
    option53: 'NONE OF THE ABOVE',
    question6: '*What are the risks associated with trading leveraged products?',
    option61: 'THERE ARE NO RISKS',
    option62: 'RISK OF LOSING THE MONEY INVESTED',
    option63: 'NONE OF THE ABOVE',
  },

  experience:{
    exp: 'Experience',
    description: 'Please select the best option to describe your trading experience:',
    subtitle1: 'Futures, Options, and Derivatives:',
    question1: '*How many years have you been trading Futures, Options, and Derivatives?',
    option11: 'I HAVE NEVER TRADED THESE INSTRUMENTS BEFORE',
    option12: '0 - 1 YEAR',
    option13: '2-5 YEARS',
    option14: '5-10 YEARS',
    option15: 'OVER 10 YEARS',
    question2a: '*What is your trading volume in Futures, Options,',
    question2b: ' and Derivatives?',
    option21: 'I HAVE NEVER TRADED THESE INSTRUMENTS BEFORE',
    option22: 'EUR 0 - EUR 50,000',
    option23: 'EUR 50,001 - EUR 100,000',
    option24: 'EUR 100,001 - EUR 500,000',
    option25: 'EUR 500,001 OR MORE',
    question3: '*How often do you trade Futures, Options, and Derivatives?',
    option31: 'I HAVE NEVER TRADED THESE INSTRUMENTS BEFORE',
    option32: 'DAILY',
    option33: 'WEEKLY',
    option34: 'MONTHLY',
    option35: 'QUARTERLY',
    option36: 'SEMI-ANNUALLY',
    subtitle2: 'CFD - Indicies Commodities Crypto & Rolling Forex',
    question4: '*How many years have you been trading forex or CFD?',
    option41: 'I HAVE NEVER TRADED THESE INSTRUMENTS BEFORE',
    option42: '0 - 1 YEAR',
    option43: '2 - 5 YEARS',
    option44: '5 - 10 YEARS',
    option45: 'OVER 10 YEARS',
    question5: '*What is your trading volume on Forex or CFD?',
    option51: 'I HAVE NEVER TRADED THESE INSTRUMENTS BEFORE',
    option52: 'EUR 0 - EUR 50,000',
    option53: 'EUR 50,001 - EUR 100,000',
    option54: 'EUR 100,001 - EUR 500,000',
    option55: 'EUR 500,001 OR MORE',
    question6: 'How often do you trade Forex or CFD?',
    option61: 'I HAVE NEVER TRADED THESE INSTRUMENTS BEFORE',
    option62: 'DAILY',
    option63: 'WEEKLY',
    option64: 'MONTHLY',
    option65: 'QUARTERLY',
    option66: 'SEMI-ANNUALLY'
  },

  verification:{
    idv: 'ID Verification',
    h1: 'Almost complete!',
    subtitle1: 'We just need to verify your identity',
    description: 'To approve your trading account we are required to verify your identity. Please attach the following documents to your application for review. Tradeview may request additional information or documents to comply with AML and CFT regulations.',
    pleaseprovide: 'Please provide these documents:',
    h2a: 'Government-issued photo ID',
    descriptionh2a: 'e.g. passport (recommended), identity card or residence permit.',
    h2b: 'Proof of residence',
    descriptionh2b: 'e.g. utility bill, certificate of residence, bank estatement.',
    h2c: 'Government photo ID',
    descriptionh2c1: 'Please upload a colour copy of your passport (recommended), identity card or residence permit.',
    descriptionh2c2: 'Please note:',
    descriptionh2c3: 'Your photo ID is clear and visible',
    descriptionh2c4: 'Your ID issued by a government agency',
    descriptionh2c5: 'Your full name, date of birth and expiry/issued date are clearly shown',
    descriptionh2c6: 'Attach the front and back of your ID as separate files',
    select1: '*TYPE OF DOCUMENT',
    typeofdocs1: 'Passport (Recomended)',
    typeofdocs2: 'Driving License',
    typeofdocs3: 'Identity Card',
    typeofdocs4: 'Residence Permit',
    h2d: 'Upload your document:',
    descriptionh2d1: 'Select the FRONT of your document',
    front: 'FRONT',
    descriptionh2d2: 'Select the BACK of your document',
    back: 'BACK',
    description1: 'Each file should be either a JPG, PNG or JPEG, and under 2MB in size',
    goback: 'Go back',
    done: 'Done',
    h2e: 'Proof of residence',
    descriptionh2e1: 'e.g. utility bill, certificate of residence, bank estatement',
    descriptionh2e2: 'All proof of residences must be current and not outdated by more than 90 days',
    descriptionh2e3: 'Show the full document, clearly display the name and full address (no PO Boxes)',
    descriptionh2e4: 'Show a provision of services such as money owed or paid.',
    select2: '*TYPE OF DOCUMENT',
    proofType1: "Utility Bill",
    proofType2: "Certificate of Residence",
    proofType3: "Bank Statement",
    h2f: 'Upload your document:',
    descriptionh2f1: 'Select files',
    descriptionh2f2: 'Each file should be either a JPG, PNG or JPEG, and under 2MB in size',
    description2: '*I CONSENT TO TRADEVIEW ELECTRONICALLY VERIFYING MY IDENTITY BY PROVIDING MY PERSONAL INFORMATION, INCLUDING IDENTITY DOCUMENTS, TO AN IDENTITY VERIFICATION SERVICE PROVIDER TO OBTAIN AN ASSESSMENT OF WHETHER IT MATCHES INFORMATION HELD BY THEM OR OTHER IDENTITY VERIFICATION SOURCES.',
    uploadDocument: "Please upload your document.",
    documentCorresponding: "Please upload your document corresponding to Government photo ID and Proof of residence",
    cantAttach: "You can't attach this image, try with another one"
  },

  acknowledgement:{
    ack: 'Acknowledgement',
    description: '*Click on the document links below and confirm your understanding and agreement with the document. After confirming the application, agreements and disclosures, read the signature block affirmation and using your mouse please sign your name.',
    check1: 'CLIENT AGREEMENT',
    check2: 'ELECTRONIC TRADING AGREEMENT',
    check3: 'RISK DISCLOSURE',
    check4: 'PRIVACY POLICY',
    check5: 'CONSENT TO DELIVERY OF STATEMENTS BY ELECTRONIC MEDIA',
    check6: 'COMMISSION AGREEMENT',
    check7: 'TERMS AND CONDITIONS',
    check8: 'BEST EXECUTION POLICY',
    check9: 'CONFLICTS OF INTEREST POLICY',
    check10: 'Key Information Documents (KID):',
    forex: 'FOREX',
    crypto: 'CRYPTOCURRENCY',
    commodities: 'COMMODITIES',
    index: 'INDEX',
    description2: 'By signing below, I acknowledge and agree to the terms in the above-listed Client Agreement, Electronic Trading Agreement, Risk Disclosure, Privacy Policy, Consent to Delivery of Statements by Electronic Media, Commission Agreement, Terms and Conditions, Best Execution Policy, Conflicts of Interest Policy and KIDs. I further acknowledge and agree, under penalty of perjury, that all information provided in the application is true and accurate and all deposits into my account, regardless of origination source, type of currency, or method of deposit, are not the proceeds of criminal or illegal activity and are derived from legitimate sources.',
    authorized: 'AUTHORIZED',
    signature: 'SIGNATURE, SIGN ABOVE.',
    confirm: 'Confirm Signature',
    clean: 'Clear Signature',
    hasNot: 'Has not signed'
  },

  riskdisclosure:{
    add: 'Additional Risk Disclosure',
    description: "The information provided on your account application indicates that you do not meet Tradeview Europe Ltd.'s guidelines to open a trading account for one of the following reasons:",
    list1: 'You are not between 21 and 63 years old',
    list2: 'Your annual income is below EUR 10K',
    list3: 'You have filed for bankruptcy within the last year',
    list4: 'You are not employed',
    description1: 'While Tradeview Europe Ltd. is prepared to open your account, it is necessary to advise you to reconsider this investment. Based on your personal information and/or investment experience, forex might be too risky of an investment strategy. The loss in trading forex can be substantial. You should therefore carefully consider whether such trading is suitable for you in light of your circumstances and financial resources. The high degree of leverage that is obtainable in forex trading because of the small margin requirements can work against you as well as for you. The use of leverage can lead to large losses as well as gains.',
    description2: '*I UNDERSTAND THAT I DO NOT MEET THE MINIMUM GUIDELINES TO OPEN AN ACCOUNT AS SET FORTH BY TRADEVIEW EUROPE LTD. IN THE ABOVE PARAGRAPHS. HOWEVER, I HAVE CONSIDERED THE FINANCIAL RISKS INVOLVED IN FOREX TRADING WITH REGARD TO MY PERSONAL SITUATION, AND I WISH TO PROCEED WITH OPENING AN ACCOUNT.',
    description3: '*I ACKNOWLEDGE THAT I HAVE READ THE ABOVE AND FULLY UNDERSTAND THE INFORMATION PROVIDED HEREIN.',
  },

  confirmation:{
    subtitle1: 'You are one click away from submitting your application',
    description: 'If you need to review or edit your previously entered information you can go to the',
    modify: '"Modify"',
    description2: 'link on each section.If you are ready to submit your application now just click on the submission button.',
    subapplication: 'Submit Application',
    congratulation: 'Congratulations',
    descriptionmodal: 'Thank you for your application. Your uploaded documents are under review and will be verified shortly. Our operations team will be in contact with you should we require any further documentation.',
    close: 'Close',
    your: 'Your',
    account: 'individual account',
    description3: 'Request is being processed. Final status will be notified at your email.'
  }
}

}
